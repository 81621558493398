import React, { ReactNode } from "react"
import './hero-action-bandeau.css'
import { Container } from "semantic-ui-react";
import { SimpleButton } from "../../../../generic/simple-button";



type Props = {
    classPage: string,
    title:string,
    paragraph?:string,
    textButton?:string,
    actionButtonUrl?: ()=> void
    children?: ReactNode,
    targetBlank?:boolean
};

export function HeroActionBandeau({classPage, title, paragraph, textButton, actionButtonUrl, targetBlank, children}: Props) {
    
    return (
        <section className="container-bandeau-section"> 
                <div className={children ? `container-bandeau-with-children ${classPage} ` : `container-bandeau-without-children ${classPage}`}>
                    <Container>
                        <div className={`container-text-block ${paragraph ? 'margin-bottom-with-p' : 'margin-bottom-without-p'}`}>
                            <h1 className="container-text-title ">{title}</h1>
                            {paragraph && paragraph !== "" && 
                            <p className="container-text-paragraph">{paragraph}</p>
                            }
                            {textButton && 
                            <SimpleButton 
                            stretchOnDesktop
                            ralewayAnd16
                            label={textButton}
                            onClick={actionButtonUrl}
                            secondary
                            />
                            }
                        </div>
                    </Container>
                </div>
                {children && 
                <div className='container-children'>
                        {children}
                </div>
                }
        </section>
    )         
}