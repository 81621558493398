import { PeriodiciteEcheance } from "../../entities/periodicite-echeance";
import { Indicateur } from "../indicateur";
import { StatutProjet } from "../../entities/statut-projet";
import { ProjetResources } from "../../entities/projet-resources";
import Projet from "../../projet";
import { Departement } from "../../entities/departement";
import { Region } from "../../entities/region";
import { QualiteRepresentant } from "../../entities/qualite-representant";
import { User } from "../../user";
import { EtablissementDto } from "../../dto/etablissement-dto";
import { DonneesPubliquesCollectivites } from "../../entities/donnees-publiques-collectivites";
import { OnboardingStatus } from "../../entities/onboarding-status";
import { TypePieceByProfil } from "../../entities/type-piece-by-profil";
import { UserPieceJustificative } from "../../entities/user-piece-justificative";
import { KYCDocumentError } from "../../entities/kyc-document-error";
import { ProjetListItem } from "../projet-list-item";
import { EtablissementPieceJustificative } from "../../entities/etablissement-piece-justificative";
import { LabelProjet } from "../../entities/label-projet";
import { SecteurProjet } from "../../entities/secteur-projet";
import { TypologieRemboursement } from "../../entities/typologie-remboursement";
import { TypeFinancement } from "../../entities/type-financement";
import { ProjetCreationWorkflow } from "../../entities/projet-creation-workflow";
import { Secteur } from "../../entities/secteur";
import { Label } from "../../entities/label";
import { ActionVillyzProjet } from "../../entities/action-villyz-projet";
import { MangoPayEventHistory } from "../../entities/mangopay-event-history";
import { HistoRemboursementProjet } from "../../entities/histo-remboursement-projet";
import { EcheancierProjet } from "../../entities/echeancier-projet";
import { AdminParams } from "../../entities/admin-params";
import { HistoRetraitCitoyen } from "../../entities/histo-retrait-citoyen";
import { EcheanceDtoItem } from "../../dto/echeance-dto-item";
import { Pays } from "../../entities/pays"
import { Epci } from "../../entities/epci"
import { CommuneDTO } from "../util/commune-dto";
import { CollectivitePostPublique } from "../../entities/collectivite-post-publique";
import { TypeEvenementWithPalier } from "../../entities/type-evenement-with-palier";
import { TypeEvenement } from "../../entities/type-evenement";
import { InvestWorkflow } from "../../entities/invest-workflow";
import { BankProvider } from "../../entities/bank-provider";
import { TrueLayerEventHistory } from "../../entities/truelayer-event-history";
import { BankBranch } from "../../entities/bank-branch";

import { UserInfoAdmin } from "../util/user-info-admin";
import { MangoPayEventReference } from "../../entities/mangopay-event-reference";
import { StatutPieceJustificative } from "../../entities/statut-piece-justificative";
import { RemboursementUserDtoItem } from "../remboursement-user-dto-item";
import { ReponseQuestionProposition } from "../../entities/reponse-question-proposition";
import { Question } from "../../entities/question";
import { UserQuestionReponse } from "../../entities/user-question-reponse";
import { Beneficiaire } from "../../entities/beneficiaire";
import { HistoRemboursementUser } from "../../entities/histo-remboursement-user";
import { StatutInvestissement } from "../../entities/statut-investissement";
import { StatutRemboursement } from "../../entities/statut-remboursement";
import { TypeEmprunt } from "../../entities/type-emprunt";
import { TrueLayerSimpleHookMessage } from "../../entities/truelayer-simple-hook-message";
import { MangoPaySimpleHookMessage } from "../../entities/mangopay-simple-hook-message";
import { TrueLayerPaymentStatus } from "../../entities/truelayer-payment-status";
import { HistoInvestissementItem } from "../histo-investissement-item";
import { HistoInvestissementProjet } from "../../entities/histo-investissement-projet";
import { CHU } from "../../entities/chu";
import { CategorieEtablissement } from "../../entities/categorie-etablissement";
import { TypeCollectivite } from "../../entities/type-collectivite";
import ProjetRestrictedDepartement from "../../entities/projet-restricted-departement";
import { EtablissementMairie } from "../../entities/etablissement-mairie";
import { TypeInvestisseurMorale } from "../../entities/type-investisseur-morale";
import { WorkflowListData } from "../util/workflow-list-data";
import { TypeCollecte } from "../../entities/type-collecte";
import { FormeJuridiqueEntreprise } from "../../entities/forme-juridique-entreprise";
import ProjetObjectifs from "../../entities/projet-objectifs";


export interface DefaultResponse extends Response {
  IsTraitementOk?: boolean;
  Error?: string;
}

export interface PeriodiciteListResponse extends DefaultResponse {
  PeriodiciteList?: PeriodiciteEcheance[];
}

export interface IndicateurListResponse extends Response {
  IndicateurList?: Indicateur[];
}

export interface ProjetCreateResponse extends Response {
  IsProjetCreated?: boolean;
  ProjetStatutCode?: string;
  ProjetId?: number;
  Error?: string;
}

export interface ProjetDetailResponse extends DefaultResponse {
  Projet?: Projet;
  ProjetResourcesList?: ProjetResources[];
  LabelProjetList?: LabelProjet[];
  SecteurProjetList?: SecteurProjet[];
  RestrictedDepartementList?: ProjetRestrictedDepartement[];
  IsValidateKYC?: boolean;
  CategorieEtablissementCode?: string;
  CategorieEtablissementLibelle?: string;
  TypeCollectiviteCode?: string;
  NomEtablissement?: string;
  NomLocalite?: string;
  CodeDepartement?: string;
  NbInvestisseurs?: number;
  NextStepKey?: string;
  TypeCollecteCode?: string;
  HasToRedirect?: boolean;
  NextSubStepKey?: string;
}

export interface StatutProjetListResponse extends Response {
  StatutProjetList?: StatutProjet[];
}

export interface ProjetUploadResponse extends DefaultResponse {}

export interface ProjetStatusChangeResponse extends DefaultResponse {
  CodeStatus?: string;
}

export interface CheckWalletAndBankingAliasResponse extends DefaultResponse {
  IsMangoPayObjectCreated?: boolean;
}

export interface OnboardingCitoyenResponse extends DefaultResponse {
  NextStepKey?: string;
  IsOnboardingCompleted?: boolean;
  UserInfo?: UserInfoResponse;
  NextFormStep?: string;
}

export interface ProjetItemResponse extends DefaultResponse {
  ProjetCardInfo?: ProjetListItem;
}

export interface EtablissementMairieResponse extends DefaultResponse {
  Etablissement?: EtablissementMairie;
}

export enum ProfilCode {
  PORTEUR_PROJET = "POP",
  INVESTISSEUR = "INV",
  VILLYZ = "VIL",
  BANQUE = "BAN",
}

export interface UserInfoResponse extends DefaultResponse {
  UserId?: number;
  Nom?: string;
  Prenom?: string;
  DateNaissance?: Date;
  Mail?: string;
  IsResidentFrancais?: boolean;
  LibelleVoie?: string;
  ComplementAdresse?: string;
  CodePostal?: string;
  Civilite?: string;
  CommuneId?: number;
  DepartementId?: number;
  CommuneNaissanceId?:number;
  PaysNaissanceCode?:string;
  PaysDeNaissance?:string;
  PaysCode?: string;
  Pays?: string;
  NomDeCommuneEtrangere?:string;
  CommuneDesignation?: string;
  CommuneDesignationNaissance?: string;
  OnboardingWorkflowCode?: string;
  IsIdentificationDone?: boolean;
  IsMailValid?: boolean;
  IsCNIValid?: boolean;
  IsRIBValid?: boolean;
  QualiteRepresentantId?: number;
  AutreFonction?: string;
  QualiteRepresentant?: string;
  CollaborateursList?: User[];
  Etablissement?: EtablissementDto;
  ConfirmationDelegation?: boolean;
  NombrePiecesJustificativesEnvoyees?: number;
  PourcentageCompletion?: number;
  OnboardingStatus?: OnboardingStatus;
  OnboardingInformationMessage?: string;
  ProfilCode?: ProfilCode;
  MontantWalletNaturalUser?: number;
  Nationalite?: string;
  IsPiecesJustifDone?: boolean;
  IsIdentiteDone?: boolean;
  IsExperienceInvestissement?: boolean;
  IsAdressePostaleDone?: boolean;
  DateExperienceInvestissement?: Date;
  ValeurPatrimonialeNette?: number;
  ConfirmationDeclaration?: boolean;
  Categorie?: string;
  IsBeneficiairesEffectifsDone?: boolean;
  BeneficiairesList?: Beneficiaire[];
  NumeroSiren?: string;
  IsVerificationDone?: boolean;
  IsAdresseRepresentantDone?: boolean;
  IsMarketingMailsAllowed?: boolean;
  DateUpdated?: Date;
  IsCSGExonere?: boolean;
  IsCRDSExonere?: boolean;
  IsIRExonere?: boolean;
  IsPSExonere?: boolean;
  DateCSGUpdated?: Date;
  DateCRDSUpdated?: Date;
  DateIRUpdated?: Date;
  DatePSUpdated?: Date;
  MangoPayLongWalletId?: string;
  MangoPayLongUserId?: string;
  IsBloque?: boolean
}
export interface UserPiecesJustificativesListResponse extends DefaultResponse {
  UserPieceJustifList?: UserPieceJustificative[];
  EtablissementPieceJustifList?: EtablissementPieceJustificative[];
}

export interface CommuneListResponse extends DefaultResponse {
  CommuneList?: CommuneDTO[];
}

export interface IsKYCValidatedResponse extends DefaultResponse {
  IsKYCValidated : boolean;
}

export interface DepartementListResponse extends DefaultResponse {
  DepartementList?: Departement[];
}

export interface RegionListResponse extends DefaultResponse {
  RegionList?: Region[];
}

export interface EpciListResponse extends DefaultResponse {
  EpciList?: Epci[];
}

export interface CHUListResponse extends DefaultResponse {
  CHUList?: CHU[];
}

export interface OnboardingCollectiviteResponse extends DefaultResponse {
  NextStepKey?: string;
  IsOnboardingCompleted?: boolean;
  UserInfo?: UserInfoResponse;
  NextFormStep?:string
}

export interface OnboardingInvestisseurResponse extends DefaultResponse {
  NextStepKey?: string;
  IsOnboardingCompleted?: boolean;
  UserInfo?: UserInfoResponse;
  NextFormStep?:string
}

export interface OnboardingSEMResponse extends DefaultResponse {
  NextStepKey?: string;
  IsOnboardingCompleted?: boolean;
  UserInfo?: UserInfoResponse;
  NextFormStep?:string
}

export interface OnboardingCHUResponse extends DefaultResponse {
  NextStepKey?: string;
  IsOnboardingCompleted?: boolean;
  UserInfo?: UserInfoResponse;
  NextFormStep?:string
}

export interface OnboardingOrganisationResponse extends DefaultResponse {
  NextStepKey?: string;
  IsOnboardingCompleted?: boolean;
  UserInfo?: UserInfoResponse;
  NextFormStep?:string
}

export interface OnboardingBusinessResponse extends DefaultResponse {
  NextStepKey?: string;
  IsOnboardingCompleted?: boolean;
  UserInfo?: UserInfoResponse;
  NextFormStep?:string
}

export interface QualiteRepresentantListResponse extends DefaultResponse {
  QualiteRepresentantList?: QualiteRepresentant[];
}

export interface EmbedResponse {
  Type: string;
  SiteName: string;
  Title: string;
  Image: string;
  Url: string;
  Description: string;
}
export interface GetDonneesPubliquesCollectivitesResponse
  extends DefaultResponse {
  DonneesCollectivite?: DonneesPubliquesCollectivites[];
}

export interface GetDonneesPubliquesCollectivitesCurrentYearResponse
  extends DefaultResponse {
  DonneesCollectivite?: DonneesPubliquesCollectivites;
}

export interface TypePiecesListForUserResponse extends DefaultResponse {
  TypePieceList?: TypePieceByProfil[];
}

export interface MultipleFileRegistrationResponse extends DefaultResponse {
  UserPieceList?: UserPieceJustificative[];
  EtablissementPieceList?: EtablissementPieceJustificative[];
  UserInfo?: UserInfoResponse;
  NextStepKey?: string;
}

export interface KYCErrorMessageResponse extends DefaultResponse {
  ErrorList?: KYCDocumentError[];
}

export interface TypologieRemboursementListResponse extends DefaultResponse {
  TypologieRemboursementList?: TypologieRemboursement[];
}

export interface TypeFinancementListResponse extends DefaultResponse {
  TypeFinancementList?: TypeFinancement[];
}

export interface ProjetCreationWorkflowListResponse extends DefaultResponse {
  ProjetCreationWorkflowList?: ProjetCreationWorkflow[];
}

export interface SecteurListResponse extends DefaultResponse {
  SecteurList?: Secteur[];
}

export interface LabelListResponse extends DefaultResponse {
  LabelList?: Label[];
}

export interface ActionVillyzProjetListResponse extends DefaultResponse {
  ActionVillyzProjetsList?: ActionVillyzProjet[];
}

export interface TableauAmortissementResponse extends DefaultResponse {
  File?: string;
}

export interface ContratDePretResponse extends DefaultResponse {
  File?: string;
}

export interface FichierAnalyseVillyzResponse extends DefaultResponse {
  File?: string;
}

export interface InvestResponse extends DefaultResponse {
  RedirectURL?: string;
  Status?: string;
  ResultMessage?: string;
  MontantVirement?:number;
  Iban?:string;
  Bic?:string;
}

export interface UnlockFundsResponse extends DefaultResponse {
  RedirectURL?: string;
}

export interface RecentMangoPayEventResponse extends DefaultResponse {
  History?: MangoPaySimpleHookMessage[];
}

export interface EcheanceListResponse extends DefaultResponse {
  RemboursementCitoyenList?: HistoRemboursementProjet[];
  RemboursementCollectiviteList?: EcheancierProjet[];
  RemboursementList?: EcheanceDtoItem[];
}

export interface AdminParamsListResponse extends DefaultResponse {
  AdminParamsList?: AdminParams[];
}

export interface DelaiReservationVirementResponse extends DefaultResponse {
  DelaiReservationVirement?: string;
}

export interface RecentPayoutListResponse extends DefaultResponse {
  HistoRetraitList?: HistoRetraitCitoyen[];
}

export interface AllPayoutsListResponse extends DefaultResponse {
  HistoRetraitList?: HistoRetraitCitoyen[];
}

export interface InvestissementHistoryResponseListResponse extends DefaultResponse {
  InvestissementHistoryResponseList: InvestissementHistoryResponse[];
}

export interface FormeJuridiqueEntrepriseListResponse extends DefaultResponse {
  FormeJuridiqueEntrepriseList?: FormeJuridiqueEntreprise[];
}

export interface InvestissementHistoryResponse {
  Id?: number,
  ProjetId?: number,
  Titre?: string,
  StatutCollecte?: string,
  StatutInvestissement?: StatutInvestissement,
  TauxInteretCitoyen?: string,
  EndDate?: Date,
  DateInvestissement?: Date,
  DateValidated?: Date,
  MontantInvesti?: number,
  MontantPercu?: number,
  DatePremiereEcheance?: Date,
  PeriodiciteEcheance?: PeriodiciteEcheance,
  DateDebutCollecte?: Date,
  DureeFinancement?: number,
  DateDerniereEcheance?: Date,
  NomCollectivite?: string,
  CodeDepartement?: string,
  NomRegion?: string,
  TypeCollectiviteCode?: string,
  EventMessage?: string,
  RaisonModification? : string,
  StatutProjet? : string,
  DateModification? : Date,
  DateSuspension? : Date,
  DateCloture? : Date,
  MangoPayId?: string,
  MangoPayStatus?: string,
  TrueLayerId?: string,
  TrueLayerTrueLayerPaymentStatus?: string,
  HistoRemboursementUser?: HistoRemboursementUser,
  TypeEmprunt?: TypeEmprunt,
  MontantMaxInvestissement?: number,
  MontantReinvestissement?: number,
  ModePaiementCode?: string,
  IsReserve?: boolean,
  TypePaiementCode?: string,
  TypeCollecteCode?: string,
  StatutDonProjetCode?: string,
  MontantCollecte?: number
}

export interface ProjetListResponse extends DefaultResponse {
  ProjetList?: ProjetListItem[]
}

export interface PaysListResponse extends DefaultResponse {
  PaysList?: Pays[]
}

export interface DonneesImpotResponse extends DefaultResponse {
  File?: string
}

export interface SimulationResponse extends DefaultResponse {
  MontantPrevisionnelPercu?: number
}

export interface IsProjetSuiviResponse extends DefaultResponse {
  IsAbo?: boolean
}
export interface PostListResponse extends DefaultResponse {
  PostsList?: CollectivitePostPublique[]
}


export interface PostCreationResponse extends DefaultResponse {
  PostId?: number;
  ProjectId?: number;
}

export interface PostReactionsResponse extends DefaultResponse {
  NumberOfReactions?: number;
}

export interface UserReactionsReponse extends DefaultResponse {
  PostId?: number
  IsLiked?: boolean;
}
export interface TypeEvenementWithPalierResponse extends DefaultResponse {
  EventWithPalier?: TypeEvenementWithPalier;
  TypeEvenement?: TypeEvenement;
}


export interface LogoExistanceResponse extends DefaultResponse {
  Id? : number,
  Exist: boolean
}

export interface RemboursementHistoryListResponse extends DefaultResponse {
  HistoRemboursementUserList?: RemboursementUserDtoItem[];
}

export interface FichierInformationsCleResponse extends DefaultResponse {
  File?: string;
}


export interface IsUserAlertedResponse extends DefaultResponse {
  IsAlert?: boolean
}
export interface UsersListResponse extends DefaultResponse {
  UsersList?: UserInfoAdmin[];
}
export interface MailUpdateResponse extends DefaultResponse {
  Mail?: string
}
export interface MangoPayEventListResponse extends DefaultResponse {
  MangoPayEventList?: MangoPayEventReference[]
}
export interface HistoRemboursementProjetListResponse
  extends DefaultResponse {
  RemboursementHistoryResponseList: RemboursementHistoryResponse[];
}

export interface InvestWorkflowResponse extends DefaultResponse {
  InvestWorkflowList?: InvestWorkflow[]
}

export interface BankProviderListResponse extends DefaultResponse {
  ProviderList?: BankProvider[]
}

export interface RecentTrueLayerEventListResponse extends DefaultResponse {
  History?: TrueLayerSimpleHookMessage[]
}

export interface RecentlyTreatedTrueLayerEventsListResponse extends DefaultResponse {
  History?: TrueLayerEventHistory[]
}

export interface RecentlyTreatedMangoPayEventResponse extends DefaultResponse {
  History?: MangoPayEventHistory[]
}

export interface BranchProviderListResponse extends DefaultResponse {
  BranchList?: BankBranch[]
}
export interface RemboursementHistoryResponse {
  Id?: number,
  Titre?: string,
  Statut?: string,
  Montant?: number,
  DateRemboursement?: Date,
  DateCreation?: Date,
  EventMessage?: string
  MangoPayId?: string
}

export interface HistoRetraitProjetListResponse
  extends DefaultResponse {
  RetraitHistoryResponseList: RetraitHistoryResponse[];
}

export interface RetraitHistoryResponse {
  Id?: number,
  Statut?: string,
  Montant?: number,
  DateDemande?: Date,
  MangoPayId?: string
}

export interface StatutPieceJustificativeListResponse extends DefaultResponse {
  StatutPieceJustifList?: StatutPieceJustificative[]
}

export interface RemboursementHistoryListResponse extends DefaultResponse {
  HistoRemboursementUserList?: RemboursementUserDtoItem[];
}

export interface FichierInformationsCleResponse extends DefaultResponse {
  File?: string;
}
export interface QuestionListResponse extends DefaultResponse {
 QuestionList?: Question[];
}

export interface ReponseQuestionPropositionListResponse extends DefaultResponse {
  ReponsePropositionList?: ReponseQuestionProposition[];
}
export interface UserQuestionReponseListResponse extends DefaultResponse {
  UserQuestionReponsesList?: UserQuestionReponse[];
}
export interface ResultatTestConnaissanceResponse extends DefaultResponse {
  Score?: number
  ReponseNumeroList?: number[];
}
export interface ReportingAnnuelResponse extends DefaultResponse {
  File?: string
}
export interface IsUserAlertedRepriseResponse extends DefaultResponse {
  IsAlert?: boolean
}

export interface BeneficiairesListResponse extends DefaultResponse {
  BeneficiairesList?: Beneficiaire[];
}

export interface ReportingInfosResponse extends DefaultResponse {
  File?: string
}

export interface RemboursementStatutsListResponse extends DefaultResponse{
  RemboursementStatutsList?: StatutRemboursement[]
}

export interface TypeEmpruntListResponse extends DefaultResponse {
  TypeEmpruntList?: TypeEmprunt[]
}

export interface EcheanciersProjetResponse extends DefaultResponse {
  EcheanciersProjet?: EcheancierProjet[]
}

export interface EcheanciersProjetResponse extends DefaultResponse {
  EcheanciersProjet?: EcheancierProjet[]
}

export interface TrueLayerPaymentStatusListResponse extends DefaultResponse {
  TrueLayerPaymentStatusList?: TrueLayerPaymentStatus[]
}

export interface InvestissementHistoryListForUserAndProjectResponse extends DefaultResponse
{
  UserInvestissementList? : HistoInvestissementItem[]
  ProjetInvestissementList? : HistoInvestissementProjet[]
  ProjetReinvestissementList?: HistoInvestissementItem[]
}

export interface AdminParamValueResponse extends DefaultResponse {
  AdminParamValue?: AdminParams
}

export interface FirstSEMProjectResponse extends DefaultResponse {
  Id?: number
}

export interface DescriptionDocumentsListResponse extends DefaultResponse {
  DescriptionDocuments?: string[]
}

export interface DescriptionDocumentResponse extends DefaultResponse {
  Name?: string
  File?: string
}

export interface StatutInvestissementListResponse extends DefaultResponse {
  StatutInvestissementList?: StatutInvestissement[]
}

export interface CategorieEtablissementListResponse extends DefaultResponse {
  CategorieEtablissementList?: CategorieEtablissement[]
}

export interface TypeCollectiviteListResponse extends DefaultResponse {
  TypeCollectiviteList?: TypeCollectivite[]
}

export interface TypeInvestisseurMoralListResponse extends DefaultResponse {
  TypeInvestisseurMoralList?: TypeInvestisseurMorale[]
}

export interface WorkflowStepListByWorkflowTypeCodeResponse extends DefaultResponse {
  WorkflowStepList?: WorkflowListData[]
}


export interface TrueLayerProviderListResponse extends DefaultResponse {
  BankList?: BankProvider[]
}

export interface InscriptionUserNewsLetterResponse extends DefaultResponse {
  UserInfo? : UserInfoResponse;
}

export interface SocialProofResponse extends DefaultResponse {
  TotalInvestissementReussi: number,
  TotalMembres: number
}

export interface ProjetsCardResponse extends DefaultResponse {
  ProjetList : ProjetListItem[],
  ProjetItem : ProjetListItem
}

export interface GenerateVirementClassiqueResponse extends DefaultResponse {
  IBAN?: string,
  WireReference?: string
}

export interface EcheancierSimulationResponse extends DefaultResponse {
  EcheancierFile?: string
}

export interface ReportingNewsletterMailListResponse extends DefaultResponse {
  File?: string
}

export interface ReportingOnboardingUtilisateursResponse extends DefaultResponse {
  File?: string
}

export interface ReportingStatusDesPaiementsResponse extends DefaultResponse {
  File?: string
}

export interface WorkflowProcessResultResponse extends DefaultResponse {
  IsTraitementOk?: boolean
  NextFlowStepCode?: string
  NextSubStepCode?: string
}

export interface NextEcheanceProjetResponse extends DefaultResponse {
  Id?: number,
  DateEcheance?: Date,
  Montant?: number
}

export interface AskEcheanceForProjetResponse extends DefaultResponse {
  IBAN?: string,
  BIC?: string,
  WireReference?: string,
  MangoPayLongPayinId?: string
}

export interface CalculateMontantCompteVillyzResponse extends DefaultResponse {
  MontantCompteVillyz?: number
}

export interface CalculateUserSumPrevisionnelForProjectResponse extends DefaultResponse {
  MontantSumPrevisionnel?: number
}

export interface CalculateMontantPrevisionnelCollecteResponse extends DefaultResponse {
  MontantPrevisionnelCollecte?: number
}

export interface TypeCollecteListResponse extends DefaultResponse {
  TypeCollecteList?: TypeCollecte[]
}

export interface UserDonHistoryListResponse extends DefaultResponse {
  UserDonHistoryResponseList?: UserDonHistoryResponse[];
}

export interface UserDonHistoryResponse {
  Id?: number,
  ProjetId?: number,
  ProjetSlug?: string,
  Titre?: string,
  StatutDonLibelle?: string,
  MontantDon?: number,
  StatutDonProjetCode? : string,
  StatutDonCode?: string,
  ModePaiementCode?: string,
  TypePaiementCode?: string,
  TypeCollecteCode?: string,
  DateDon?: Date,
  IsReserve?: boolean
}

export interface ProjetObjectifsResponse extends DefaultResponse {
  ObjectifList?: ProjetObjectifs []
}
