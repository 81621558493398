import React, { useState } from 'react'
import { AuthenticationState } from '../../../model/state/authentication-state';
import Projet from '../../../model/projet';
import { WorkflowProcessResultResponse } from '../../../model/dto/response';
import { Form } from 'semantic-ui-react';
import { FormErrorHeader } from '../../generic/form-error-header';
import { InputField } from '../../generic/input-field';
import { CheckBoxSimpleField } from '../../generic/checkbox-simple-field/checkbox-simple-field';
import { SubmitButton } from '../../generic/submit-button';
import { SimpleButton } from '../../generic/simple-button';
import { FlowStep } from '../../../model/entities/flow-step';
import { referenceService } from '../../../services/reference-service';
import { projetService } from '../../../services/projet-service';
import { ProjetSpecificationsDonBody } from '../../../model/dto/body/projet-specifications-don-body';
import { ApplicationState } from '../../../store';
import { useSelector } from 'react-redux';
import { TypeCollecte } from '../../../model/entities/type-collecte';

type Props = {
    projet: Projet,
    isCreating?: boolean,
    workflowTypeCode?: string,
    onSubmitSuccess: (response: Response) => void,
    onSkipStep?: (response: WorkflowProcessResultResponse) => void;
}
type FieldData = {
    name?: string,
    value?: any,
    isValid?: boolean
}

type FormData = {
    montantRecherche: FieldData,
    montantMaximalDon: FieldData,
    montantMaximalCollecte: FieldData,
    isDefiscalisationOk: FieldData
}

const montantTotalRechercheMax: number = 1000000
const montantSouhaiteLabel: string = "Montant recherché pour la collecte de dons"
const montantMaximalCollecteLabel: string = "Montant maximal que peut atteindre la collecte de dons" 
const montantMaximalDonLabel: string = "Montant maximal par don"
const isDefiscalisationOkLabel: string = "J'accepte que les dons reçus soient soumis au régime de défiscalisation légal de 60%"

export default function FinancementDons({projet, isCreating, workflowTypeCode, onSubmitSuccess, onSkipStep}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    
    const [form, setForm] = useState<FormData>({
        montantRecherche: {name: 'montantRecherche', value: projet?.MontantRechercheDon ? projet.MontantRechercheDon / 100 : '', isValid: true},
        montantMaximalDon: {name: 'montantMaximalDon', value: projet ? (projet.MontantMaxPersonneDon ? projet.MontantMaxPersonneDon / 100 : '') : '', isValid: true},
        montantMaximalCollecte: {name: 'montantMaximalCollecte', value: projet ? (projet.MontantMaxCollecteDon ? projet.MontantMaxCollecteDon / 100 : '') : '', isValid: true},
        isDefiscalisationOk : {name: 'isDefiscalisationOk', value: '', isValid: true} 

    })

    const [isFormError, setIsFormError] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
      
        setForm({ ...form,  ...newField});
        setIsFormError(false)
    }
    const validateMandatory = (value: any): boolean => {
        return value && value !== ""
    }
    
    const validateMontantSouhaite = (value: any): boolean => {
        const montant = value as number
        const regex = /[0-9]{1,7}/g;
        return validateMandatory(value) && montant > 0 && montant <= montantTotalRechercheMax && regex.test(value)
    }

    const validateMontantMaximalCollecte = (value: any): boolean => {
        return validateMandatory(value) && parseInt(value) <= montantTotalRechercheMax 
    }

    const isFormValid = (): boolean => {
        return validateMontantSouhaite(form.montantRecherche.value) &&
                validateMontantMaximalCollecte(form.montantMaximalCollecte.value) &&
                validateMandatory(form.montantMaximalDon.value) &&
                validateMandatory(form.isDefiscalisationOk.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const onPreSubmit = (): void => {
        setIsFormSubmitted(true)
    }

    const body: ProjetSpecificationsDonBody = {
        MontantMaxCollecteDon: form.montantMaximalCollecte.value * 100,
        MontantMaxDonByUser : form.montantMaximalDon.value * 100,
        MontantRechercheDon : form.montantRecherche.value * 100,
        WorkflowTypeCode : workflowTypeCode,
        IsPageDonCombine: projet.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON
    }

    const nextFinacementDonsStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode ?? '',
            CurrentFlowStep : FlowStep.FINANCEMENT_DES_DONS,
            RessourceId:  projet?.Id!,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipStep && onSkipStep(response)})
    }

  return (
    <Form>
         <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <Form.Group>
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantRecherche}
                        submitted={isFormSubmitted}
                        label={montantSouhaiteLabel}
                        error="Merci de renseigner un montant de collecte recherché non nul et inférieur à 1 million d'euros"
                        onChange={(e) => handleInputChange(e, form.montantRecherche.name)}
                        validateField={validateMontantSouhaite}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantMaximalCollecte}
                        submitted={isFormSubmitted}
                        label={montantMaximalCollecteLabel}
                        error="Merci de renseigner le montant total que peut atteindre la collecte, le seuil maximal étant de 1 million d'euros"
                        onChange={(e) => handleInputChange(e, form.montantMaximalCollecte.name)}
                        validateField={validateMontantMaximalCollecte}
                    />
                </Form.Field>
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantMaximalDon}
                        submitted={isFormSubmitted}
                        label={montantMaximalDonLabel}
                        error="Merci de renseigner le montant maximal pour un don"
                        onChange={(e) => handleInputChange(e, form.montantMaximalDon.name)}
                        validateField={validateMandatory}
                    />
                </Form.Field>
                
            </Form.Group>
            <Form.Field width={16}>
                <CheckBoxSimpleField 
                    data={form.isDefiscalisationOk}
                    submitted={isFormSubmitted}
                    label={isDefiscalisationOkLabel}
                    error="Merci d'accepter que les dons reçus puissent être défiscalisés"
                    validateField={validateMandatory}
                    onChange={(value: any, isValid: boolean) => {
                        setForm({...form, isDefiscalisationOk: {name: "isDefiscalisationOk", value: value, isValid: isValid}})
                    }}
                />
            </Form.Field>
            <div css={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }} 
                className="button-bar">
                <SubmitButton 
                    data={body}
                    label="Valider"
                    action={() => projetService.saveSpecificationsDonProjet(body, projet && projet.Id, loginProps && loginProps.oauth)}
                    onActionSuccess={onSubmitSuccess}
                    onFormNotValid={onFormNotValid}
                    validateForm={isFormValid}
                    onPreSubmit={onPreSubmit}
                    debounceTimming={isCreating ? 1000 : undefined}
                />
                {isCreating && (
                    <SimpleButton 
                        label="Passer cette étape"
                        onClick={() => nextFinacementDonsStepKey()}
                    />
                )}
            </div>
    </Form>
  )
}
