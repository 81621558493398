import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { HorizontalTimeline, TimelineElement } from "../../generic/horizontal-timeline"
import { InfosGeneralesProjetForm } from "./infos-generales-projet-form"
import { referenceService } from "../../../services/reference-service"
import { ApplicationState } from "../../../store";
import { ProjetDetailResponse, WorkflowProcessResultResponse } from "../../../model/dto/response"
import { DescriptionProjetForm } from "./description-projet-form"
import { FinancementGlobalForm } from "./financement-global-form"
import Projet from "../../../model/projet"
import { FinancmentParticipatifForm } from "./financement-participatif-form"
import { CagnotteForm } from "./cagnotte-form"
import { FinalisationProjetForm } from "./finalisation-projet-form"
import { FlowStep } from "../../../model/entities/flow-step"
import PeriodeDonForm from "./periode-don-form"
import FinancementDons from "./financement-dons"
import { AuthenticationState } from "../../../model/state/authentication-state"
import FinancementProjetWorkflowSwitch from "../financement-projet-workflow-switch"

type Props = {
    projet: Projet,
    workflowTypeCode?: string,
    loginProps?: AuthenticationState,
    onNextStep: (stepKey: string) => void
}

function CreateProjetWorkflowForm({projet, workflowTypeCode, loginProps, onNextStep}: Props) {
    const [timelineElements, setTimelineElements] = useState<TimelineElement[]>([])
    const [activeStepKey, setActiveStepKey] = useState(FlowStep.INFORMATIONS_GENERALES)
    const [currentProjet, setCurrentProjet] = useState<Projet>(projet)
    const [activeSubStepKey, setActiveSubStepKey] = useState<string | null >(null)

    const body = {
        WorkflowTypeCode : workflowTypeCode ?? ''
    }

    useEffect(() => {
        referenceService.getWorkflowStepListByWorkflowTypeCode(body)
        .then(response => {
            if(response.IsTraitementOk) {
                const workflowListRes: TimelineElement[] | undefined = response.WorkflowStepList && 
                response.WorkflowStepList.map(res => {
                return {
                    key: res.Key,
                    dataMainInfo: res.Libelle,
                    order:res.Order
                } as TimelineElement
          })
                setTimelineElements(workflowListRes ?? [])
            }
        })
    }, [projet])

    const onNextStepSuccess = (response: ProjetDetailResponse) => {
        setActiveStepKey(response.NextStepKey!)
        setCurrentProjet(response.Projet!)
        onNextStep(response.NextStepKey!)
        if(response.NextSubStepKey === null || response.NextSubStepKey === undefined){
            setActiveSubStepKey(null)
        }else{
            setActiveSubStepKey(response.NextSubStepKey)
        }
    }

    const goToNextStep = (response: WorkflowProcessResultResponse) => {
        if(response.IsTraitementOk){
          setActiveStepKey(response.NextFlowStepCode!)
          setActiveSubStepKey(response.NextSubStepCode!)
        }
    }

    return (
        <>
            <HorizontalTimeline
                elements={timelineElements}
                activeElementKey={activeStepKey}
                lastElementKey={FlowStep.FINALISATION}
            />
            {activeStepKey === FlowStep.INFORMATIONS_GENERALES && (
                <InfosGeneralesProjetForm 
                    projet={currentProjet}
                    isCreating={true}
                    onSubmitSuccess={(response: Response) => {
                        onNextStepSuccess(response)
                    }}
                    onSkipStep={goToNextStep}
                    workflowTypeCode={workflowTypeCode}
                />
            )}
            {activeStepKey === FlowStep.DESCRIPTION && (
                <DescriptionProjetForm 
                    projet={currentProjet}
                    isCreating={true}
                    onSubmitSuccess={(response: Response) => {
                        onNextStepSuccess(response)
                    }}
                    onSkipStep={goToNextStep}
                    workflowTypeCode={workflowTypeCode}
                />
            )}
            {activeStepKey === FlowStep.FINANCEMENT_GLOBAL && (
                <FinancementGlobalForm 
                    projet={currentProjet}
                    isCreating={true}
                    onSubmitSuccess={(response: Response) => {
                        onNextStepSuccess(response)
                    }}
                    onSkipStep={goToNextStep}
                    workflowTypeCode={workflowTypeCode}
                />
            )}
            {activeStepKey === FlowStep.FINANCEMENT_PARTICIPATIF && (
                <FinancementProjetWorkflowSwitch 
                    projet={currentProjet}
                    onSubmitSuccess={(response: Response) => {
                        onNextStepSuccess(response)
                    }}
                    onSkipStep={goToNextStep}
                    activeSubStepKey={activeSubStepKey}
                    workflowTypeCode={workflowTypeCode}
                    isDon={false}
                />
            )}
            {activeStepKey === FlowStep.PERIODE_DE_COLLECTE && (
                <CagnotteForm 
                    projet={currentProjet}
                    isCreating={true}
                    onSubmitSuccess={(response: Response) => {
                        onNextStepSuccess(response)
                    }}
                    onSkipStep={goToNextStep}
                    workflowTypeCode={workflowTypeCode}
                />
            )}
            {activeStepKey === FlowStep.PERIODE_DE_DON && 
                <PeriodeDonForm 
                    projet={currentProjet}
                    isCreating={true}
                    onSubmitSuccess={(response: Response) => {
                        onNextStepSuccess(response)
                    }}
                    onSkipStep={goToNextStep}
                    workflowTypeCode={workflowTypeCode}
                />
            }
            {activeStepKey === FlowStep.FINANCEMENT_DES_DONS && 
                   <FinancementProjetWorkflowSwitch 
                   projet={currentProjet}
                   onSubmitSuccess={(response: Response) => {
                       onNextStepSuccess(response)
                   }}
                   onSkipStep={goToNextStep}
                   activeSubStepKey={activeSubStepKey}
                   workflowTypeCode={workflowTypeCode}
                   isDon={true}
               />
            }
            {activeStepKey === FlowStep.FINALISATION && (
                <FinalisationProjetForm 
                    projet={currentProjet}
                />
            )}
        </>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedCreateProjetWorkflowForm = connect(mapStateToProps, null)(CreateProjetWorkflowForm);
export { ConnectedCreateProjetWorkflowForm as CreateProjetWorkflowForm };