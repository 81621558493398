import React from "react";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { OAuthResponse } from "../../model/authentication/oauth-response";
import { DefaultResponse } from "../../model/dto/response";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { useDebouncedCallback } from "use-debounce";

export interface SubmitButtonProps {
    loginProps?: AuthenticationState;
    label: string;
    data?: any;
    style?: any;
    name?: string;
    backgroundColor?: string;
    textColor?: string;
    loaderArea?: string;
    type?: string;
    id?:string;
    debounceTimming?:number;
    action?: (data: any, oauth?: OAuthResponse) => Promise<DefaultResponse>;
    onClick?: () => void;
    validateForm?: () => boolean;
    onFormNotValid?: () => void;
    onActionSuccess?: (response: Response) => void;
    onActionFailure?: (exception: any) => void;
    onPreSubmit?: () => void;
    icon?: string;
}

function SubmitButton(props: SubmitButtonProps) {
    const btn = {
        backgroundColor: props.backgroundColor ?? "#005870",
        color: props.textColor ?? "#ffffff",
        ...props.style,
    };
    const timer = props.debounceTimming ?? 2000
    const debounce =  useDebouncedCallback(() => (props.action && props.action(props.data, props.loginProps?.oauth)) || (props.onClick && props.onClick()), timer, {leading: true})

    const onSubmitButtonClick = (e: any) => {
        if (props.onPreSubmit) {
            props.onPreSubmit();
        }
        if (
            !props.validateForm ||
            (props.validateForm && props.validateForm())
        ) {
            const name = e.target.name;
            const element = document.getElementsByName(name).item(0);
            if (element) {
                const ele = element as HTMLButtonElement;
                ele.disabled = true;
                setTimeout(function () {
                    ele.disabled = false;
                }, 5000);
            }

            if (props.action) {
                if (props.loaderArea) {
                    trackPromise(
                        debounce()!
                            .then((rep) => {
                                if (!rep.IsTraitementOk) {
                                    if (props.onActionFailure) {
                                        props.onActionFailure(rep);
                                    }
                                } else {
                                    props.onActionSuccess?.(rep);
                                }
                            })
                            .catch((ex) => {
                                if (props.onActionFailure) {
                                    props.onActionFailure(ex);
                                }
                            }),
                        props.loaderArea
                    );
                } else {
                    trackPromise(
                        debounce()!
                            .then((rep) => {
                                if (rep.IsTraitementOk === false) {
                                    if (element) {
                                        const ele =
                                            element as HTMLButtonElement;
                                        ele.disabled = false;
                                    }
                                    if (props.onActionFailure) {
                                        props.onActionFailure(rep);
                                    }
                                } else {
                                    props.onActionSuccess?.(rep);
                                }
                            })
                            .catch((ex) => {
                                if (props.onActionFailure) {
                                    props.onActionFailure(ex);
                                }
                            })
                    );
                }
            } else if (props.onClick) {
                debounce()
            }
        } else {
            if (props.onFormNotValid) {
                props.onFormNotValid();
            }
        }
    };

    return (
        <div className="button-action center">
            <button
                type="button"
                style={btn}
                className="btn btn-primary"
                name={props.name ?? "submit"}
                onClick={onSubmitButtonClick}
                id={props.id}
            >
                <div style={props.icon ? {display: 'flex', justifyContent: 'center', alignItems:'center' } : {display: 'flex', justifyContent: 'center' }} >
                    {props.label}
                    {props.icon && (
                    <img src={props.icon} alt='icon flèche'/>
                )}
                </div>
            </button>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication,
});

const ConnectedSubmitButton = connect(mapStateToProps, null)(SubmitButton);
export { ConnectedSubmitButton as SubmitButton };
