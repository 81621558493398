import ProjetObjectifs from "../model/entities/projet-objectifs";

export const calcultePurcentBar = (start : number, end: number) =>{
    const pourcentage = start / end * 100
    return Math.round(pourcentage * 100) / 100
}

export const calculateNextObjectif = (montantCollecte: number | null, objectifsList: ProjetObjectifs[], montantMaxCollecte : number) => {
    if(montantCollecte === null){
        montantCollecte = 0
    }
    if(!objectifsList || objectifsList.length < 1 || montantCollecte >= objectifsList[objectifsList.length - 1].Montant!){
        return montantMaxCollecte / 100
    }else {
        if(montantCollecte >= objectifsList[objectifsList.length - 1].Montant!){
            return montantMaxCollecte / 100
        }
        return objectifsList.find(objectif => objectif.Montant! > montantCollecte!)?.Montant! / 100
    }
}

export const isUnderFirstObjectif = (montantCollecte: number | null, objectifsList: ProjetObjectifs[]) => {
    if(!montantCollecte || montantCollecte === null || montantCollecte < objectifsList[0].Montant! ) return true
    return false
}

export const returnLastObjectifAttemptedOrder = (montantCollecte: number , objectifsList: ProjetObjectifs[]) => {
    let order = 0
    const lastObjectif = objectifsList[objectifsList.length - 1]
    if(montantCollecte >= lastObjectif.Montant!){
        order = lastObjectif.Order!
    }else {
        const indexOfNext = objectifsList.findIndex(objectif => objectif.Montant! > montantCollecte!)
        order = objectifsList[indexOfNext - 1].Order!
    }
    
    return order === 1 ? '1er' : `${order}e`
}