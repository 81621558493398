import React, {useState} from 'react'
import { FinancmentParticipatifForm } from './forms/financement-participatif-form'
import Projet from '../../model/projet'
import { AuthenticationState } from '../../model/state/authentication-state'
import { ProjetDetailResponse, WorkflowProcessResultResponse } from '../../model/dto/response'
import ObjectifsForm from './forms/objectifs-form'
import { SubFlowStep } from '../../model/entities/sub-flow-step'
import FinancementDons from './forms/financement-dons'

type Props = {
  projet: Projet,
  workflowTypeCode?: string,
  loginProps?: AuthenticationState,
  isDon: boolean,
  activeSubStepKey: string | null 
  onSkipStep: (response: WorkflowProcessResultResponse) => void
  onSubmitSuccess: (response: ProjetDetailResponse) => void
}

export default function FinancementProjetWorkflowSwitch({projet, workflowTypeCode, isDon, activeSubStepKey, onSubmitSuccess, onSkipStep}: Props) {
  
  if(isDon) {
    return <div>
      {(activeSubStepKey === null || activeSubStepKey === SubFlowStep.CARACTERISTIQUES_FINANCEMENT_DON) &&
        <FinancementDons
          projet={projet}
          isCreating={true}
          onSubmitSuccess={(response: ProjetDetailResponse) => {
            onSubmitSuccess(response)
          }}
          onSkipStep={onSkipStep}
          workflowTypeCode={workflowTypeCode}
        />
      }
      { activeSubStepKey === SubFlowStep.OBJECTIFS_PROJET_DON && 
        <ObjectifsForm 
          projet={projet}
          isDon={isDon}
          isCreating={true}
          onSkipStep={onSkipStep}
          workflowTypeCode={workflowTypeCode}
          onSubmitSuccess={(response: ProjetDetailResponse) => {
            onSubmitSuccess(response)
          }}
        />
      }
    </div>
  } else {
    return (
      <div>
      {(activeSubStepKey === null || activeSubStepKey === SubFlowStep.CARACTERISTIQUES_FINANCEMENT_EMPRUNT) && 
        <FinancmentParticipatifForm
          projet={projet}
          onSkipStep={onSkipStep}
          onSubmitSuccess={(response: ProjetDetailResponse) => {
            onSubmitSuccess(response)
          }}
            isCreating={true}
            workflowTypeCode={workflowTypeCode}
        />
      }
      { activeSubStepKey === SubFlowStep.OBJECTIFS_PROJET_EMPRUNT && 
        <ObjectifsForm 
          projet={projet}
          isDon={isDon}
          isCreating={true}
          onSkipStep={onSkipStep}
          workflowTypeCode={workflowTypeCode}
          onSubmitSuccess={(response: ProjetDetailResponse) => {
            onSubmitSuccess(response)
          }}
        />
      }
      
    </div>
    )
    
  }
}