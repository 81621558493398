import { trackPromise } from "react-promise-tracker";
import { OAuthResponse } from "../model/authentication/oauth-response";
import { AlerterUserBody } from "../model/dto/body/alerter-user-body";
import { AlerterUserRepriseBody } from "../model/dto/body/alerter-user-reprise-body";
import { AnalyseVillyzBody } from "../model/dto/body/analyse-villyz-body";
import { AnalyseVillyzMultipleDocuments } from "../model/dto/body/analyse-villyz-multiple-document-body";
import { AnnulationLeveeBody } from "../model/dto/body/annulation-levee-body";
import { DescriptionDocumentsBody } from "../model/dto/body/description-documents-body";
import { ModificationLeveeBody } from "../model/dto/body/modification-levee-body";
import { PostReactionBody } from "../model/dto/body/post-reaction-body";
import { ProjetCompleteBody } from "../model/dto/body/projet-complete-body";
import { ProjetDescriptionBody } from "../model/dto/body/projet-description-body";
import { ProjetFinancementGlobalBody } from "../model/dto/body/projet-financement-global-body";
import { ProjetFinancementParticipatifBody } from "../model/dto/body/projet-financement-participatif-body";
import { ProjetGeneralInfosBody } from "../model/dto/body/projet-general-infos-body";
import { ProjetPeriodeCollecteBody } from "../model/dto/body/projet-periode-collecte-body";
import { ReouvertureLeveeBody } from "../model/dto/body/reouverture-levee-body";
import { SaveMontantPartVariableBody } from "../model/dto/body/save-montant-part-variable";
import { SimulationBody } from "../model/dto/body/simulation-body";
import { SuivreProjetBody } from "../model/dto/body/suivre-projet-body";
import { SuspendreLeveeBody } from "../model/dto/body/suspendre-levee-body";
import { TableauAmortissementRequestBody } from "../model/dto/body/tableau-amortissement-request-body";
import { BodyValidationDebouclage } from "../model/dto/body/validation-debouclage-body";
import {
  DefaultResponse,
  ProjetDetailResponse,
  ProjetItemResponse,
  ProjetListResponse,
  ProjetUploadResponse,
  SimulationResponse,
  IsProjetSuiviResponse,
  PostCreationResponse,
  UserReactionsReponse,
  IsUserAlertedResponse,
  PostListResponse,
  BankProviderListResponse,
  TableauAmortissementResponse,
  CalculateMontantPrevisionnelCollecteResponse,
} from "../model/dto/response";
import { CollectivitePostPublique } from "../model/entities/collectivite-post-publique";
import { treatHttpResponseCode } from "./http-helper";
import { ProlongationDureeCollecteAdminBody } from "../model/dto/body/prolongation-duree-collecte-admin-body";
import { ProjetTypeCollecteBody } from "../model/dto/body/projet-type-collecte-body";
import { ProjetPeriodeDonBody } from "../model/dto/body/projet-periode-don-body";
import { ProjetSpecificationsDonBody } from "../model/dto/body/projet-specifications-don-body";
import { ProcessProjectToAVenirStatutBody } from "../model/dto/body/process-to-avenir-statut-body";
import { PublishBody } from "../model/dto/body/publish-body";
import { RegisterObjectifsProjetBody } from "../model/dto/body/register-objectifs-projet-body";

export const projetService = {
  deletePicture,
  uploadPicture,
  publishProjet,
  getProjetBySlug,
  getProjetBySlugAdmin,
  getProjetItemById,
  getProjetList,
  getProjetAdmin,
  createProjet,
  saveProjetComplete,
  saveProjetInfosGenerales,
  saveDescriptionProjet,
  saveFinancementGlobalProjet,
  saveFinancementParticipatifProjet,
  savePeriodeCollecteProjet,
  getSimulatedInvestment,
  generateTableauAmortissement,
  validateVillyzDebouclagePieceJustificative,
  publishAnalyseVillyzInfo,
  prolongationProjet,
  suivreProjet,
  isProjetSuivi,
  createPost,
  updatePost,
  deletePost,
  reactToPost,
  getReactionsByUserId,
  publishAnalyseVillyzMultipleDocuments,
  alerterUser,
  isUserAlerted,
  getPostList,
  getBankProviders,
  deleteProject,
  suspendreLevee,
  reouvertureLevee,
  modificationLevee,
  annulationLevee,
  alerterUserReprise,
  isUserAlertedReprise,
  saveMontantPartVariable,
  registerDescriptionDocuments,
  getProjetPublies,
  ProcessProjectToAVenirStatut,
  calculateMontantPrevisionnelCollecte,
  saveTypeCollecteProjet,
  savePeriodeDonProjet,
  saveSpecificationsDonProjet,
  registerObjectifsProjet
};


function getProjetList(oauth: OAuthResponse | undefined, isMecenatTabClicked: boolean): Promise<ProjetListResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets?isMecenat=${isMecenatTabClicked}`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetListResponse>
  })
}

function getProjetAdmin(oauth: OAuthResponse | undefined, isMecenatTabClicked: boolean): Promise<ProjetListResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/admin?isMecenat=${isMecenatTabClicked}`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetListResponse>
  })
}

function getProjetPublies(oauth: OAuthResponse | undefined): Promise<ProjetListResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/publies`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetListResponse>
  })
}

function getProjetItemById(projetId: number, oauth: OAuthResponse | undefined): Promise<ProjetItemResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/item`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetItemResponse>
  })
}

function publishProjet(projetId: number, body: PublishBody, oauth?: OAuthResponse): Promise<DefaultResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body)
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/publish`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<DefaultResponse>
    })
}

function prolongationProjet(body: ProlongationDureeCollecteAdminBody, projetId: number, oauth: OAuthResponse | undefined): Promise<ProjetUploadResponse> {
  
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body)
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/prolongation`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetUploadResponse>
  })
}

async function uploadPicture(projetId: number, data: File, oauth: OAuthResponse | undefined): Promise<ProjetUploadResponse> {
  let formData = new FormData();
  formData.append("file", data);

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: formData,
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/upload`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetUploadResponse>
  })
}

async function deletePicture(projetId: number, ressourceId: number, oauth: OAuthResponse | undefined): Promise<ProjetUploadResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + oauth?.access_token,
    }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/remove/${ressourceId}`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetUploadResponse>
  })
}

function validateVillyzDebouclagePieceJustificative(projetId: number| undefined, oauth: OAuthResponse | undefined, body: BodyValidationDebouclage): Promise<DefaultResponse> {
  let formData = new FormData();

  const files = body.userPieceList;
  const isDon = body.IsDon && body.IsDon ? "true" : "false"

  for(let i = 0; i < files.length; i++) {
      formData.append("file" + i, files[i].Document!);
      formData.append("typePiece" + i, files[i].TypePiece!.toString());
  }
  
  formData.append("DateSignature", body.DateSignature);
  formData.append("Iban", body?.Iban ?? "")

  const requestOptions = {
      method: 'POST',
      headers: {
          'Authorization': 'Bearer ' + oauth?.access_token 
      },
      body: formData
  };

  return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth!.userId}/projet/${projetId}/register-typed-piece-validation?isDon=${isDon}`, requestOptions)
  .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<DefaultResponse>
  }))
}

function getProjetBySlug(oauth: OAuthResponse | undefined, slug?: string, isPageDon?: boolean): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
  };

  return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/` + slug + `?isPageDon=${isPageDon}`, requestOptions))
}

function getProjetBySlugAdmin(oauth: OAuthResponse | undefined, slug?: string, isPageDon?: boolean): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
  };

  return trackPromise(fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/` + slug + `/admin?isPageDon=${isPageDon}`  , requestOptions))
}

function saveProjetComplete(body: ProjetCompleteBody, projetId: number | undefined, oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/save-complete`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}

function saveProjetInfosGenerales(body: ProjetGeneralInfosBody, projetId: number | undefined, oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/save-general-infos-projet`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}

function saveDescriptionProjet(body: ProjetDescriptionBody, projetId: number | undefined, oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/save-description-projet`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}

function saveFinancementGlobalProjet(body: ProjetFinancementGlobalBody, projetId: number | undefined, oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/save-financement-global-projet`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}

function saveFinancementParticipatifProjet(body: ProjetFinancementParticipatifBody, projetId: number | undefined, oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/save-financement-participatif-projet`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}

function savePeriodeCollecteProjet(body: ProjetPeriodeCollecteBody, projetId: number | undefined, oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/save-periode-collecte-projet`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}

function publishAnalyseVillyzInfo(body: AnalyseVillyzBody, projetId: number | undefined, oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/analyse-villyz-info`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function createProjet(oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}

function getSimulatedInvestment(body: SimulationBody, projectId: number): Promise<SimulationResponse> {
  let userStorage = localStorage.getItem("oauth_user");
  let oauthResponse: OAuthResponse | undefined = userStorage
    ? JSON.parse(userStorage)
    : undefined;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauthResponse?.access_token,
    },
    body: JSON.stringify(body)
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/public/projets/${projectId}/simulation`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<SimulationResponse>
    })
}

function generateTableauAmortissement(body: TableauAmortissementRequestBody, projetId: number | null, oauth: OAuthResponse | undefined) : Promise<TableauAmortissementResponse> {
  const requestOptions = {
      method: 'POST',
      headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + oauth?.access_token
      },
      body: JSON.stringify(body)
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/simulation`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<TableauAmortissementResponse>
  })
}

function suivreProjet(body: SuivreProjetBody, projetId: number, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: 'POST',
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauth?.access_token
    },
    body: JSON.stringify(body) 
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/suivre`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function isProjetSuivi(projetId: number, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: 'GET',
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauth?.access_token
    }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/is-suivi`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<IsProjetSuiviResponse>
  })
}

function updatePost(projectId: number, postId: number, body: CollectivitePostPublique, oauth: OAuthResponse | undefined): Promise<DefaultResponse>{
  let formData = new FormData();
  formData.append("description", body.DescriptionPost!);
  formData.append("lexicalState", body.LexicalEditorState!)

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: formData
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projectId}/posts/${postId}`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function createPost(projectId: number, oauth: OAuthResponse | undefined) : Promise<PostCreationResponse>{
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + oauth?.access_token,
    }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projectId}/create-post`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<PostCreationResponse>
  })
}

async function deletePost(postId: number, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + oauth?.access_token,
    }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/posts/${postId}/delete-post`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function reactToPost(body: PostReactionBody, postId: number, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + oauth?.access_token
    },
    body: JSON.stringify(body) 
  };
  
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/posts/${postId}/post-react`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function getReactionsByUserId(postId: number, oauth: OAuthResponse | undefined): Promise<UserReactionsReponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + oauth?.access_token
    }
  };
  
  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/posts/${postId}/get-react`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<UserReactionsReponse>
  })
}

function alerterUser(body: AlerterUserBody, projetId: number, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: 'POST',
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauth?.access_token
    },
    body: JSON.stringify(body) 
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/alerter`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function isUserAlerted(projetId: number, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: 'GET',
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauth?.access_token
    }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/is-alerted`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<IsUserAlertedResponse>
  })
}

function getPostList(projectId: number, oauth: OAuthResponse | undefined): Promise<PostListResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + oauth?.access_token
    },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projectId}/allposts`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<PostListResponse>
  })
}

function getBankProviders(oauth: OAuthResponse | undefined): Promise<BankProviderListResponse> {
  const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + oauth?.access_token
      }
    };
  
    return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/banks`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<BankProviderListResponse>
    })
}

function deleteProject(projectId: number, oauth: OAuthResponse | undefined): Promise<DefaultResponse> {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + oauth?.access_token
    },
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projectId}/delete-project`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function  publishAnalyseVillyzMultipleDocuments(projetId: number| undefined, oauth: OAuthResponse | undefined, body:AnalyseVillyzMultipleDocuments): Promise<DefaultResponse> {
  let formData = new FormData();
  
  const files = body.analysePieceList;

  for(let i = 0; i < files.length; i++) {
      formData.append("file" + i, files[i].Document!);
      formData.append("typePiece" + i, files[i].TypePiece!.toString());
  }
  const requestOptions = {
      method: 'POST',
      headers: {
          'Authorization': 'Bearer ' + oauth?.access_token 
      },
      body: formData
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth!.userId}/projet/${projetId}/analyse-villyz-multiple-documents`, requestOptions)
  .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<DefaultResponse>
  })
}

function suspendreLevee(body: SuspendreLeveeBody, projetId: number | undefined, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/suspendre-levee`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function reouvertureLevee(body: ReouvertureLeveeBody, projetId: number | undefined, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/reouverture-levee`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function modificationLevee(body: ModificationLeveeBody, projetId: number | undefined, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/modification-levee`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function annulationLevee(body: AnnulationLeveeBody, projetId: number | undefined, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/annulation-levee`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function alerterUserReprise(body: AlerterUserRepriseBody, projetId: number, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: 'POST',
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauth?.access_token
    },
    body: JSON.stringify(body) 
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/alerterReprise`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function isUserAlertedReprise(projetId: number, oauth: OAuthResponse | undefined) {
  const requestOptions = {
    method: 'GET',
    headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauth?.access_token
    }
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/is-alerted-reprise`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<IsUserAlertedResponse>
  })
}

function saveMontantPartVariable(body: SaveMontantPartVariableBody, oauth: OAuthResponse | undefined, projetId?: number): Promise<DefaultResponse>{
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + oauth?.access_token
    },
    body: JSON.stringify(body) 
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projet/${projetId}/save-montant-part-variable`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function registerDescriptionDocuments(body: DescriptionDocumentsBody, projetId: number, oauth: OAuthResponse | undefined): Promise<DefaultResponse>{
  let formData = new FormData();
  
  const files = body.Documents;

  for(let i = 0; i < files.length; i++) {
    formData.append("file" + i, files[i].Document!);
    formData.append("name" + i, files[i].Name!);
  }

  const requestOptions = {
    method: 'POST',
    headers: {
        'Authorization': 'Bearer ' + oauth?.access_token 
    },
    body: formData
};

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/register-description-documents`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<DefaultResponse>
  })
}

function ProcessProjectToAVenirStatut(projetId: number, body: ProcessProjectToAVenirStatutBody, oauth?: OAuthResponse): Promise<DefaultResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body : JSON.stringify(body)
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/process-project-to-avenir-statut`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<DefaultResponse>
    })
}

function calculateMontantPrevisionnelCollecte(projectId: number, oauth: OAuthResponse | undefined, isDon: boolean): Promise<CalculateMontantPrevisionnelCollecteResponse>{
  const requestOptions = {
    method: 'GET',
    headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + oauth?.access_token 
    }
};

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projectId}/sum-previsionnel-for-project?isDon=${isDon}`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<CalculateMontantPrevisionnelCollecteResponse>
  })
}

function saveTypeCollecteProjet(body: ProjetTypeCollecteBody, oauth?: OAuthResponse | undefined, projetId?: number){
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body)
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/save-type-collecte-projet`, requestOptions)
    .then(response => {
      treatHttpResponseCode(response.status)
      return response.json() as Promise<DefaultResponse>
    })
}

function savePeriodeDonProjet(body: ProjetPeriodeDonBody, projetId: number | undefined, oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/save-periode-don-projet`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}

function saveSpecificationsDonProjet(body: ProjetSpecificationsDonBody, projetId: number | undefined, oauth: OAuthResponse | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  };

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/save-specifications-don-projet`, requestOptions)
  .then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}

function registerObjectifsProjet(body: RegisterObjectifsProjetBody, oauth: OAuthResponse | undefined, projetId: number | undefined): Promise<ProjetDetailResponse> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + oauth?.access_token,
    },
    body: JSON.stringify(body),
  }

  return fetch(`${process.env.REACT_APP_BASE_URL}/api/${oauth?.userId}/projets/${projetId}/register-objectifs-projet`, requestOptions).then(response => {
    treatHttpResponseCode(response.status)
    return response.json() as Promise<ProjetDetailResponse>
  })
}