import { addDays, differenceInDays, parseISO } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Card, Icon, Image, Modal, ModalContent, Popup } from "semantic-ui-react";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ProjetListItem } from "../../model/dto/projet-list-item";
import { useNavigate } from "react-router-dom";
import { labelsPictos, sectorsIcons } from "./detail/parts/general-infos";
import { StatutProjet } from "../../model/entities/statut-projet";
import { DateFinCard } from "./date-fin-card";
import { ProjetStatut } from "./projet-statut";
import { projetService } from "../../services/projet-service";
import { SubmitButton } from "../generic/submit-button";
import { TypeEmprunt } from "../../model/entities/type-emprunt";
import { ProjetLocalisation } from "./projet-localisation";
import { TypeCollecte } from "../../model/entities/type-collecte";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { calculateNextObjectif, calcultePurcentBar } from "../../helpers/objectifs-helper";
import { publicService } from "../../services/public-service";
import ProgressBar from "@ramonak/react-progress-bar";

export interface ProjetLineProps {
  projet: ProjetListItem;
  isCardClickable?: boolean;
  isDon?: boolean;
  reload?: ()=>void;
}

export function ProjetLine({projet, isCardClickable, isDon, reload}: ProjetLineProps) {
  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  const [open, setOpen] = useState(false)
  const [objectifs, setObjectifs] = useState<any[]>([])
  const navigate = useNavigate();

  useEffect(() => {
    const typeCollecte: string = isDon ? TypeCollecte.DON : TypeCollecte.EMPRUNT_CITOYEN
    publicService.getObjectifsProjet(projet.Id, typeCollecte).then((response) => {
      setObjectifs(response.ObjectifList ?? [])
    })
  },[])

  const onCardClick = (slug?: string): void => {
    if(isDon) {
      navigate(`/projets/${slug}/don`);
    }
    else {
      navigate(`/projets/${slug}`);
    }
  };
  
  const statutTermines = [
    StatutProjet.CLOTURE, 
    StatutProjet.DEBOUCLAGE_DEMANDE, 
    StatutProjet.DEBOUCLAGE_POSSIBLE,
    StatutProjet.DEBOUCLAGE_VALIDE,
    StatutProjet.DEBOUCLAGE_FINALISE,
    StatutProjet.FINANCE
  ]

  const statutPrePublies = [
    StatutProjet.INCOMPLET,
    StatutProjet.COMPLET
  ]

  const daysUntilEnd = useMemo(() => {
    if(isDon){
      if (
        !projet?.DateDebutCollecteDon ||
        !projet?.DureeCollecteDon
      ) {
        return "-";
      }

      const startDate = parseISO(projet.DateDebutCollecteDon);
      const endDate = addDays(startDate, projet.DureeCollecteDon+1);

      return new Intl.NumberFormat("fr-FR", {
        style: "decimal",
      }).format(differenceInDays(new Date(), endDate));
    } else {
      if (
        !projet?.DateDebutFinancementCitoyen ||
        !projet?.DureeFinancementCitoyen
      ) {
        return "-";
      }
  
      const startDate = parseISO(projet.DateDebutFinancementCitoyen);
      
      const endDate = addDays(startDate, projet.DureeFinancementCitoyen+1);
      return new Intl.NumberFormat("fr-FR", {
        style: "decimal",
      }).format(differenceInDays(new Date(), endDate));
    }
    
  }, [projet]);

  const nextObjectif = isDon ? calculateNextObjectif(projet.MontantCollecteDon!, objectifs!, projet.MontantMaxCollecteDon!) : calculateNextObjectif(projet.MontantCollecte!, objectifs!, projet.MontantMaximalFinancement!)

  const pourcentage = useMemo(() => {
    if(!isDon && projet.MontantFinancementCitoyenDemande && projet.MontantCollecte) {
      const objectifStart = projet.MontantCollecte / 100
      return calcultePurcentBar(objectifStart, nextObjectif)
    }
    else if(isDon && projet.MontantRechercheDon && projet.MontantCollecteDon) {
      const objectifStart = projet.MontantCollecteDon / 100
      const result = projet.MontantCollecteDon / projet.MontantRechercheDon * 100
      return calcultePurcentBar(objectifStart, nextObjectif)
    }
    return 0
  }, [])

  const accessibleGrey = "#777770"
  const blueVillyz = '#005870'
  const defiscalisationInfos = 'Défiscalisation : 60%'


  return (
    <>
      <Card {...(isCardClickable && {onClick: () => onCardClick(projet.UrlSlug)})}>
        {(statutPrePublies.find(statut => statut === (isDon ?  projet.StatutDonProjetCode : projet.StatutProjetCode))) &&
          <div css={{
                position:"absolute", 
                top:"10px", 
                right:"10px",
                width:"25px", 
                height:"25px", 
                zIndex:1,
              }} 
              onClick={(e)=>{
                e.stopPropagation()
                setOpen(true)
              }}
          >
            <div css={{
                width:"25px", 
                height:"25px", 
                backgroundColor:"white",
                borderRadius:"50%",
                border:"1px solid #9e2a2b",
                padding:"2px 3px"
              }}
            >
              <Icon 
                name="trash" 
                color="red"
              />
            </div>
          </div>
        }
        <Card.Meta>
          <ProjetStatut 
            daysUntilEnd={daysUntilEnd} 
            statut={ isDon ? projet.StatutDonProjetCode! : projet.StatutProjetCode!} 
            categorieEtablissementCode={projet.CategorieEtablissement} 
            categorieEtablissementLibelle={projet.CategorieEtablissementLibelle}
          />
        </Card.Meta>
        <Image
          css={{
            background: "transparent url('/wireframe.png') center no-repeat !important",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderRightLeftRadius: "4px",
          }}
          src={
            projet.PictureId ? `${process.env.REACT_APP_BASE_URL}/public/projets/${projet.Id}/picture/${projet.PictureId}` : '/wireframe.png'
          }
          wrapped
          alt={`image du projet ${projet.Titre}`}
        />
        <Card.Content>
          <Card.Meta css={{ marginBottom: "10px"}}>
            {projet.SecteurProjetList?.map((sector) => {
              if (!sector.SecteurCode) {
                return;
              }
              return (
                <Popup
                  content={sectorsIcons[sector.SecteurCode].label}
                  key={sectorsIcons[sector.SecteurCode].label}
                  trigger={
                    <Icon
                      key={sector.SecteurCode}
                      title={sectorsIcons[sector.SecteurCode].label}
                      name={sectorsIcons[sector.SecteurCode].icon}
                      color={sectorsIcons[sector.SecteurCode].color}
                      size="big"
                    />
                  }
                />
              );
            })}
            {projet.LabelProjetList?.map((label) => {
              if (!label.LabelCode || !labelsPictos[label.LabelCode]) {
                return;
              }
             
              return (
                <img
                  key={label.LabelCode}
                  src={labelsPictos[label.LabelCode].img}
                  title={labelsPictos[label.LabelCode].label}
                  css={{ height: "29px", width: "auto" }}
                  alt="logo représentant la catégorie du projet"
                />
              );
            })}
          </Card.Meta>
          <Card.Header>
            <span css={{ fontSize: "1.15em" }}>{projet.Titre}</span>
          </Card.Header>
          <Card.Meta>
            <ProjetLocalisation 
              typeCollectiviteCode={projet.TypeCollectiviteCode} 
              codeDepartement={projet.CodeDepartement} 
              categorieEtablissement={projet.CategorieEtablissement} 
              nomEtablissement={projet.CollectiviteNom}
              nomLocalite={projet.NomLocalite}
            />
          </Card.Meta>
          <Card.Meta>
            <DateFinCard 
              projet={projet}
              isDon={isDon}
            />
          </Card.Meta>
 
          <Card.Meta css={{ paddingTop: "10px" }}>
            {!statutTermines.find(statut => statut === projet.StatutProjetCode) && !isDon &&
              <>
                <span
                  css={{
                    color: "#000000d9",
                    fontSize: "larger",
                    fontWeight: "bold",
                  }}
                >
                  {projet.MontantFinancementCitoyenDemande? nextObjectif.toLocaleString("fr-FR") : 0}€           
                </span>{" "}
                <span style={{color:accessibleGrey}}>recherchés</span>
              </>
            } 

            {!statutTermines.find(statut => statut === projet.StatutDonProjetCode) && isDon &&
              <>
                <span
                  css={{
                    color: "#000000d9",
                    fontSize: "larger",
                    fontWeight: "bold",
                  }}
                >
                  {projet?.MontantRechercheDon ? nextObjectif.toLocaleString("fr-FR") : 0}€           
               </span>{" "}
               <span style={{color:accessibleGrey}}>recherchés</span>
             </>
            }
                
            {(statutTermines.find(statut => statut === projet.StatutProjetCode)) && !isDon &&
              <> 
                <span
                  css={{
                    color: "#000000d9",
                    fontSize: "larger",
                    fontWeight: "bold",
                  }}
                >
                  {projet.MontantCollecte && (projet.MontantCollecte / 100).toLocaleString("fr-FR")}€
                </span>{" "}
                <span style={{color:accessibleGrey}}>collectés</span>
                <ProgressBar
                  completed={100}
                  maxCompleted={100} 
                  height="0.5em"
                  bgColor={blueVillyz}
                  isLabelVisible={false}
                />  
              </>
            }

            {(statutTermines.find(statut => statut === projet.StatutDonProjetCode)) && isDon &&
              <> 
                <span
                  css={{
                    color: "#000000d9",
                    fontSize: "larger",
                    fontWeight: "bold",
                  }}
                >
                  {projet.MontantCollecteDon && (projet.MontantCollecteDon / 100).toLocaleString("fr-FR")}€
                </span>{" "}
                <span style={{color:accessibleGrey}}>collectés</span>
                <ProgressBar
                  completed={100}
                  maxCompleted={100} 
                  height="0.5em"
                  bgColor={blueVillyz}
                  isLabelVisible={false}
                />  
              </>
            } 

            {!statutTermines.find(statut => statut === projet.StatutProjetCode) && !isDon &&
            <div style={{display:'flex', flexDirection:'column'}}>
              <ProgressBar
                completed={pourcentage}
                maxCompleted={100} 
                height="0.5em"
                bgColor={blueVillyz}
                isLabelVisible={false}
              />    
               <span style={{fontSize: "12px", color:'black', alignSelf:'center'}}>{projet.StatutProjetCode === StatutProjet.PUBLIE && ((projet.MontantCollecte ? projet.MontantCollecte / 100 : 0) + "€ collectés")}</span>
            </div>
            }      

            {!statutTermines.find(statut => statut === projet.StatutDonProjetCode) && isDon &&
             <div style={{display:'flex', flexDirection:'column'}}>
                <ProgressBar
                  completed={pourcentage}
                  maxCompleted={100} 
                  height="0.5em"
                  bgColor={blueVillyz}
                  isLabelVisible={false}
                />    
              <span style={{fontSize: "12px", color:'black', alignSelf:'center'}}>{projet.StatutDonProjetCode === StatutProjet.PUBLIE && ((projet.MontantCollecteDon ? projet.MontantCollecteDon / 100 : 0) + "€ collectés")}</span>
           </div>    
            }
          </Card.Meta>
          <Card.Description>{projet.DescriptionCourt}</Card.Description>
        </Card.Content>

        {isDon ? (
            <Card.Content>
              <div css={{display: "flex", flexDirection: "column", color: "black"}}>
                <div css={{display: "flex", justifyContent: "space-between"}}>
                  <span>
                    {defiscalisationInfos}
                  </span>
                  <span css={{ fontSize: "smaller", paddingLeft: "5px", color: accessibleGrey }}>
                      Mécénat d'entreprise
                    </span>
                </div>
                {projet.StatutDonProjetCode === StatutProjet.A_VENIR ? 
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button className="btn-project-disabled" disabled aria-disabled>
                      En cours de préparation
                    </button>
                  </div> 
                  :
                  <div css={{display: "flex", justifyContent: "center"}}>
                    <button className="btn btn-primary" >
                      Découvrir
                    </button>
                  </div>
                }
              </div>
            </Card.Content> 
        ) : (
        projet.StatutProjetCode === StatutProjet.A_VENIR ?
         <Card.Content>
            <div css={{display: "flex", flexDirection: "column", color: "black"}}>
              <div css={{display: "flex", justifyContent: "space-between"}}>
                <span>
                  Taux fixe
                </span>
                <span css={{ fontSize: "smaller", paddingLeft: "5px", color: accessibleGrey }}>
                  {projet.TypeEmpruntCode === TypeEmprunt.TITRES ? "Titres participatifs" : "Prêt rémunéré"} 
                </span>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="btn-project-disabled" disabled aria-disabled>
                  En cours de préparation
                </button>
              </div>
            </div>
          </Card.Content>
        : 
        <Card.Content>
          <div css={{display: "flex", flexDirection: "column", color: "black"}}>
                <div css={{display: "flex", justifyContent: "space-between"}}>
                  <div>
                    <Icon disabled name="money bill alternate" />
                    {projet.TauxInteretCitoyen}% par an
                  </div>
                  <span css={{fontSize: "smaller", paddingLeft: "5px", color: accessibleGrey}}>
                    {projet.TypeEmpruntCode === TypeEmprunt.TITRES ? "Titres participatifs" : "Prêt rémunéré"} 
                  </span>
                </div>
            <div css={{display: "flex", justifyContent: "center"}}>
              <button className="btn btn-primary" >
                Découvrir
              </button>
            </div>
         </div>
        </Card.Content>
        )
      }

      </Card>

      <Modal onClose={() => setOpen(false)} open={open}>
        <Modal.Header>Supprimer ce projet</Modal.Header>
        <Modal.Content>
          <ModalContent>
            <p>Êtes-vous sûr de vouloir supprimer ce projet ? </p>
          </ModalContent>
          <Modal.Description>
            <div css={{display:"flex", justifyContent:"center", marginTop: "20px"}}>
                <SubmitButton label = "Supprimer" action={()=>projetService.deleteProject(projet.Id!, loginProps?.oauth)} onActionSuccess={reload}/>
                <SubmitButton label = "Annuler" onClick={()=>setOpen(false)}/>
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
}