import React from 'react'
import { Container, Grid, Image } from 'semantic-ui-react'
import './agreement.css'

export default function Agreement() {
  return (
    <Container>
        <div className="section-blue-box">
            <Grid doubling stackable centered>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <div className="container-img">
                            <Image
                                src="/img/colloc/PFP.png"
                                alt="logo officiel de plate-forme de financement participatif agrée par l'état "
                                className='blue-box-img'
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <p
                            style={{
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                        >
                            Une plateforme réglementée
                        </p>
                        <p style={{ fontSize: "16px" }}>
                            Villyz est la{" "}
                            <strong>
                                première plateforme française
                            </strong>{" "}
                            agréée en tant que Prestataire de
                            Service de Financement Participatif
                            par l’
                            <strong>
                                Autorité des Marchés Financiers{" "}
                            </strong>
                            (AMF).
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    </Container>
)
}
