import React from 'react'

type Props = {
    label?: string,
    icon:string,
    isLabelFirst?: boolean,
    labelColor?: string,
    style?: {}
    onClick : ()=> void
}
export default function IconButton({onClick, label, icon, isLabelFirst, labelColor, style}: Props) {
    if(isLabelFirst){
        return (
            <button
            onClick={onClick}
            style={{fontFamily: "raleway", marginLeft:20, display:'flex', alignItems:'center', ...style}} 
            >
                <span style={{marginRight: 15}}>{label}</span>
                <img src={icon} alt={`bouton icone ${label}`}/>
                
            </button>
          )
    }
    else if(label && !isLabelFirst){
       return <button
        onClick={onClick}
        style={{fontFamily: "raleway", display:'flex', alignItems:'center', ...style}}
        >
            <span><img src={icon} alt={`bouton icone ${label}`}/></span> 
            <span style={{color: labelColor, marginLeft: 15}}>{label}</span>
        </button>
    }

    return (
        <button
        onClick={onClick}
        style={{display:'flex', alignItems:'center', ...style}}
        >
            <img src={icon} alt={`bouton icone`}/>
        </button>
    )
}
