import React from "react";
import { Button, Container, Grid, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import './rejoignez-une-commu-engagee.css'

type Props = {
    width?:number,
    isButtonEmpty?: boolean
}

export default function RejoignezUneCommuEngagee({width, isButtonEmpty}: Props) {
    return (
        <Container>
            <Grid doubling stackable centered>
                <Grid.Row>
                    <div id="rejoignez-une-communauté-container">
                        {isButtonEmpty ? 
                        <div className="first-block-empty" >
                            <h2 className="first-block-title">
                            Rejoignez une communauté d'investisseurs engagés
                            </h2>
                            <p className="first-block-subtitle">
                                Placez dès aujourd’hui votre épargne dans un
                                projet qui vous ressemble.
                            </p>
                        </div>
                        :
                         <div className="first-block" >
                              <h2
                                className="first-block-title"     
                            >
                                Rejoignez une communauté d'investisseurs engagés
                            </h2>
                            <p
                            className="first-block-subtitle"
                            >
                                Placez dès aujourd’hui votre épargne dans un
                                projet qui vous ressemble.
                            </p>
                            <div className="first-block-buttons-container">
                                <Button
                                    as={NavLink}
                                    to="/login"
                                    color="blue"
                                    className="first-block-buttons-container-inscription-button"
                                >
                                    S’inscrire
                                </Button>

                                <Button
                                    as={NavLink}
                                    to="/projets"
                                    className="first-block-buttons-container-projets-button"
                                >
                                    Découvrir les projets
                                </Button>
                            </div>
                        </div>
                        }

                        <div className="second-block">
                            <div className="bullet-paragraph-base bullet-paragraph-base-margin">                        
                                <Icon
                                    name="check circle"
                                    size="small"
                                    className="bullet-paragraph-icon"
                                />
                                <p className="bullet-paragraph-text">
                                    Valorisez votre épargne en investissant dans
                                    des projets d’avenir
                                </p>
                            </div>

                            <div className="bullet-paragraph-base bullet-paragraph-base-margin">
                                <Icon
                                    name="check circle"
                                    size="small"
                                    className="bullet-paragraph-icon"
                                />
                                <p className="bullet-paragraph-text">
                                    Financez des projets grâce à un investissement 100% dirigé et sans frais.
                                </p>
                            </div>

                            <div className="bullet-paragraph-base">
                                <Icon
                                    name="check circle"
                                    size="small"
                                    className="bullet-paragraph-icon"
                                />
                                <p className="bullet-paragraph-text">
                                    Réservé à toutes et tous : accessible dès 1€
                                    et sans condition de revenu
                                </p>
                            </div>
                        </div>
                    </div>
                </Grid.Row>
            </Grid>
        </Container>
    );
}
