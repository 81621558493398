import React from "react"
import { connect } from "react-redux"
import { SubmitButton } from "../generic/submit-button";
import { SimpleButton } from "../generic/simple-button";
import { userService } from "../../services/authentication-redux-service"
import { RegisterRepresentantsCollectiviteBody } from "../../model/dto/body/register-representant-collectivite-body";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { User } from "../../model/user";
import { Form } from "semantic-ui-react"
import { OnboardingCollectiviteResponse, ProfilCode, UserInfoResponse } from "../../model/dto/response";
import { FormErrorHeader } from "../generic/form-error-header";
import { FlowStep } from "../../model/entities/flow-step";

interface SubscriptionCollectiviteFormRepresentantsProps {
    loginProps?: AuthenticationState,
    userInfo?: UserInfoResponse
    existingCollaborateursList?: User[],
    onSkipOnboarding?: () => void,
    onSkipStep?: (nextStepKey: string) => void
    onSubmitSuccess: (response: Response) => void
    isOnboarding: boolean
    close?: () => void
}

interface SubscriptionCollectiviteFormRepresentantsState {
    newMail: string
    mailList: User[]
    errorMessage: string
    emailValid: boolean
}

class SubscriptionCollectiviteFormRepresentants extends React.Component<SubscriptionCollectiviteFormRepresentantsProps, SubscriptionCollectiviteFormRepresentantsState> {
    constructor(props: SubscriptionCollectiviteFormRepresentantsProps) {
        super(props)

        this.state = {
            newMail: '',
            mailList: [],
            errorMessage: "",
            emailValid: true
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.onAddClick = this.onAddClick.bind(this)
        this.validateMandatory = this.validateMandatory.bind(this)
        this.isFormValid = this.isFormValid.bind(this)
        this.onFormNotValid = this.onFormNotValid.bind(this)
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, emailValid: true, newMail: e.target.value, errorMessage: ""})
    }

    validateMandatory(value: any): boolean{
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return value !== "" && regex.test(value)
    }

    onAddClick() {
        if(!this.validateMandatory(this.state.newMail)){
            this.setState({...this.state, emailValid: false})
            return
        }
        this.setState({...this.state, newMail: '',mailList: [...this.state.mailList, {Mail: this.state.newMail}]})
    }

    isFormValid() {
        return this.state.mailList.length != 0
    }

    onFormNotValid() {
        return  this.setState({...this.state, errorMessage: "Merci de corriger les champs en erreur", emailValid: false})
    }

    render() {
        const body: RegisterRepresentantsCollectiviteBody = {
            MailList: this.state.mailList,
            UserMail: this.props.loginProps?.user?.Mail
        }

        const { loginProps, existingCollaborateursList, isOnboarding, onSkipStep, close } = this.props

        return (
            <div>
                <Form>
                    <FormErrorHeader 
                        message={this.state.errorMessage}
                        displayed={this.state.errorMessage != ""}
                    />

                    <Form.Field>
                        <label>Ajouter un collaborateur en renseignant son adresse mail professionnelle</label>
                        <input 
                            type="text"
                            name="newMail"
                            className="form-group"
                            value={this.state.newMail}
                            onChange={this.handleInputChange}
                        />
                        {!this.state.emailValid && (
                            <div 
                                css={{
                                    color: "#F03E3E",
                                    fontSize: "10px"
                                    }}
                            >
                                Veuillez rentrer un email valide
                            </div>
                        )}
                    </Form.Field>
                
                    <SimpleButton 
                        className="form-group"
                        label="Ajouter"
                        onClick={this.onAddClick}
                    />

                    <div className="form-group">
                        <div className="form-group">
                            Liste des collaborateurs
                        </div>
                        {existingCollaborateursList?.map(user => {
                            return (
                                <div>
                                    {user.Mail}
                                </div>
                            )
                        })
                        }
                        {this.state.mailList.map(user => {
                            return (
                                <div>
                                    {user.Mail}
                                </div>
                            )
                        })
                        }
                    </div>

                    <div className="button-bar">
                        <SubmitButton 
                            data={body}
                            label="Valider"
                            action={() => {
                                if (loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET)
                                {
                                    return userService.registerRepresentantsCollectivite(body, loginProps?.oauth?.userId, loginProps?.oauth?.etablissementId, loginProps?.oauth)
                                }
                                else
                                {
                                    return userService.registerRepresentantsCollectiviteByAdmin(body, loginProps?.oauth?.userId, this.props.userInfo?.Etablissement?.Id, loginProps?.oauth)
                                }
                            }}
                            validateForm={this.isFormValid}
                            onFormNotValid={this.onFormNotValid}
                            onActionSuccess={this.props.onSubmitSuccess}
                            onActionFailure={(response: OnboardingCollectiviteResponse)=>{
                                this.setState({...this.state, mailList: [], errorMessage: response.Error!})
                            }}
                            loaderArea="modal"
                            debounceTimming={isOnboarding ? 1000 : undefined}
                        />
                        {
                            isOnboarding ? 
                                <SimpleButton
                                    label="Passer"
                                    onClick={() => onSkipStep && onSkipStep(FlowStep.COMPLET)}
                                />
                            :
                                <SimpleButton 
                                    className="form-group"
                                    label="Annuler"
                                    onClick={close}
                                />
                        }
                    </div>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedSubscriptionCollectiviteFormRepresentants = connect(mapStateToProps, null)(SubscriptionCollectiviteFormRepresentants);
export { ConnectedSubscriptionCollectiviteFormRepresentants as SubscriptionCollectiviteFormRepresentants };