import React, { useEffect, useMemo, useState } from "react"
import { Form } from "semantic-ui-react"
import { AdminParamsBody } from "../../../model/dto/body/admin-params-body";
import { AdminParams } from "../../../model/entities/admin-params";
import { InputField } from "../../generic/input-field"
import { userService } from "../../../services/authentication-redux-service"
import { useSelector } from "react-redux";
import { AuthenticationState } from "../../../model/state/authentication-state"
import { ApplicationState } from "../../../store"
import { SubmitButton } from "../../generic/submit-button"
import { AdminParamsListResponse } from "../../../model/dto/response";
import { CheckBoxListField } from "../../generic/checkbox-list-field";

type Props = {
    params: AdminParams[],
    onFormSubmit: (data: AdminParams[]) => void
}

type FieldData = {
    value?: any,
    name: string,
    isValid?: boolean,
    key?: string
};
    
type FormData = {
    decalageBatchCloture: FieldData,
    decalageBatchEcheance: FieldData,
    ancienneteHistoEvenement: FieldData,
    decalageBatchCancelProjet: FieldData,
    emailTest: FieldData,
    decalagePostAuto: FieldData,
    isTrueLayerInactive: FieldData,
    delaiExpirationVirement: FieldData,
    montantCBEnAvant: FieldData
    delaiReservationVirementClassique: FieldData
    taillePaquetGenerationContratsFinaux : FieldData
}

export function ParamAdminForm({params, onFormSubmit}: Props) {
    const [form, setForm] = useState<FormData>({
        decalageBatchCloture: {value: params.find(p => p.Key === AdminParams.DECALAGE_BATCH_CLOTURE_KEY)?.Value, name: 'decalageBatchCloture', isValid: true, key: AdminParams.DECALAGE_BATCH_CLOTURE_KEY},
        decalageBatchEcheance: {value: params.find(p => p.Key === AdminParams.DECALAGE_CALCUL_ECHEANCE_KEY)?.Value, name: 'decalageBatchEcheance', isValid: true, key: AdminParams.DECALAGE_CALCUL_ECHEANCE_KEY},
        ancienneteHistoEvenement: {value: params.find(p => p.Key === AdminParams.NB_JOUR_RECHERCHE_EVT_MANGOPAY_KEY)?.Value, name: 'ancienneteHistoEvenement', isValid: true, key: AdminParams.NB_JOUR_RECHERCHE_EVT_MANGOPAY_KEY},
        decalageBatchCancelProjet: {value: params.find(p => p.Key === AdminParams.DECALAGE_BATCH_CANCEL_PROJET_KEY)?.Value, name: 'decalageBatchCancelProjet', isValid: true, key: AdminParams.DECALAGE_BATCH_CANCEL_PROJET_KEY},
        emailTest: {value: params.find(p => p.Key === AdminParams.EMAIL_TEST_KEY)?.Value, name: 'emailTest', isValid: true, key: AdminParams.EMAIL_TEST_KEY},
        decalagePostAuto: {value: params.find(p => p.Key === AdminParams.DECALAGE_POST_AUTO)?.Value, name: 'decalagePostAuto', isValid: true, key: AdminParams.DECALAGE_POST_AUTO},
        isTrueLayerInactive: {value: params.find(p => p.Key === AdminParams.IS_TRUELAYER_ACTIVE)?.Value?.toString(), name: 'isTrueLayerInactive', isValid: true, key: AdminParams.IS_TRUELAYER_ACTIVE},
        delaiExpirationVirement: {value: params.find(p => p.Key === AdminParams.DELAI_VIREMENT_EXPIRED)?.Value?.toString(), name: 'delaiExpirationVirement', isValid: true, key: AdminParams.DELAI_VIREMENT_EXPIRED},
        montantCBEnAvant : {value: params.find(p => p.Key === AdminParams.MONTANT_CB_EN_AVANT)?.Value?.toString(), name: 'montantCBEnAvant', isValid: true, key: AdminParams.MONTANT_CB_EN_AVANT},
        delaiReservationVirementClassique : {value: params.find(p=> p.Key === AdminParams.DELAI_EXPIRATION_RESERVATION_KEY)?.Value?.toString(), name:'delaiReservationVirementClassique', isValid: true, key:AdminParams.DELAI_EXPIRATION_RESERVATION_KEY},
        taillePaquetGenerationContratsFinaux : {value: params.find(p=> p.Key === AdminParams.TAILLE_PAQUET_GENERATION_CONTRATS_FINAUX)?.Value?.toString(), name:'taillePaquetGenerationContratsFinaux', isValid: true, key:AdminParams.TAILLE_PAQUET_GENERATION_CONTRATS_FINAUX} 
    })

    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean, key?: string): void => {
        const fieldValue: string = e.target.value;
        setForm({...form, [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid, key: key}})
    }

    const onFormSubmitSuccess = (response: AdminParamsListResponse) => {
        if(response.IsTraitementOk) {
            onFormSubmit(response.AdminParamsList!)
        }
    }

    const body: AdminParamsBody = {
        AncienneteRechercheEvenementMangoPay: form.ancienneteHistoEvenement.value,
        DecalageBatchCloture: form.decalageBatchCloture.value,
        DecalageBatchEcheance: form.decalageBatchEcheance.value,
        DecalageBatchCancelProjet: form.decalageBatchCancelProjet.value,
        EmailTest: form.emailTest.value,
        DecalagePostAuto: form.decalagePostAuto.value,
        IsTrueLayerInactive: form.isTrueLayerInactive.value === 'true',
        DelaiExpirationVirement: form.delaiExpirationVirement.value,
        MontantCBEnAvant : form.montantCBEnAvant.value,
        DelaiReservationVirementClassique: form.delaiReservationVirementClassique.value,
        TaillePaquetGenerationContratsFinaux : form.taillePaquetGenerationContratsFinaux.value
    }
    
    return (
        <Form>
            <Form.Field width={16}>
                <InputField 
                    data={form.decalageBatchCloture}
                    label="Décalage batch publication/clôture projet (en jours)"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.decalageBatchEcheance}
                    label="Décalage batch résolution échéance (en jours)"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.ancienneteHistoEvenement}
                    label="Ancienneté de la recherche d'évènements MangoPay (en jours)"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.decalageBatchCancelProjet}
                    label="Décalage en jour du batch pour l'annulation des projets en débouclage possible depuis trop longtemps (en jours)"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.emailTest}
                    label="Email de redirection pour les tests"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.decalagePostAuto}
                    label="Décalage post automatique anniversaire projet (en jours)"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.delaiExpirationVirement}
                    label="Délai d'expiration d'un virement (en heures)"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.montantCBEnAvant}
                    label="Montant jusqu'auquel le paiement via CB est mis en avant"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.delaiReservationVirementClassique}
                    label="Délai d'expiration de la réservation d'un virement classique (jours)"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.taillePaquetGenerationContratsFinaux}
                    label="Taille max du paquet de contrats finaux possible de générer"
                    onChange={handleInputChange}
                />
            </Form.Field>
            <Form.Field>
                <CheckBoxListField 
                    data={form.isTrueLayerInactive}
                    label="TrueLayer actif ?"
                    elements={[{ label: 'oui', key: 'true'}, { label: 'non', key: 'false'}]}
                    onChange={(key: string, isChecked?: boolean) => {
                        if(isChecked) {
                            setForm({...form, isTrueLayerInactive: {value: key, name: 'isTrueLayerInactive', isValid: true}})
                        }
                        else {
                            setForm({...form, isTrueLayerInactive: {value: undefined, name: 'isTrueLayerInactive', isValid: false}})
                        }
                    }}
                />
            </Form.Field>
            <SubmitButton 
                data={body}
                label="Valider"
                action={() => userService.registerAllAdminParams(body, loginProps?.oauth?.userId, loginProps?.oauth)}
                onActionSuccess={onFormSubmitSuccess}
            />
        </Form>
    )
}