import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon } from "semantic-ui-react";
import { primaryColorVillyz } from "./pages/styles";


export function UserConnectionUnAuthMobile() {
  return (
    <Dropdown
      className="right"
      icon={null}
      floating
      trigger={
        <Icon 
            name="bars" 
            size="large" 
            style={{
                color: primaryColorVillyz,
            }}  
        />
        }
      direction="left"
      style={{marginRight : "20px"}}
    >
        <Dropdown.Menu>
            <Dropdown.Item 
                as={Link} 
                to="/engagements"
            >
                Notre mission
            </Dropdown.Item>
            <Dropdown.Item 
                as={Link} 
                to="/projets"
            >
                Projets
            </Dropdown.Item>
            <Dropdown.Item 
                as={Link} 
                to="/collectivites"
            >
                Secteur public
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item 
                as={Link} 
                to="/connexion"
            >
             Connexion
            </Dropdown.Item>
            <Dropdown.Item 
                as={Link} 
                to="/inscription"
            >
             Inscription
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
  );
}




