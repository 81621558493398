import React, { useEffect, useMemo } from "react"
import { useState } from "react"
import { Form } from 'semantic-ui-react';
import { SubmitButton } from "../generic/submit-button";
import { userService } from "../../services/authentication-redux-service"
import { RegisterIdentificationCollectiviteBody } from "../../model/dto/body/register-identification-collectivite-body";
import { referenceService } from "../../services/reference-service"
import { ApplicationState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { AuthenticationState } from "../../model/state/authentication-state";
import { UserInfoResponse, OnboardingCollectiviteResponse, DefaultResponse, ProfilCode, CategorieEtablissementListResponse, TypeCollectiviteListResponse, TypeInvestisseurMoralListResponse } from "../../model/dto/response";
import { TypeCollectivite } from "../../model/entities/type-collectivite"
import { CollectiviteSelector } from "../generic/collectivite-selector/collectivite-selector";
import { Collectivite } from "../../model/entities/profil";
import { FormErrorHeader } from "../generic/form-error-header";
import { RegisterIdentificationCollectiviteAdminBody } from "../../model/dto/body/register-identification-collectivite-admin-body";
import { CategorieEtablissement } from "../../model/entities/categorie-etablissement";
import { CheckBoxElement, CheckBoxListField } from "../generic/checkbox-list-field";
import { InputField } from "../generic/input-field";
import { AuthenticationAction, CHANGE_IDENTITY } from "../../actions/authentication-actions";
import { WorkflowType } from "../../model/entities/workflow-type";
import { siretRegex } from "../../helpers/regex-helper";

type FieldData = {
    value?: any,
    name?: string,
    error?: string,
    isValid?: boolean
};
    
type Form = {
    selectedTypeCollectivite : FieldData,
    selectedCategorieEtablissement: FieldData,
    communeCode?: FieldData,
    epciCode?: FieldData,
    departementCode?: FieldData,
    regionCode?: FieldData,
    siretCode: FieldData,
    nomSociete: FieldData,
    chuCode?: FieldData,  
}

interface ListItem {
    key?: string,
    value?: any,
    text?: string
}

interface Props {
    isOnboarding?: boolean,
    userInfo?: UserInfoResponse,
    onSubmitSuccess: (response: Response) => void
}

export function SubscriptionCollectiviteFormIdentification({
    isOnboarding,
    userInfo,
    onSubmitSuccess
}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const dispatch = useDispatch()

    const [form, setForm] = useState<Form>({
        selectedTypeCollectivite: {value: undefined, name: "selectedTypeCollectivite" },
        selectedCategorieEtablissement: {value: undefined, name: "selectedCategorieEtablissement" },
        communeCode: {value: undefined, name: "communeCode" },
        departementCode: {value: undefined, name: "departementCode" },
        regionCode: {value: undefined, name: "regionCode" },
        epciCode: {value: undefined, name: "epciCode" },
        siretCode: {value: undefined, name: "siretCode" },
        chuCode: {value: undefined, name: "chuCode" },
        nomSociete: {value: undefined, name: "nomSociete" }
    })
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [isFormError, setIsFormError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [categorieEtablissementList, setCategorieEtablissementList] = useState<CategorieEtablissement[]>([])
    const [typeCollectiviteList, setTypeCollectiviteList] = useState<TypeCollectivite[]>([])
    const [siretErrorMessage, setSiretErrorMessage] = useState<string>("")

    useEffect(() => {
        referenceService.getAllCategorieEtablissement()
        .then((response: CategorieEtablissementListResponse) => {
            setCategorieEtablissementList(response.CategorieEtablissementList!)
        })

        referenceService.getAllTypeCollectivite()
        .then((response: TypeCollectiviteListResponse) => {
            setTypeCollectiviteList(response.TypeCollectiviteList!)
        })
    }, [])

    const validateMandatory = (value: any) => {
        return value && value !== "" 
    }

    const emptyFieldErrorMessage = "Veuillez renseigner ce champ"
    const siretFieldWrongCaracterMessage = "Veuillez renseigner une suite de 9 ou 14 chiffres sans espace ou autre caractère"
    const siretFieldLengthErrorMessage = "Veuillez renseigner une suite de 9 ou 14 chiffres"

    const validateSiret = (value: string): boolean => {
        if(!value || (value && value === "")){
            setSiretErrorMessage(emptyFieldErrorMessage)
            return false
        }

        const resultRegex = siretRegex.test(value)
        if(resultRegex){
            if(value.length === 9 ||  value.length === 14){
                setSiretErrorMessage("")
                return true
            } 
            else {
                setSiretErrorMessage(siretFieldLengthErrorMessage)
                return false
            }
        }else {
            setSiretErrorMessage(siretFieldWrongCaracterMessage)
            return false
        }
    }

    const isFormValid = () => {
        const {selectedTypeCollectivite, selectedCategorieEtablissement, communeCode, departementCode, epciCode, regionCode, chuCode, siretCode, nomSociete } = form;

        if (selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && selectedTypeCollectivite.value === TypeCollectivite.COMMUNE && communeCode ){
            if (!validateMandatory(communeCode.value)) {
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner une commune")
            }
            return validateMandatory(communeCode.value)
        }
        
        if (selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && selectedTypeCollectivite.value === TypeCollectivite.DEPARTEMENT && departementCode){
            if (!validateMandatory(departementCode.value)) {
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner un département")
            }
            return validateMandatory(departementCode.value)
        }

        if (selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && selectedTypeCollectivite.value === TypeCollectivite.EPCI && epciCode){
            if (!validateMandatory(epciCode.value)) {
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner une EPCI")
            }
            return validateMandatory(epciCode.value)
        }

        if (selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && selectedTypeCollectivite.value === TypeCollectivite.REGION && regionCode){
            if (!validateMandatory(regionCode.value)) {
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner une région")
            }
            return validateMandatory(regionCode.value)
        }

        if (selectedCategorieEtablissement.value === CategorieEtablissement.CHU && chuCode){
            if (!validateMandatory(chuCode.value)){
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner un CHU")
            }
            return validateMandatory(chuCode.value)
        }

        if (selectedCategorieEtablissement.value === CategorieEtablissement.SOCIETE && siretCode){
            if (!validateMandatory(siretCode.value) || !validateMandatory(nomSociete.value)){
                setIsFormError(true)
                setErrorMessage("Veuillez renseigner les informations de votre société")
            }
            return validateSiret(siretCode.value) && validateMandatory(nomSociete.value)
        }

        setIsFormError(true)
        setErrorMessage("Veuillez renseigner vos informations")
        return false
    }

    const onFormNotValid = () => {
        
    }

    const onSubmitError = (response: DefaultResponse) => {
        if(!response.IsTraitementOk && response.Error) {
            setIsFormError(true)
            setErrorMessage(response.Error)
        }
    }

    const onSubmitIdentiteSuccess = (response: OnboardingCollectiviteResponse) => {
        if(response.UserInfo) {
            const action: AuthenticationAction = { type: CHANGE_IDENTITY, user: response.UserInfo }
            dispatch(action)
        }
        onSubmitSuccess(response)
    }

    const onCollectiviteChange = (value: any, field: string) => {
        setIsFormError(false)
        setForm({...form, [field]: {value: value, name: field}})
    }

    const isRefListDisplayedOrSiret = () => {
        const { selectedCategorieEtablissement } = form
        return (
            selectedCategorieEtablissement.value === CategorieEtablissement.SOCIETE
        )
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string, isValid?: boolean, key?: string) => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: {value: fieldValue, name: fieldName, isValid: isValid, key: key} };
      
        setIsFormError(false)
        setForm({...form, ...newField})
    }

    const onPreSubmit = () => {
        setSubmitted(true)
    }

    const renderCollectiviteCode = () => {
        const {selectedTypeCollectivite, selectedCategorieEtablissement, chuCode, communeCode, departementCode, epciCode, regionCode} = form
        if(selectedCategorieEtablissement?.value === CategorieEtablissement.CHU) {
            return chuCode?.value;
        }

        if(selectedCategorieEtablissement?.value === CategorieEtablissement.COLLECTIVITE && selectedTypeCollectivite?.value === TypeCollectivite.COMMUNE) {
            return communeCode?.value;
        }

        if(selectedCategorieEtablissement?.value === CategorieEtablissement.COLLECTIVITE && selectedTypeCollectivite?.value === TypeCollectivite.DEPARTEMENT) {
            return departementCode?.value;
        }

        if(selectedCategorieEtablissement?.value === CategorieEtablissement.COLLECTIVITE && selectedTypeCollectivite?.value === TypeCollectivite.EPCI) {
            return epciCode?.value;
        }

        if(selectedCategorieEtablissement?.value === CategorieEtablissement.COLLECTIVITE && selectedTypeCollectivite?.value === TypeCollectivite.REGION) {
            return regionCode?.value;
        }
        
        return undefined
    }

    const collectiviteCode = useMemo(() => {
        return renderCollectiviteCode()
    }, [form]);

    const body: RegisterIdentificationCollectiviteBody = {
        CodeCollectivite: collectiviteCode,
        NomSociete: form.nomSociete.value,
        SiretCode: form.siretCode?.value,
        IsSociete: form.selectedCategorieEtablissement.value === CategorieEtablissement.SOCIETE,
        IsCHU: form.selectedCategorieEtablissement.value === CategorieEtablissement.CHU,
        IsCommune: form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.COMMUNE,
        IsDepartement: form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.DEPARTEMENT,
        IsRegion: form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.REGION,
        IsEPCI: form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.EPCI,
        WorkflowTypeCode: WorkflowType.ONBOARDING_PORTEUR_ORGANISATION 
    }
    
    const bodyAdmin: RegisterIdentificationCollectiviteAdminBody = {
        UserId: userInfo?.UserId,
        CodeCollectivite: collectiviteCode,
        NomSociete: form.nomSociete.value,
        SiretCode: form.siretCode?.value,
        IsSociete: form.selectedCategorieEtablissement.value === CategorieEtablissement.SOCIETE,
        IsCHU: form.selectedCategorieEtablissement.value === CategorieEtablissement.CHU,
        IsCommune: form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.COMMUNE,
        IsDepartement: form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.DEPARTEMENT,
        IsRegion: form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.REGION,
        IsEPCI: form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.EPCI,
    }

    return (
        <div>
            <div className="form-group">
                <FormErrorHeader
                    message={errorMessage}
                    displayed={isFormError}
                />
                {!isOnboarding && <h1>Identité</h1>}
                <div css={{
                    padding: "0px 20px 20px 20px",
                    color: "gray"
                }}>
                    Veuillez sélectionner l'établissement que vous représentez, cet établissement sera associé à votre adresse
                    mail et vous ne pourrez plus le modifier par la suite.
                </div>
                <div className="form-group">
                    Je représente un(e): *
                </div>
                <CheckBoxListField
                    data={form.selectedCategorieEtablissement}
                    elements={categorieEtablissementList ? categorieEtablissementList.map(element => {
                        return {
                            key: element.Code,
                            label: element.Libelle
                        } as CheckBoxElement
                    }) : []}
                    label=""
                    vertical
                    onChange={(newKey: any, isValid?: boolean) => {
                        setForm({...form, selectedCategorieEtablissement: {value:newKey, name: "selectedCategorieEtablissement"}})
                    }}
                />

                {form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE &&
                <>
                    <div className="form-group">
                        Type de collectivité*
                    </div>
                    <CheckBoxListField 
                        data={form.selectedTypeCollectivite}
                        elements={typeCollectiviteList ? typeCollectiviteList.map(element => {
                            return {
                                key: element.Code,
                                label: element.Designation
                            } as CheckBoxElement
                        }) : []}
                        label=""
                        vertical
                        onChange={(newKey: any, isValid?: boolean) => {
                            setForm({...form, selectedTypeCollectivite: {value:newKey, name: "selectedTypeCollectivite"}})
                        }}
                    />
                </>
                }
            </div>
            
            {form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.COMMUNE && (
                <CollectiviteSelector
                    type={Collectivite.COMMUNE}
                    useCodeUnique={false}
                    onChange={(value: any) => {
                        onCollectiviteChange(value, "communeCode");
                    }}
                />
            )}

            {form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.EPCI && (
                <CollectiviteSelector
                    type={Collectivite.EPCI}
                    useCodeUnique={false}
                    onChange={(value: any) => {
                        onCollectiviteChange(value, "epciCode");
                    }}
            />
            )}

            {form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.DEPARTEMENT && (
                <CollectiviteSelector
                    type={Collectivite.DEPARTEMENT}
                    useCodeUnique={false}
                    onChange={(value: any) => {
                        onCollectiviteChange(value, "departementCode");
                    }}
                />
            )}

            {form.selectedCategorieEtablissement.value === CategorieEtablissement.COLLECTIVITE && form.selectedTypeCollectivite.value === TypeCollectivite.REGION && (
                <CollectiviteSelector
                    type={Collectivite.REGION}
                    useCodeUnique={false}
                    onChange={(value: any) => {
                        onCollectiviteChange(value, "regionCode");
                    }} 
                />
            )} 

            {form.selectedCategorieEtablissement.value === CategorieEtablissement.CHU && (
                <CollectiviteSelector
                    type={Collectivite.CHU}
                    useCodeUnique={false}
                    onChange={(value: any) => {
                        onCollectiviteChange(value, "chuCode");
                    }}   
                />
            )}   

            {isRefListDisplayedOrSiret() && (
                <Form>
                    <Form.Field>
                        <InputField 
                            data={form.siretCode}
                            label="Numéro SIREN ou SIRET*"
                            type="tel"
                            error={siretErrorMessage}
                            submitted={submitted}
                            onChange={handleInputChange}
                            validateField={validateSiret}
                        />
                    </Form.Field>
                    <Form.Field>
                        <InputField 
                            data={form.nomSociete}
                            label="Nom de la société*"
                            error="Veuillez renseigner ce champ"
                            submitted={submitted}
                            onChange={handleInputChange}
                            validateField={validateMandatory}
                        />
                    </Form.Field>
                </Form>
            )}
            <div css={{paddingBottom : "10px"}}>*champ obligatoire</div>
            <div css={{ marginTop: "20px" }}>
                <div className="button-bar">
                    <SubmitButton 
                        data={body}
                        label="Valider"
                        action={() => {
                            if (loginProps?.oauth?.profilCode === ProfilCode.PORTEUR_PROJET)
                            {
                                return userService.registerIdentificationCollectivite(body, loginProps?.oauth?.userId, loginProps?.oauth)
                            }
                            else
                            {
                                return userService.registerIdentificationCollectiviteByAdmin(bodyAdmin, loginProps?.oauth?.userId, userInfo?.Etablissement?.Id, loginProps?.oauth)
                            }
                        }}
                        onPreSubmit={onPreSubmit}
                        validateForm={isFormValid}
                        onFormNotValid={onFormNotValid}
                        onActionSuccess={onSubmitIdentiteSuccess}
                        onActionFailure={onSubmitError}
                        loaderArea="modal"
                        debounceTimming={isOnboarding ? 1000 : undefined}
                    />
                </div>
            </div>
        </div>
    )
}
