export interface SubFlowStep{
    Code : string,
    Libelle : string
}

const OBJECTIFS_INVESTISSEMENT: string = "OIN";
const EXPERIENCE_INVESTISSEMENT: string = "EIN";
const CONNAISSANCE_RISQUES:string = "CRS";
const ADRESSE_ETABLISSEMENT: string = "ADE";
const ADRESSE_REPRESENTANT_LEGAL: string = "ADR";
const CARACTERISTIQUES_FINANCEMENT_DON: string = "CFD";
const CARACTERISTIQUES_FINANCEMENT_EMPRUNT: string = "CFE";
const OBJECTIFS_PROJET_EMPRUNT: string = "OPE";
const OBJECTIFS_PROJET_DON: string = "OPD";

export const SubFlowStep = {
    OBJECTIFS_INVESTISSEMENT,
    EXPERIENCE_INVESTISSEMENT,
    CONNAISSANCE_RISQUES,
    ADRESSE_ETABLISSEMENT,
    ADRESSE_REPRESENTANT_LEGAL,
    CARACTERISTIQUES_FINANCEMENT_EMPRUNT,
    CARACTERISTIQUES_FINANCEMENT_DON,
    OBJECTIFS_PROJET_DON,
    OBJECTIFS_PROJET_EMPRUNT
}