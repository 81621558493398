export interface AdminParams {
    Key?: string,
    Libelle?: string,
    Value?: string,
    TestOnly?: boolean
}

const DECALAGE_BATCH_CLOTURE_KEY = 'DPC'
const DECALAGE_CALCUL_ECHEANCE_KEY = 'DDE'
const NB_JOUR_RECHERCHE_EVT_MANGOPAY_KEY = 'NJR'
const DECALAGE_BATCH_CANCEL_PROJET_KEY = 'BCP'
const EMAIL_TEST_KEY = 'EMT'
const DECALAGE_POST_AUTO = 'DPA'
const NB_JOUR_TRAITEMENT_EVT_TRUELAYER = "NJT";
const IS_TRUELAYER_ACTIVE = "TLD";
const DELAI_VIREMENT_EXPIRED = "DVE"
const MONTANT_CB_EN_AVANT = "MCB";
const DELAI_EXPIRATION_RESERVATION_KEY = "DER"
const TAILLE_PAQUET_GENERATION_CONTRATS_FINAUX = "TPG";

export const AdminParams = {
    DECALAGE_BATCH_CLOTURE_KEY,
    DECALAGE_CALCUL_ECHEANCE_KEY,
    NB_JOUR_RECHERCHE_EVT_MANGOPAY_KEY,
    DECALAGE_BATCH_CANCEL_PROJET_KEY,
    EMAIL_TEST_KEY,
    DECALAGE_POST_AUTO,
    NB_JOUR_TRAITEMENT_EVT_TRUELAYER,
    IS_TRUELAYER_ACTIVE,
    DELAI_VIREMENT_EXPIRED,
    MONTANT_CB_EN_AVANT,
    DELAI_EXPIRATION_RESERVATION_KEY,
    TAILLE_PAQUET_GENERATION_CONTRATS_FINAUX 
}