import { css } from '@emotion/core'
import React from 'react'
import { Container, Grid, Image } from 'semantic-ui-react'
import './testimony.css'

type Props = {
    text?: string,
    autor?: string,
    imageUrl?: string,
    imageAlt?: string,
    morePadding?: boolean
}

export default function Testimony({text, imageAlt, imageUrl, autor, morePadding}: Props) {
      
  return (
        <Container>
          <div 
          className='testimony-container'
          >
            <Grid doubling stackable>
              <Grid.Row>
                <Grid.Column width={3}>
                  <Image
                    src={imageUrl}
                    className='testimony-image'
                    alt={imageAlt}
                  />
                </Grid.Column>
                <Grid.Column width={13}>
                  <p style={{fontSize: "20px", fontWeight: "bold"}}>{autor}</p>
                  <p className={morePadding ?'testimony-comment-paragraph-more-padding' : 'testimony-comment-paragraph'}>
                    <span className='testimon-quote-mark'>&ldquo;</span>
                    {text}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Container>
  )
}
