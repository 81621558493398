import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { EtatCivilRegistrationBody } from "../../model/dto/body/etat-civil-registration-body";
import { userService } from "../../services/authentication-redux-service";
import { SubmitButton } from "../generic/submit-button";
import { ApplicationState } from "../../store";
import { SimpleButton } from "../generic/simple-button";
import { Form } from "semantic-ui-react";
import {
    OnboardingCitoyenResponse,
    ProfilCode,
    UserInfoResponse,
    WorkflowProcessResultResponse,
} from "../../model/dto/response";
import { InputField } from "../generic/input-field";
import { FormErrorHeader } from "../generic/form-error-header";
import { DropdownListFieldNew } from "../generic/dropdown-list-field";
import { CheckBoxSimpleField } from "../generic/checkbox-simple-field/checkbox-simple-field";
import { Pays } from "../../model/entities/pays";
import { referenceService } from "../../services/reference-service";
import { CollectiviteSelector } from "../generic/collectivite-selector/collectivite-selector";
import { Collectivite } from "../../model/entities/profil";
import { EtatCivilRegistrationAdminBody } from "../../model/dto/body/etat-civile-registration-admin-body";
import {
    AuthenticationAction,
    CHANGE_IDENTITY,
} from "../../actions/authentication-actions";
import { AuthenticationState } from "../../model/state/authentication-state";
import DateMaskFieldNew from "../generic/date-mask-field";
import { convertDateToMaskPicker, isLegalAgeDate } from "../../helpers/date-helper";
import InformationBandeau from "../generic/information-bandeau/information-bandeau";
import { RadioElement, RadioListField } from "../generic/radio-list-field";
import { WorkflowType } from "../../model/entities/workflow-type";
import { FlowStep } from "../../model/entities/flow-step";


type FieldData = {
    value?: any;
    name?: string;
    isValid?: boolean;
};

export type EtatCivilForm = {
    lastName: FieldData;
    firstName: FieldData;
    birthDate: FieldData;
    fiscLocal: FieldData;
    nationalite: FieldData;
    civilite: FieldData;
    communeNaissanceId: FieldData;
    paysNaissanceCode: FieldData;
    nomDeCommuneNaissanceEtrangere: FieldData;
};

interface SubscriptionFormEtatCivilProps {
    loginProps?: AuthenticationState;
    userInfo?: UserInfoResponse;
    isOnboarding?: boolean;
    userId?: number;
    onSubmitSuccess: (response: Response) => void;
    onSkipStep?: (response: WorkflowProcessResultResponse) => void;
    changeIdentity?: (userInfo: UserInfoResponse) => void;
}

function SubscriptionFormEtatCivilNew({
    loginProps,
    userInfo,
    isOnboarding,
    userId,
    onSubmitSuccess,
    onSkipStep,
    changeIdentity,
}: SubscriptionFormEtatCivilProps) { 
    const [isDateError, setIsDateError] = useState<boolean>(false)
    const [dateErrorMessage, setDateErrorMessage] = useState<string>('')

    const investisseurId = loginProps?.oauth?.userId

    const validateMandatory = (value: any): boolean => {
        return value && value !== "";
    };

    const validatePhone = (value: string): boolean => {
        const regex = /^[0-9]{10}$/g;
        return regex.test(value);
    };

    const validateLieuNaissance = (value: any) => {
        if (form.paysNaissanceCode.value === "FR") {
            return validateMandatory(value);
        }
        return true;
    };

    const validateLieuNaissanceEtranger = (value: any) => {
        if (form.paysNaissanceCode.value !== "FR") {
            return validateMandatory(value);
        }
        return true;
    };

    const [form, setForm] = useState<EtatCivilForm>({
        lastName: {
            value: userInfo?.Nom || "",
            name: "lastName",
            isValid: validateMandatory(userInfo?.Nom),
        },
        firstName: {
            value: userInfo?.Prenom || "",
            name: "firstName",
            isValid: validateMandatory(userInfo?.Prenom),
        },
        birthDate: {
            value: userInfo?.DateNaissance || "",
            name: "birthDate",
            isValid: validateMandatory(userInfo?.DateNaissance),
        },
        fiscLocal: {
            value: userInfo?.IsResidentFrancais || "",
            name: "fiscLocal",
            isValid: validateMandatory(userInfo?.IsResidentFrancais),
        },
        nationalite: {
            value: userInfo?.Nationalite || "FR",
            name: "nationalite",
            isValid: true,
        },
        civilite: {
            value: userInfo?.Civilite || "",
            name: "civilite",
            isValid: validateMandatory(userInfo?.Civilite),
        },
        communeNaissanceId: {
            value: userInfo?.CommuneNaissanceId || "",
            name: "communeNaissanceId",
            isValid: validateMandatory(userInfo?.CommuneNaissanceId),
        },
        paysNaissanceCode: {
            value: userInfo?.PaysNaissanceCode || "FR",
            name: "paysNaissanceCode",
            isValid: validateMandatory(userInfo?.PaysNaissanceCode),
        },
        nomDeCommuneNaissanceEtrangere: {
            value: userInfo?.NomDeCommuneEtrangere || "",
            name: "nomDeCommuneNaissanceEtrangere",
            isValid: validateMandatory(userInfo?.NomDeCommuneEtrangere),
        },
    });

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isFormError, setIsFormError] = useState(false);
    const [paysList, setPaysList] = useState<Pays[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        referenceService.getAllPays().then((response) => {
            if (response.IsTraitementOk) {
                setPaysList(response.PaysList!);
            }
        });
    }, []);

    const handleInputChangeNew = (
        e: React.ChangeEvent<HTMLInputElement>,
        fieldName?: string,
        isValid?: boolean
    ): void => {
        const fieldValue: string = e.target.value;

        if (fieldName) {
            const newField: FieldData = {
                [fieldName]: {
                    value: fieldValue,
                    name: fieldName,
                    isValid: isValid,
                },
            };
            setForm({ ...form, ...newField });
            setIsFormError(false);
        }
    };

    const handleDateChange = (
        date: Date | null,
        field: string,
        isValid?: boolean
    ): void => {
        const fieldName: string = field;
        const fieldValue: Date | null = date;
        const newField: FieldData = {
            [fieldName]: { value: fieldValue, isValid: isValid },
        };
        setForm({ ...form, ...newField });
        setIsFormError(false);
        setIsDateError(false);
    };

    const wrongDateMessage = "Veuillez saisir une date valide"
    const legalAgeDateMessage = "Conformément aux CGU vous devez avoir au moins 18 ans pour utiliser les services de la plateforme"

    const validateDateNaissance = (date: Date| string) => {
        if(!date){
            setIsDateError(true)
            setDateErrorMessage(wrongDateMessage)
            return false
        } else {
            if(typeof(date) === 'string'){
                return date = new Date(date)
            }
            if(!isLegalAgeDate(date)) {
                setIsDateError(true)
                setDateErrorMessage(legalAgeDateMessage)
                return false
            }
            setIsDateError(false)
            return true 
        }
    }

    const isFormValid = (): boolean => {
        return (
            validateDateNaissance(form.birthDate.value) &&
            validateMandatory(form.firstName.value) &&
            validateMandatory(form.fiscLocal.value) &&
            validateMandatory(form.lastName.value) &&
            validateLieuNaissance(form.communeNaissanceId.value) &&
            validateMandatory(form.paysNaissanceCode.value) &&
            validateMandatory(form.civilite.value) &&
            validateLieuNaissanceEtranger(
                form.nomDeCommuneNaissanceEtrangere.value
            )
        );
    };

    const onFormNotValid = (): void => {
        setIsFormError(true);
    };

    const preSubmit = () => {
        setFormSubmitted(true);
    };

    const onSubmitError = (response: Response): void => {
        console.log("Une erreur est survenue");
    };

    const onFormSubmitSuccess = (
        response: OnboardingCitoyenResponse
    ): void => {
        if (changeIdentity) {
            const action: AuthenticationAction = {
                type: CHANGE_IDENTITY,
                user: response.UserInfo!,
            };
            localStorage.setItem(
                "user_info",
                JSON.stringify(response.UserInfo)
            );
            dispatch(action);
            changeIdentity(response.UserInfo!);
        }

        onSubmitSuccess(response);
    };

    const body: EtatCivilRegistrationBody = {
        BirthDate: form.birthDate.value,
        FirstName: form.firstName.value,
        LastName: form.lastName.value,
        FiscLocal: form.fiscLocal.value,
        Nationalite: form.nationalite.value,
        Civilite: form.civilite.value,
        CommuneNaissanceId: form.communeNaissanceId.value,
        PaysNaissanceCode: form.paysNaissanceCode.value,
        NomDeCommuneNaissanceEtrangere: form.nomDeCommuneNaissanceEtrangere.value,
        WorkflowTypeCode: WorkflowType.ONBOARDING_CITOYEN      
    };

    const bodyAdmin: EtatCivilRegistrationAdminBody = {
        UserId: userId,
        BirthDate: form.birthDate.value,
        FirstName: form.firstName.value,
        LastName: form.lastName.value,
        FiscLocal: form.fiscLocal.value,
        Nationalite: form.nationalite.value,
        Civilite: form.civilite.value,
        CommuneNaissanceId: form.communeNaissanceId.value,
        PaysNaissanceCode: form.paysNaissanceCode.value,
        NomDeCommuneNaissanceEtrangere: form.nomDeCommuneNaissanceEtrangere.value,
    };

    const nextEtatCivilStepKey = () => {
            const body = {
                WorkflowTypeCode : WorkflowType.ONBOARDING_CITOYEN,
                CurrentFlowStep : FlowStep.ETAT_CIVIL,
                RessourceId:  loginProps?.user?.UserId!,
                IsSkiped: true
            }
            return referenceService.getNextStep(body).then(response => {
                onSkipStep && onSkipStep(response)})
    }

    return (
        <div>
            <Form  autocomplete="off">
                <FormErrorHeader
                    message="Veuillez corriger les champs en erreur"
                    displayed={isFormError}
                />
                <InformationBandeau 
                    type="information"
                    icon={{name:'info circle', size:"big", color:"blue"}}
                    message="Votre identité est cruciale pour garantir la sécurité de vos investissements. Ces informations ne sont pas partagées avec des annonceurs."
                />
                <Form.Group>
                    <Form.Field>
                        <RadioListField
                            label="Civilité*"
                            elements={[
                                { label: "Madame", key: "Madame" },
                                { label: "Monsieur", key: "Monsieur" },
                            ]}
                            data={form.civilite}
                            onChange={(value: string) => {
                                setForm({
                                  ...form,
                                  civilite: { name: "civilite", value, isValid: true },
                                });
                              }}
                            validateField={validateMandatory}
                            submitted={formSubmitted}
                            error="Merci d'indiquer votre civilité"
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={8}>
                        <InputField
                            data={form.lastName}
                            label="Nom*"
                            autocomplete="family-name"
                            submitted={formSubmitted}
                            onChange={handleInputChangeNew}
                            error="Merci de renseigner votre nom"
                            validateField={validateMandatory}
                        />
                    </Form.Field>
                    <Form.Field width={8}>
                        <InputField
                            data={form.firstName}
                            label="Prénom*"
                            submitted={formSubmitted}
                            onChange={handleInputChangeNew}
                            error="Merci de renseigner votre prénom"
                            validateField={validateMandatory}
                            autocomplete="given-name"
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={8}>
                        <DateMaskFieldNew
                            data={form.birthDate}
                            label="Date de naissance*"
                            className="field"
                            autoComplete="bday"
                            submitted={formSubmitted}
                            onChange={(
                                date: Date | null,
                                isValid?: boolean
                            ) => {
                                handleDateChange(date, "birthDate", isValid);
                            }}
                            error={dateErrorMessage}
                            onError={isDateError}
                            validateField={validateMandatory}
                            formatModel="# # / # # / # # # #"
                            mask="_"
                            type="tel"
                            allowEmptyFormatting
                            value={userInfo ? userInfo.DateNaissance && convertDateToMaskPicker(userInfo.DateNaissance.toString()) : ''}
                        />
                    </Form.Field>
                    <Form.Field width={8}>
                        <DropdownListFieldNew
                            label="Nationalité*"
                            field={form.nationalite}
                            placeholder="Nationalité"
                            error="Merci de renseigner votre nationalité"
                            datasource={paysList.map((c) => ({
                                text: c.Nom!,
                                value: c.Code!,
                            }))}
                            onChange={(value) =>
                                handleInputChangeNew(
                                    { target: { value } } as any,
                                    form.nationalite.name
                                )
                            }
                            autocomplete=""
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={8}>
                        <DropdownListFieldNew
                            label="Pays de naissance*"
                            field={form.paysNaissanceCode}
                            placeholder="Pays de naissance"
                            error="Merci de renseigner votre pays de naissance"
                            datasource={paysList.map((c) => ({
                                text: c.Nom!,
                                value: c.Code!,
                            }))}
                            onChange={(value) =>
                                handleInputChangeNew(
                                    { target: { value } } as any,
                                    form.paysNaissanceCode.name
                                )
                            }
                        />
                    </Form.Field>
                    <Form.Field width={8}>
                        {form.paysNaissanceCode.value === "FR" ? (
                            <CollectiviteSelector
                                type={Collectivite.COMMUNE}
                                label="Ville de naissance*"
                                autocomplete="nope"
                                useCodeUnique={false}
                                submitted={formSubmitted}
                                initialCode={form.communeNaissanceId.value}
                                error="Merci de renseigner votre ville de naissance"
                                onChange={(value: any, isValid: boolean) => {
                                    setForm({
                                        ...form,
                                        communeNaissanceId: {
                                            value: value,
                                            name: "communeId",
                                            isValid: isValid,
                                        },
                                    });
                                    setIsFormError(false);
                                }}
                                validateField={validateLieuNaissance}
                            />
                        ) : (
                            <InputField
                                data={form.nomDeCommuneNaissanceEtrangere}
                                label="Nom de votre commune de naissance*"
                                submitted={formSubmitted}
                                onChange={handleInputChangeNew}
                                error="Merci de renseigner le nom de votre commune de naissance"
                                validateField={validateLieuNaissanceEtranger}
                            />
                        )}
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field width={8}>
                        <CheckBoxSimpleField
                            data={form.fiscLocal}
                            label="Je suis domicilié en France et résident fiscal français*"
                            error="Merci de confirmer cette mention"
                            submitted={formSubmitted}
                            onChange={(value: any, isValid: boolean) => {
                                handleInputChangeNew(
                                    { target: { value } } as any,
                                    form.fiscLocal.name,
                                    isValid
                                );
                            }}
                            validateField={validateMandatory}
                        />
                    </Form.Field>
                </Form.Group>
                <div css={{ paddingBottom: "10px" }}>*champ obligatoire</div>
            </Form>
            <div className="button-bar">
                <SubmitButton
                    label="Valider"
                    onPreSubmit={preSubmit}
                    action={() => {
                        if (
                            loginProps?.oauth?.profilCode ===
                            ProfilCode.INVESTISSEUR
                        ) {
                            return userService.registerEtatCivil(
                                body,
                                loginProps?.oauth?.userId,
                                loginProps?.oauth
                            );
                        } else {
                            return userService.registerEtatCivilByAdmin(
                                bodyAdmin,
                                loginProps?.oauth?.userId,
                                loginProps?.oauth
                            );
                        }
                    }}
                    validateForm={isFormValid}
                    onFormNotValid={onFormNotValid}
                    onActionSuccess={onFormSubmitSuccess}
                    onActionFailure={onSubmitError}
                    debounceTimming={isOnboarding ? 1000 : undefined}
                />
                {isOnboarding && (
                    <SimpleButton
                        label="Passer"
                        onClick={() => nextEtatCivilStepKey()}
                    />
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication,
});

const mapDispatchToAction = {
    changeIdentity: (userInfo: UserInfoResponse) => ({
        type: "CHANGE_IDENTITY",
        user: userInfo,
    }),
};

const ConnectedSubscriptionFormEtatCivilNew = connect(
    mapStateToProps,
    mapDispatchToAction
)(SubscriptionFormEtatCivilNew);
export { ConnectedSubscriptionFormEtatCivilNew as SubscriptionFormEtatCivilNew };
