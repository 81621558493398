import React, { useMemo, useState } from "react"
import { Card, CardContent, Icon } from "semantic-ui-react";
import { LightEditableBlock } from "../../../../generic/editable-block/light-editable-block";
import Projet from "../../../../../model/projet";
import { StatutProjet } from "../../../../../model/entities/statut-projet";
import { addDays, differenceInDays, format, parseISO } from "date-fns";
import FinancementDons from "../../../forms/financement-dons";
import { AuthenticationState } from "../../../../../model/state/authentication-state";
import { ApplicationState } from "../../../../../store";
import { useSelector } from "react-redux";
import { ProfilCode, ProjetDetailResponse } from "../../../../../model/dto/response";
import PeriodeDonForm from "../../../forms/periode-don-form";
import { ProjetStatut } from "../../../projet-statut";
import { InformationsDon } from "../information-don/informations-don";
import './summary-block-don.css'
import { SubFlowStep } from "../../../../../model/entities/sub-flow-step";
import ObjectifsForm from "../../../forms/objectifs-form";
import ProjetObjectifs from "../../../../../model/entities/projet-objectifs";

type Props = {
    project: Projet,
    categorieEtablissementCode?: string,
    categorieEtablissementLibelle?: string,
    nonEditListCode: string[];
    nombreDonateur: number;
    objectifs?: ProjetObjectifs[];
    onPublishSuccess: (response: Response) => void;
    reload: () => void;
}

export function SummaryBlockDon({project, categorieEtablissementCode, categorieEtablissementLibelle, nonEditListCode, nombreDonateur, objectifs, reload, onPublishSuccess}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const [subFlowStep, setSubFlowStep] = useState(SubFlowStep.CARACTERISTIQUES_FINANCEMENT_DON)
    
    const daysUntilEnd = useMemo(() => {
        if (
          !project?.DateDebutCollecteDon ||
          !project?.DureeCollecteDon
        ) {
          return "-";
        }
    
        const startDate = parseISO(project.DateDebutCollecteDon!);
        
        const endDate = addDays(startDate, project.DureeCollecteDon!+1);
        return new Intl.NumberFormat("fr-FR", {
          style: "decimal",
        }).format(differenceInDays(new Date(), endDate));
      }, [project]);


    const endDate = useMemo(() => {
        if(project.DateClotureDon) {
          return parseISO(project.DateClotureDon)
        }
    
        if (
          !project?.DateDebutCollecteDon ||
          !project?.DureeCollecteDon
        ) {
          return undefined;
        }
        const startDate = parseISO( project.DateDebutCollecteDon); 
        return addDays(startDate, project.DureeCollecteDon);
      }, [project]);

      
    return (
    <Card fluid>
      <CardContent className="summary-don-header-block">
            <LightEditableBlock
                title="Période de collecte"
                editable={(loginProps.oauth?.profilCode === ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutDonProjetCode!)) || loginProps.oauth?.profilCode === ProfilCode.VILLYZ)}
                    editor={(close) => (
                    <PeriodeDonForm
                        projet={project}
                        onSubmitSuccess={() => {
                            close();
                            reload();
                        }}
                    />
                )}
                iconCss={{
                  position: "absolute",
                  right:"10px",
                  top:"10px"
                }}
                > 
                {(project.StatutDonProjetCode === StatutProjet.COMPLET || 
                project.StatutDonProjetCode === StatutProjet.INCOMPLET || 
                project.StatutDonProjetCode === StatutProjet.EN_TRAITEMENT || 
                project.StatutDonProjetCode === StatutProjet.INFORMATION_MANQUANTE || 
                project.StatutDonProjetCode === StatutProjet.A_VENIR) && (
                    <div className="summary-don-header-date-block">
                        <span className="summary-don-header-span-day">
                            <Icon name="clock" size="large" /> J{daysUntilEnd}
                        </span>
                        {endDate && (
                            <span className="summary-header-span-end-date">
                            Termine le {format(endDate, "dd/MM/yy")}
                            </span>
                        )}
                    </div>
                )}
                <span className="summary-don-header-text-area">
                    <ProjetStatut 
                        daysUntilEnd={daysUntilEnd} 
                        statut={project.StatutDonProjetCode!} 
                        categorieEtablissementCode={categorieEtablissementCode}
                        categorieEtablissementLibelle={categorieEtablissementLibelle}
                    />
                </span>
            </LightEditableBlock>
        </CardContent>
        <CardContent className="summary-don-main-block">  
          <LightEditableBlock
            title="Caractéristiques du don"
            editable={(loginProps.oauth?.profilCode === ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutDonProjetCode!))) || loginProps.oauth?.profilCode === ProfilCode.VILLYZ}
            editor={(close) => (
              <>
                {subFlowStep === SubFlowStep.CARACTERISTIQUES_FINANCEMENT_DON &&
                  <FinancementDons
                    projet={project}
                    onSubmitSuccess={(response: ProjetDetailResponse) => {setSubFlowStep(response.NextSubStepKey!)}}
                  />
                }
                {subFlowStep === SubFlowStep.OBJECTIFS_PROJET_DON && 
                  <ObjectifsForm
                      projet={project}
                      onSubmitSuccess={() => {
                          close();
                          reload();
                      }}
                      isDon={true}
                      isCreating={false}
                      objectifs={objectifs}
                  />
                }
              </>
            )}
            iconCss={{
              position: "absolute",
              right:"12px",
              top:"15%"
            }}
          >
            <InformationsDon
              project= {project} 
              nombreDonateur={nombreDonateur} 
              endDate={endDate!}
              reload={reload}
              onPublishSuccess={onPublishSuccess}
              objectifs={objectifs} 
            />
          </LightEditableBlock>
      </CardContent>
    </Card>
    )
}