import React, { createRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Container, Menu, Sticky } from "semantic-ui-react";
import { AuthenticationState } from "../../model/state/authentication-state";
import { ApplicationState } from "../../store";
import { UserConnectionInformation } from "../user-connection-information";
import {UserConnectionInformationMobile} from "../user-connection-information-mobile"
import {UserConnectionUnAuthMobile} from "../user-connect-info-mobile-unauth"
import { ReactComponent as VillyLogo } from "./logo.svg";
import { useScroll } from "./useScroll";
import { bigApplicationHeader, smallApplicationHeader, primaryColorVillyz, mainBackgroundColor} from "./styles"


type Props = { loginProps?: AuthenticationState };

function ApplicationHeader({ loginProps }: Props) {
  const { scrollY } = useScroll();
  const isFloatingMenu = scrollY > 70;
  const contextRef: React.RefObject<any> = createRef()


  return ( <>
    <div ref={contextRef} css={bigApplicationHeader}>
      <Sticky 
        ref={contextRef}>
        <Menu
          borderless
          secondary
          attached="top"
          style={{ height: "70px", backgroundColor: mainBackgroundColor, border:"transparent"}}
          css={{
            "&.ui.menu": {
             transition: "background-color 0.3s ease",
              ...(isFloatingMenu && { backgroundColor: mainBackgroundColor, boxShadow: "0px 3px 6px 0px rgba(0,0,0,0.41) !important"}),
            },

          }}
        >
          <Container style={{fontFamily:"raleway"}}>
            <Menu.Item
              as={NavLink}
              to="/"
              style={{fill: primaryColorVillyz, background:"none", marginRight:"10px", minHeight:"60px"}}
              activeclassname=""
              aria-label="lien vers l'accueil de villyz"
            >
              <VillyLogo/>
            </Menu.Item>
            <Menu.Item style={{color:primaryColorVillyz, fontWeight:"bold", fontSize: '16px'}} as={NavLink} to="/engagements" activeclassname="active">
              Notre mission
            </Menu.Item>
            <Menu.Item style={{color:primaryColorVillyz, fontWeight:"bold", fontSize: '16px'}} as={NavLink} to="/projets" activeclassname="active">
              Les projets
            </Menu.Item>
            <Menu.Item style={{color:primaryColorVillyz, fontWeight:"bold", fontSize: '16px'}} as={NavLink} to="/collectivites" activeclassname="active">
              Secteur public
            </Menu.Item>
            {!loginProps?.isAuthenticated && (
              <>
                <Menu.Item
                  as={NavLink} 
                  to="/connexion" 
                  activeclassname="active"
                  position="right"
                  css={{ padding:"8px 2px !important"}}
                >
                  <Button
                   css={{
                    backgroundColor:`${mainBackgroundColor} !important`,
                    fontWeight:"bold",
                    padding:"9px 25px !important",
                    textAlign:"center",
                    border:`2px solid ${primaryColorVillyz} !important`,
                    borderRadius:"5px !important",
                    fontFamily:"raleway-bold !important", 
                    marginRight:'0 auto'
                  }}
                  >
                    <span 
                      style={{
                        fontWeight:"bold",
                        fontSize:"18px",
                       
                        color:primaryColorVillyz, 
                      }}
                    >
                      Connexion
                    </span>
                  </Button>
                </Menu.Item>
                <Menu.Item
                  as={NavLink} 
                  to="/inscription" 
                  activeclassname="active"
                  style={{ padding:"8px 2px !important"}}
                >
                  <Button
                       primary
                       css={{
                         fontWeight:"bold",
                         padding:"9px 25px !important",
                         textAlign:"center",
                         border:`2px solid ${primaryColorVillyz} !important`,
                         borderRadius:"5px !important",
                         fontFamily:"raleway-bold !important",
                       }}
                   
                  >
                    <span 
                      style={{
                        fontWeight:"bold",
                        fontSize:"18px",
                        color:"white", 
                      }}
                    >
                      Inscription
                    </span>
                  </Button>
                </Menu.Item>
              </>
            )}
            {loginProps?.isAuthenticated && (
              <Menu.Menu
                position="right"
                css={{ marginRight: "10px", alignItems: "center" }}
              >
                <UserConnectionInformation />
              </Menu.Menu>
            )}
          </Container>
        </Menu>
      </Sticky>
    </div>
    <div ref={contextRef} css={smallApplicationHeader}>
      <Sticky 
        ref={contextRef}>
        <Menu
          borderless
          secondary
          attached="top"
          
          css={{
            border: "transparent !important",
            "&.ui.menu": {
              height: "70px",
              fontFamily:"raleway-bold" ,
              ...(isFloatingMenu && { backgroundColor: mainBackgroundColor, boxShadow: "0px 5px 11px 0px rgba(34, 33, 33, 0.41) !important"}),
            },
           }}
        >
          <Container style={{ width: "100%", color:primaryColorVillyz }}>
            <Menu.Item
              as={NavLink}
              to="/"
              style={{fill: primaryColorVillyz, background:"none"}}
              activeclassname=""
              aria-label="lien vers l'accueil de villyz"
            >
              <VillyLogo color="blue" style={{background: "none"}}/>
            </Menu.Item>
            {!loginProps?.isAuthenticated && (
              <Menu.Menu
              position="right"
              style={{ marginRight: "10px", alignItems: "center" }}
              aria-labelledby="bouton d'ouverture et fermeture du menu déroulant"
              role="button" 
              >
                <UserConnectionUnAuthMobile />
              </Menu.Menu>
            )}
            {loginProps?.isAuthenticated && (
              <Menu.Menu
                position="right"
                style={{ marginRight: "10px", alignItems: "center", color:`${primaryColorVillyz} !important` }}
                aria-label="bouton d'ouverture et fermeture du menu déroulant"
              >
                <UserConnectionInformationMobile />
              </Menu.Menu>
            )}
            
          </Container>
        </Menu>
      </Sticky>
      
  </div>
  </>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  loginProps: state.authentication,
});

const ConnectedApplicationHeader = connect(
  mapStateToProps,
  null
)(ApplicationHeader);
export { ConnectedApplicationHeader as ApplicationHeader };
