import React, { useState } from "react"
import { Form } from "semantic-ui-react"
import { connect } from "react-redux"
import { ApplicationState } from "../../../store"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { InputField } from "../../generic/input-field"
import Projet from "../../../model/projet"
import { SubmitButton } from "../../generic/submit-button"
import { projetService } from "../../../services/projet-service"
import { FormErrorHeader } from "../../generic/form-error-header"
import { SimpleButton } from "../../generic/simple-button"
import { ProjetPeriodeCollecteBody } from "../../../model/dto/body/projet-periode-collecte-body"
import DateMaskFieldNew from "../../generic/date-mask-field"
import { calculateEndOfCollecte, convertDateToMaskPicker } from "../../../helpers/date-helper"
import { WorkflowType } from "../../../model/entities/workflow-type"
import { FlowStep } from "../../../model/entities/flow-step"
import { referenceService } from "../../../services/reference-service"
import { WorkflowProcessResultResponse } from "../../../model/dto/response"

type Props = {
    loginProps?: AuthenticationState,
    projet?: Projet,
    isCreating?: boolean,
    workflowTypeCode?: string,
    onSubmitSuccess: (response: Response) => void,
    onSkipStep?: (response: WorkflowProcessResultResponse) => void;
}

type FieldData = {
    name?: string,
    value?: any,
    isValid?: boolean
}

type FormData = {
    dateDebutCagnotte: FieldData,
    dureeCagnotte: FieldData,
    dateFinCagnotte: FieldData
}


function CagnotteForm ({loginProps, projet, isCreating, workflowTypeCode, onSubmitSuccess, onSkipStep}: Props) {
    const [form, setForm] = useState<FormData>({
        dateDebutCagnotte: {name: 'dateDebutCagnotte', value: projet ? projet.DateDebutFinancementCitoyen : '', isValid: true},
        dureeCagnotte: {name: 'dureeCagnotte', value: projet ? projet.DureeFinancementCitoyen : '', isValid: true},
        dateFinCagnotte: {name: 'dateFinCagnotte', value : projet ? calculateEndOfCollecte(projet.DateDebutFinancementCitoyen, projet.DureeFinancementCitoyen) :'', isValid: true}
    })
   
    const [isFormError, setIsFormError] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [isDateError, setIsDateError] = useState<boolean>(false)

    const validateMandatory = (value: any): boolean => {
        return value && value !== ""
    }

    const wrongDateMessage = "Merci de renseigner la date de démarrage de votre collecte. Il y a un délai de préparation de 14 jours"

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
      
        setForm({ ...form,  ...newField});
        setIsFormError(false)
    }

    const handleDureeInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string): void => {
        const fieldValue: number = +e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
        var result = new Date(form.dateDebutCagnotte.value);
        result.setDate(result.getDate() + fieldValue);
        setForm({ ...form,  ...newField, dateFinCagnotte: {name: 'dateFinCagnotte', value: result.toLocaleDateString()}});
        setIsFormError(false)
    }

    const handleDateChange = (date: Date | null, field: string, isValid?: boolean): void => {
        setForm({ ...form, dateDebutCagnotte:{name: field, value: date, isValid: isValid}, dateFinCagnotte: {name: 'dateFinCagnotte', value: calculateEndOfCollecte(date?.toDateString(), form.dureeCagnotte.value)} })
        setIsFormError(false)
        setIsDateError(false)
        setIsFormSubmitted(false)
    }

    const handleDateDebutChange = (date: Date | null, field: string, isValid?: boolean): void => {
        if(date) {
            var result = new Date(date);
            result.setDate(result.getDate() + form.dureeCagnotte.value);
            setForm({ ...form, dateDebutCagnotte:{name: field, value: date, isValid: isValid}, dateFinCagnotte: {name: 'dateFinCagnotte', value: result.toLocaleDateString()}})
        }
        setIsFormError(false)
        setIsDateError(false)
        setIsFormSubmitted(false)
    }

    const isFormValid = (): boolean => {
        return validateMandatory(form.dateDebutCagnotte.value) && validateDuree(form.dureeCagnotte.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
        if(!form.dateDebutCagnotte.value){
            setIsDateError(true)
        }else{
            setIsDateError(false)
        }
    }

    const onSubmitError = (): void => {

    }

    const onPreSubmit = (): void => {
        setIsFormSubmitted(true)
    }

    const validateDuree = (value: any): boolean => {
        const regex = /[0-9]{2}/g
        const montant = value as number
        return regex.test(value) && montant <= 90 && montant >= 30
    }

    const body: ProjetPeriodeCollecteBody = {
        DateDebut: form.dateDebutCagnotte.value,
        DureeFinancement: form.dureeCagnotte.value,
        WorkflowTypeCode: workflowTypeCode
    }

    const nextPeriodeCollecteStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode ?? '',
            CurrentFlowStep : FlowStep.PERIODE_DE_COLLECTE,
            RessourceId:  projet?.Id!,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipStep && onSkipStep(response)})
    }

    return (
        <Form>
            <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <Form.Field width={16}>
                <DateMaskFieldNew
                    data={form.dateDebutCagnotte}
                    label="Date de démarrage de la collecte"
                    className="field"
                    submitted={isFormSubmitted}
                    onChange={(
                        date: Date | null,
                        isValid?: boolean
                    ) => {
                        handleDateChange(date, form.dateDebutCagnotte.name!, isValid);
                    }}
                    error={wrongDateMessage}
                    onError={isDateError}
                    validateField={validateMandatory}
                    formatModel="# # / # # / # # # #"
                    mask="_"
                    type="tel"
                    allowEmptyFormatting
                    value={projet ? convertDateToMaskPicker(projet.DateDebutFinancementCitoyen!) : undefined}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.dureeCagnotte}
                    submitted={isFormSubmitted}
                    label="Durée de la collecte (entre 30 et 90 jours)"
                    error="Merci de renseigner la durée d'ouverture de la collecte entre 30 et 90 jours"
                    onChange={handleDureeInputChange}
                    validateField={validateDuree}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.dateFinCagnotte}
                    label="Date de fin de la collecte"
                    editable={false}
                />
            </Form.Field>
            <div css={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }} 
                className="button-bar">
                <SubmitButton 
                    data={body}
                    label="Valider"
                    action={() => projetService.savePeriodeCollecteProjet(body, projet?.Id, loginProps?.oauth)}
                    onActionSuccess={onSubmitSuccess}
                    onActionFailure={onSubmitError}
                    onFormNotValid={onFormNotValid}
                    validateForm={isFormValid}
                    onPreSubmit={onPreSubmit}
                    debounceTimming={isCreating ? 1000 : undefined}
                />
                {isCreating && (
                    <SimpleButton 
                        label="Passer cette étape"
                        onClick={() => nextPeriodeCollecteStepKey()}
                    />
                )}
            </div>
        </Form>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedCagnotteForm = connect(mapStateToProps, null)(CagnotteForm);
export { ConnectedCagnotteForm as CagnotteForm };