
import React from "react";

interface SimpleButtonProps {
    label: string;
    className?: string;
    onClick?: () => void;
    secondary? : boolean;
    inverted?: boolean;
    ralewayAnd16? :boolean //pour mettre le texte en raleway et 16px 
    stretchOnDesktop?: boolean // pour avoir un bouton plus étirer en verison pc
}

export function SimpleButton({ label, className, secondary, inverted, ralewayAnd16, stretchOnDesktop, onClick }: SimpleButtonProps) {

    const handleButtonClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className={"button-action center " + className}>
            <button 
                className={secondary ? `btn-secondary ${ralewayAnd16 ? 'raleway-and-16' : ''} ${stretchOnDesktop ? 'stretch-on-desktop' : ''}` : 
                inverted ? `btn-primary-inverted ${ralewayAnd16 ? 'raleway-and-16' : ''} ${stretchOnDesktop ? 'stretch-on-desktop' : ''}` 
                : `btn btn-primary ${ralewayAnd16 ? 'raleway-and-16' : ''} ${stretchOnDesktop ? 'stretch-on-desktop' : ''}`} 
                onClick={handleButtonClick}
                type="button"
            >
                {label}
            </button>
        </div>
    );
}