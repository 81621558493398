import React from "react";
import "./engagementsV3.css";
import {
    Button,
    Container,
    Grid,
    GridColumn,
    GridRow,
    Image,
} from "semantic-ui-react";

import RejoignezUneCommuEngagee from "../pages-components/call-to-action/rejoignez-une-commu-engagee";
import { HeroActionBandeau } from "../../projet/detail/parts/hero-action-bandeau/hero-action-bandeau";
import Testimony from "../pages-components/social-proof/testimony";
import { useNavigate } from "react-router-dom";
import { SimpleButton } from "../../generic/simple-button";


export function EngagementsV3() {
    const navigate = useNavigate()

    return (
        <div id="engagements-page-container">
            {/*======================== HERO SECTION =======================*/}    
            <HeroActionBandeau 
            title="S'engager pour nos territoires"
            paragraph="Nous oeuvrons chaque jour pour rapprocher citoyens et entreprises des acteurs publics de leurs territoires."
            classPage="notre-mission"
            />   

             {/*========================  Nous sommes tous... =====================*/}
             <div id="weAre">
                <Container >
                    <h2 className="weAre-main-title" >Nous sommes tous artisans de la fabrique de nos territoires</h2>
                    <Grid stackable={true} className="weAre-row">
                        <GridRow columns={3}>
                            <GridColumn
                                className="weAre-column"
                                computer={5}
                            >
                                <div className="weAre-column-container-img">
                                    <Image
                                        rounded
                                        src="/img/nos-engagements/notre_mission_asset1_resized.png"
                                        centered
                                        alt="image représentant des élus qui discutent"
                                        className="weAre-column-img"
                                    />
                                </div>
                                <h3 className="h3-as-H2style">Les élus et agents publics</h3>
                                <p className="standard-paragraph">
                                Ils se battent chaque jour pour améliorer le quotidien des citoyens et les représenter. Afin d’engager les habitants de leur lieu de vie dans leurs projets, ils savent qu’ils peuvent compter sur Villyz pour les accompagner.
                                </p>
                            </GridColumn>
                            <GridColumn
                                width={5}
                                className="weAre-column weAre-column-middle"
                            >   
                                <div className="weAre-column-container-img">
                                    <Image
                                        rounded
                                        src="/img/nos-engagements/notre_mission_asset2_resized.png"
                                        centered
                                        alt="image représentant des ouvriers et architectes sur un chantier"
                                        className="weAre-column-img"
                                    />
                                </div>
                                <h3 className="h3-as-H2style">Les entreprises</h3>
                                <p className="standard-paragraph">
                                Elles font battre le coeur économique du pays et souhaitent avoir un impact positif sur la société. Heureusement, elles ont désormais la possibilité de financer simplement les projets de leur territoire.
                                </p>
                            </GridColumn>
                            <GridColumn computer={5} className="weAre-column">
                                <div className="weAre-column-container-img">
                                    <Image
                                        rounded
                                        src="/img/nos-engagements/notre_mission_asset3_resized.png"
                                        centered
                                        alt="illustration de jeunes citoyens regroupés pour une photo avec prise de vue aérienne"
                                        className="weAre-column-img"
                                    />
                                </div>
                                
                                <h3 className="h3-as-H2style">Les citoyens</h3>
                                <p className="standard-paragraph">
                                Ils s’intéressent à l’avenir de leur territoire et veulent soutenir les projets qui leur tiennent à coeur. Les citoyens répondent toujours présent pour participer aux projets de leur territoire et en sont les premiers ambassadeurs.
                                </p>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Container>
                <div style={{display:"flex", justifyContent: "center", marginTop:"5vh"}}>
                        <SimpleButton 
                            label="Découvrir les projets"
                            secondary
                            onClick={() => navigate("/projets")}
                            stretchOnDesktop
                            ralewayAnd16
                        />
                </div>
            </div>

            {/*======================== Testimony =======================*/}
            <div className="engagements-testimony-container">
                <Testimony 
                autor="Yann Guével, Vice-Président de Brest Métropole "
                text="Nous avons remarqué que les gens souhaitaient investir dans des projets sur le territoire, autour des transitions. Avec ce placement, ils savent où part leur argent et ils investissent dans leur quotidien."
                imageUrl="/img/porteur-de-projet/YG_portrait.jpg"
                imageAlt="photo de Yann Guével, Vice-Président de Brest Métropole"
                />
            </div>

            {/*======================== ENGAGEMENTS SECTION =======================*/}
            <div className="engagements-list-section">
                <Container > 
                    <div className="engagements-list-container"
                    >             
                        <Grid stackable={true}>
                            <Grid.Row>
                                <Grid.Column
                                width={6}                           
                                >
                                    <Image
                                        rounded
                                        centered
                                        src="/img/nos-engagements/tower_noBG.png"                                   
                                        alt="image illustrant un édifice en construction avec des ouvriers en action"
                                        
                                    />                            
                                </Grid.Column>
                                <Grid.Column
                                    mobile={9} 
                                    computer={6}
                                    floated="right"
                                    verticalAlign="middle"
                                >
                                    <p className="engagements-paragraph">
                                    <strong>Nous imaginons un avenir où les citoyens ont le pouvoir de participer aux projets de leur territoire</strong> : transition écologique, lutte contre la désertification médicale, développement de transports durables ou création de logements accessibles à tous... Le tout en leur faisant profiter d'un rendement financier attractif.  
                                    </p>
                                </Grid.Column>
                            </Grid.Row>

                            {/* inversement de l'ordre en version mobile */}                     
                            <Grid.Row className="engagements-list-middle-section">
                                    <Grid.Column 
                                        mobile={9} 
                                        computer={6} 
                                        verticalAlign="middle"
                                    >
                                        <p className="engagements-paragraph">
                                        <strong>Notre mission est de permettre aux citoyens de prendre en main l'avenir de leurs territoires en investissant leur épargne</strong> directement dans des projets locaux qui comptent pour eux. Ils savent que 100% de l’argent investi est consacré au projet de leur choix. Et ça change tout.
                                        </p>
                                    </Grid.Column>  
                                    <Grid.Column
                                    mobile={16}
                                    tablet={6}
                                    computer={6}
                                    floated="right"
                                    >
                                        <Image
                                            rounded
                                            centered
                                            src="/img/nos-engagements/campagne_noBG.png"
                                            alt="image d'une vue aérienne d'un village champêtre, avec maison et espaces de verdure"                            
                                        />      
                                    </Grid.Column>                              
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column       
                                mobile={16}
                                tablet={6}
                                computer={6}
                                >
                                    <Image
                                        rounded
                                        src="/img/nos-engagements/group_of_citizens_noBG.png"
                                        centered
                                        alt="illustration d'un groupe échangeant lors de ce qui semble être une réunion"
                                    />
                                </Grid.Column>

                                <Grid.Column
                                    mobile={9} 
                                    computer={6}
                                    floated="right"
                                    verticalAlign="middle"
                                >
                                    <p className="engagements-paragraph">
                                        <strong>Nos valeurs fondamentales sont l'engagement citoyen, la transparence totale, l'innovation constante et le soutien aux transitions</strong> écologiques et sociales. Le cap est clair et nous sommes déterminés à devenir un vecteur de la transformation de nos territoires.
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Container>
            </div>
            
           
            
            {/*========================  Fondateurs =====================*/}
            <div id="fondateurs">
                <Container>
                    <h2 >Des fondateurs engagés à vos côtés</h2>
                    <Image
                        src="/img/nos-engagements/fondateurs.png"
                        rounded
                        className="fondateurs-img"
                        alt="photo des 3 fondateurs de villyz : Sylvain, Arthur et Quentin"
                    />
                    <Grid>
                        <GridRow
                            columns={3}
                            className="fondateurs-row"
                            reversed="phone"
                        >
                            <GridColumn
                                className="fondateurs-column"
                                computer={5}
                                tablet={5}
                                mobile={16}
                            >
                                <h3 className="h3-as-H2style">Sylvain</h3>
                                <p className="standard-paragraph">
                                La finance, il la connait du bout des doigts. Sylvain a passé presque 10 ans à différencier les bons et les mauvais emprunts. Il permet à chaque citoyen d’investir dans des projets vertueux et solides financièrement.
                                </p>
                            </GridColumn>
                            <GridColumn
                                className="fondateurs-column"
                                computer={5}
                                tablet={5}
                                mobile={16}
                            >
                                <h3 className="h3-as-H2style">Arthur</h3>
                                <p className="standard-paragraph">
                                Touche à tout qu’y s’interesse aussi bien à l’action politique qu’au pouvoir des citoyens de façonner leur territoire. Arthur met toute son énergie et ses convictions pour vous trouver les projets avec le plus d’impact.
                                </p>
                            </GridColumn>
                            <GridColumn
                                className="fondateurs-column"
                                computer={5}
                                tablet={5}
                                mobile={16}
                            >
                                <h3 className="h3-as-H2style">Quentin</h3>
                                <p className="standard-paragraph">
                                Parce qu’il est méticuleux et attentionné, Quentin oeuvre chaque jour à la tête de l’équipe tech pour rendre la plateforme Villyz toujours plus intuitive, sécurisée et à votre image. La sécurité de vos données est sa priorité.
                                </p>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </Container>
            </div>

            {/*========================  Call to action =====================*/}
            <div className="communaute-block">
                <RejoignezUneCommuEngagee />
            </div>
        </div>
    );
}
