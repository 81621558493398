import React, { useState } from "react"
import { Form } from "semantic-ui-react"
import { AuthenticationState } from "../../../model/state/authentication-state"
import { InputField } from "../../generic/input-field"
import Projet from "../../../model/projet"
import { SubmitButton } from "../../generic/submit-button"
import { projetService } from "../../../services/projet-service"
import { FormErrorHeader } from "../../generic/form-error-header"
import { SimpleButton } from "../../generic/simple-button"
import DateMaskFieldNew from "../../generic/date-mask-field"
import { calculateEndOfCollecte, convertDateToMaskPicker } from "../../../helpers/date-helper"
import { FlowStep } from "../../../model/entities/flow-step"
import { referenceService } from "../../../services/reference-service"
import { WorkflowProcessResultResponse } from "../../../model/dto/response"
import { ProjetPeriodeDonBody } from "../../../model/dto/body/projet-periode-don-body"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../../store"
import { TypeCollecte } from "../../../model/entities/type-collecte"


type Props = {
    projet?: Projet,
    isCreating?: boolean,
    workflowTypeCode?: string
    onSubmitSuccess: (response: Response) => void,
    onSkipStep?: (response: WorkflowProcessResultResponse) => void;
}

type FieldData = {
    name?: string,
    value?: any,
    isValid?: boolean
}

type FormData = {
    dateDebutCollecte: FieldData,
    dureeCollecte: FieldData,
    dateFinCollecte: FieldData
}

export default function PeriodeDonForm({projet, isCreating, workflowTypeCode, onSubmitSuccess, onSkipStep}: Props) {
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    
    const [form, setForm] = useState<FormData>({
        dateDebutCollecte: {name: 'dateDebutCollecte', value: projet ? projet.DateDebutCollecteDon : '', isValid: true},
        dureeCollecte: {name: 'dureeCollecte', value: projet ? projet.DureeCollecteDon : '', isValid: true},
        dateFinCollecte: {name: 'dateFinCollecte', value : projet ? calculateEndOfCollecte(projet.DateDebutCollecteDon?.toString(), projet.DureeCollecteDon) :'', isValid: true}
    })
   
    const [isFormError, setIsFormError] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [isDateError, setIsDateError] = useState<boolean>(false)

    const validateMandatory = (value: any): boolean => {
        return value && value !== ""
    }

    const wrongDateMessage = "Merci de renseigner la date de démarrage de votre campagne"

    const handleDureeInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string): void => {
        const fieldValue: number = +e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
        var result = new Date(form.dateDebutCollecte.value);
        result.setDate(result.getDate() + fieldValue);
        setForm({ ...form,  ...newField, dateFinCollecte: {name: 'dateFinCollecte', value: result.toLocaleDateString()}});
        setIsFormError(false)
    }

    const handleDateChange = (date: Date | null, field: string, isValid?: boolean): void => {
        setForm({ ...form, dateDebutCollecte:{name: field, value: date, isValid: isValid}, dateFinCollecte: {name: 'dateFinCollecte', value: calculateEndOfCollecte(date?.toDateString(), form.dureeCollecte.value)} })
        setIsFormError(false)
        setIsDateError(false)
        setIsFormSubmitted(false)
    }

    const isFormValid = (): boolean => {
        return validateMandatory(form.dateDebutCollecte.value) && validateDuree(form.dureeCollecte.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
        if(!form.dateDebutCollecte.value){
            setIsDateError(true)
        }else{
            setIsDateError(false)
        }
    }

    // const onSubmitError = (): void => {

    // }

    const onPreSubmit = (): void => {
        setIsFormSubmitted(true)
    }

    const validateDuree = (value: any): boolean => {
        const regex = /[0-9]{1}/g
        const montant = value as number
        return regex.test(value) && montant > 0
    }

    const body: ProjetPeriodeDonBody = {
        DateDebut: form.dateDebutCollecte.value,
        DureeCampagne: form.dureeCollecte.value,
        WorkflowTypeCode: workflowTypeCode,
        IsPageDonCombine: projet?.TypeCollecteCode === TypeCollecte.COMBINAISON_EMPRUNT_DON
    }

    const nextPeriodeCollecteStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode ?? '',
            CurrentFlowStep : FlowStep.PERIODE_DE_DON,
            RessourceId:  projet?.Id!,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipStep && onSkipStep(response)})
    }

  return (
    <Form>
            <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <Form.Field width={16}>
                <DateMaskFieldNew
                    data={form.dateDebutCollecte}
                    label="Date de démarrage de la campagne"
                    className="field"
                    submitted={isFormSubmitted}
                    onChange={(
                        date: Date | null,
                        isValid?: boolean
                    ) => {
                        handleDateChange(date, form.dateDebutCollecte.name!, isValid);
                    }}
                    error={wrongDateMessage}
                    onError={isDateError}
                    validateField={validateMandatory}
                    formatModel="# # / # # / # # # #"
                    mask="_"
                    type="tel"
                    allowEmptyFormatting
                    value={projet ? convertDateToMaskPicker(projet.DateDebutCollecteDon?.toString()!) : undefined}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.dureeCollecte}
                    submitted={isFormSubmitted}
                    label="Durée de la campagne"
                    error="Merci de renseigner la durée de la campagne en jours, non nul"
                    onChange={handleDureeInputChange}
                    validateField={validateDuree}
                />
            </Form.Field>
            <Form.Field width={16}>
                <InputField 
                    data={form.dateFinCollecte}
                    label="Date de fin de la collecte"
                    editable={false}
                />
            </Form.Field>
            <div css={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }} 
                className="button-bar">
                <SubmitButton 
                    data={body}
                    label="Valider"
                    action={() => projetService.savePeriodeDonProjet(body, projet?.Id, loginProps?.oauth)}
                    onActionSuccess={onSubmitSuccess}
                    onFormNotValid={onFormNotValid}
                    validateForm={isFormValid}
                    onPreSubmit={onPreSubmit}
                    debounceTimming={isCreating ? 1000 : undefined}
                />
                {isCreating && (
                    <SimpleButton 
                        label="Passer cette étape"
                        onClick={() => nextPeriodeCollecteStepKey()}
                    />
                )}
            </div>
        </Form>
  )
}
