import React from "react";
import './collectivites-v3.css'
import { NavLink } from "react-router-dom";
import {Button, Container, Grid, Header, Image, Icon} from "semantic-ui-react";
import { HeroActionBandeau } from "../../projet/detail/parts/hero-action-bandeau/hero-action-bandeau";
import Testimony from "../pages-components/social-proof/testimony";
import { SimpleButton } from "../../generic/simple-button";
import Agreement from "../pages-components/agrement/agreement";

type Props = {}

export function CollectivitesV3({}: Props) {
  const redirectToTypeform = () => {
    window.open("https://qekp7gberg2.typeform.com/to/ABjAqXGU", "_blank", "noopener,noreferrer")
  }

  return (
    <div id="page-collectivites">
{/*======================== HERO SECTION =======================*/}
      <HeroActionBandeau
        classPage="secteur-public"
        title="Financez et engagez"
        paragraph="Transformez vos financements en levier d'engagement local, le tout en accédant à des taux d'emprunt compétitifs et à du mécénat."
        textButton="Nous contacter"
        actionButtonUrl={redirectToTypeform}
      >
        <div className="colloc-bandeau-children-container">
            <Testimony 
            autor="Yann Guével, Vice-Président de Brest Métropole "
            text="Nous avons remarqué que les gens souhaitaient investir dans des projets sur le territoire, autour des transitions. Avec ce placement, ils savent où part leur argent et ils investissent dans leur quotidien."
            imageUrl="/img/porteur-de-projet/YG_portrait.jpg"
            imageAlt="photo de Yann Guével, Vice-Président de Brest Métropole"
            morePadding
            />
        </div>
      </HeroActionBandeau>

      {/*======================== CONFIANCE SECTION =====================*/}
      <div className="section-confiance">
        <Container>
          <Grid >
          <h2 className="section-confiance-title">La plateforme de référence du secteur public local</h2>
          <Grid.Row columns={5} verticalAlign="middle">
              <Grid.Column ><Image size="tiny" centered src="./img/logos/Logo Rosoy2.png" alt="logo de la ville de Rosnay"/></Grid.Column>
              <Grid.Column ><Image size="tiny" centered src="./img/logos/LogoBrest_crop.png" alt="logo de la ville de Brest"/></Grid.Column>
              <Grid.Column ><Image size="tiny" centered src="./img/logos/LogoGlanges_crop.png" alt="logo de la ville de Glanges"/></Grid.Column>
              <Grid.Column ><Image centered src="./img/logos/LogoSereilhac_crop.png" alt="logo de la ville de Sereilhac"/></Grid.Column>
              <Grid.Column><Image 
              size="small" 
              centered src="./img/logos/Labastide-Monréjeau.png" alt="logo de la ville de Labastide-Monréjeau"/></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4} verticalAlign="middle" >
              <Grid.Column><Image 
              size="tiny" 
              centered src="./img/logos/LogoHauteville_crop.png" alt="logo de la ville de Hauteville sur mer"/></Grid.Column>
              <Grid.Column><Image 
              size="small" 
              centered src="./img/logos/logo_besancon.png" alt="logo de la ville de Besançon"/></Grid.Column>
              <Grid.Column><Image 
              size="tiny"
               className="column-image-control-mobile" centered src="./img/logos/LogoPleyben_crop.png" alt="logo de la ville de Pleyben"/></Grid.Column>
              <Grid.Column><Image 
              size="tiny" 
              centered src="./img/logos/logo_commentry_sans_fond2.png" alt="logo de la ville de Commentry"/></Grid.Column>
          </Grid.Row>
          </Grid>
        </Container>
        <div className="section-confiance-btn-container">
                <SimpleButton 
                    label="Nous contacter"
                    secondary
                    onClick={redirectToTypeform}
                    stretchOnDesktop
                    ralewayAnd16
                />
        </div>
      </div>  

  {/*========================  LEGAL BANDEAU SECTION =====================*/}
      <div className="section-spacing-standard">
        <Agreement/>
      </div>

    {/*======================== AVANTAGE SECTION =====================*/}
      <div className="section-spacing-standard" >
        <Container >
          <div className="avantage-section-container">
            <Grid stackable={true}>
                <Grid.Row>
                    <Grid.Column
                        mobile={8}
                        tablet={4}
                        computer={6}
                        className="avantage-section-illustration-column"                        
                    >
                      <div className="avantage-section-illustration-container-left">
                          <Image
                                  src="img/porteur-de-projet/two_citizens_noBG.png"                                   
                                  alt="logo illustrant le titre Rendez les citoyens acteurs du financement de vos projets"                
                          />    
                      </div>                        
                    </Grid.Column>

                    <Grid.Column
                        mobile={12}
                        tablet={12}
                        computer={7}
                        floated="right"
                        verticalAlign="middle"
                    >
                        <h2 className="avantage-section-title">Rendez les citoyens acteurs du financement de vos projets</h2>
                        <p  className="avantage-section-description">Parce qu’œuvrer pour les habitants de votre territoire c’est également pouvoir compter sur eux. Nous avons développé l’emprunt citoyen pour donner à chacune et chacun les moyens de soutenir la politique locale et de participer à la vie démocratique de leur territoire.</p>
                    </Grid.Column>
                </Grid.Row>

                {/* inversement de l'ordre en version mobile */}                     
                <Grid.Row className="reversed-mobile-section">
                        <Grid.Column 
                        mobile={12}
                        tablet={12}
                        computer={7} 
                        verticalAlign="middle"
                        >
                            <h2 className="avantage-section-title">Faites de vos projets d’investissement un marqueur démocratique</h2>
                            <p  className="avantage-section-description">Tous les citoyens n’ont pas la possibilité de participer aux conseils de quartier ou à leur nouveau budget participatif. Mais tous sont intéressés par l’impact des projets de leur collectivité sur leur vie de tous les jours. Leur donner la possibilité de s’engager via l’épargne c’est leur permettre d’être acteur de leur vie démocratique.</p>
                        </Grid.Column>
                        
                        <Grid.Column
                        mobile={8}
                        tablet={4}
                        computer={6}
                        floated="right"
                        className="avantage-section-illustration-column"
                        >
                          <div className="avantage-section-illustration-container-right">
                            <Image
                                    src="img/porteur-de-projet/business_manager_no_BG.png"
                                    alt="image d'une personne qui anime une réunion avec une tablette dans les mains"                             
                                />    
                          </div>
                        </Grid.Column>                              
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column       
                      mobile={8}
                      tablet={4}
                      computer={6}
                      className="avantage-section-illustration-column"
                    >
                      <div className="avantage-section-illustration-container-left">
                        <Image
                                src="img/porteur-de-projet/tv_noBG.png"                                  
                                alt="illustration d'une tv qui affiche un village champêtre au pied d'une montagne"                       
                        />    
                      </div>
                    </Grid.Column>

                    <Grid.Column
                        mobile={12}
                        tablet={12}
                        computer={7} 
                        floated="right"
                        verticalAlign="middle"
                    >
                        <h2 className="avantage-section-title">Valorisez votre action publique grâce à l’emprunt citoyen</h2>
                        <p  className="avantage-section-description">La vision des territoires est parfois ancrée dans une image d’Epinal. Or, c’est tout l’inverse et les élus se battent pour le faire savoir ! Nous avons à coeur de vous accompagner pour diffuser les projets par-delà votre collectivité. Pour que l’emprunt citoyen soit une source de fierté locale autant que de rayonnement national. </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
          </div>
        </Container> 
      </div>  

      {/*========================  Témoignage SECTION =====================*/}
      <div className="section-spacing-standard">
        <Testimony 
        autor="Emilie Gillet, Maire de Glanges"
        imageUrl="/img/porteur-de-projet/emilie.png"
        imageAlt="photo de Emilie Gillet"
        text="Nous souhaitions pouvoir impliquer les habitants dans la vie et la dynamique de la commune. Quoi de mieux que de leur permettre
              d'investir dans un projet de territoire comme celui de notre Pôle Santé. Villyz a rendu cela possible et ce fut une grande réussite puisque 114 citoyens ont investi au total 65.000€ !"
        />
      </div>

      {/*======================== Actors =====================*/}
      <div className="section-spacing-standard">
                <Container >
                  <h2 className="actor-section-title">Un financement pour tous les acteurs publics</h2>
                  <div className="actor-section-img-container">
                      <div className="actor-container-element">
                        <div className="actor-container-element-img-block">
                            <Image
                                rounded
                                src="/img/colloc/mayor_noBG.png"
                                alt="image représentant des élus qui discutent"
                                className="actor-image"
                            />
                        </div>
                        <h3 className="actor-image-substitle">Collectivités territoriales</h3>
                      </div>

                      <div className="actor-container-element">
                        <div className="actor-container-element-img-block">
                          <Image
                              src="/img/colloc/ouvrier_noBG.png"
                              alt="image représentant des ouvriers et architectes sur un chantier"
                              className="actor-image"
                          />
                        </div>
                        <h3 className="actor-image-substitle">Etablissements publics et sociétés</h3>
                      </div>
                      
                      <div className="actor-container-element">
                        <div className="actor-container-element-img-block">
                            <Image
                                src="/img/colloc/doctor_no_BG.png"
                                alt="illustration de jeunes citoyens regroupés pour une photo avec prise de vue aérienne"
                                className="actor-image"
                            />
                        </div>
                        <h3 className="actor-image-substitle">Etablissements de santé</h3>
                      </div>
                </div>
                <div style={{display:"flex", justifyContent: "center", marginTop:"5vh"}}>
                       <SimpleButton 
                            label="Nous contacter"
                            secondary
                            onClick={redirectToTypeform}
                            stretchOnDesktop
                            ralewayAnd16
                        />
                </div>
                </Container>
            </div>                             

      {/*========================  Social Proof SECTION =====================*/}
            <div className="section-spacing-custom">
              <Container>
                <Grid stackable verticalAlign="middle">
                <h2 className="section-spacing-h2">Référencée par les acteurs publics du développement</h2>
                <Grid.Row columns={3} centered>
                  <Grid.Column computer={4}><div className="section-referencement-img-first-block"><Image size="tiny" src="./img/logos/logo_european.svg" alt="logo de la regulation européenne de financement crowfunding"/></div></Grid.Column>
                  <Grid.Column computer={6}><Image size="medium" centered src="./img/logos/logo_bdt.svg" alt="logo de la banque des territoires"/></Grid.Column>
                  <Grid.Column computer={4}><div className="section-referencement-img-last-block"><Image size="small" src="./img/logos/Logo_Aides-territoires.png" alt="logo de aides territoires"/></div></Grid.Column>
                </Grid.Row>
                </Grid>
              </Container>
            </div>

      
      {/*========================  Call to Action Section =====================*/}
      <div className="last-section-margin">
        <Container>
          <Grid doubling stackable centered>
            <Grid.Row>
              <div className="call-to-action-global-container">
                <div className="call-to-action-left-box" >
                  <Header
                    as="h2"
                    className="call-to-action-header"
                  >
                    Envie d'engager votre territoire ?
                  </Header>
                  <p className="call-to-action-subtitle">Rencontrez nos experts pour échanger sur vos projets</p>
                  <div className="call-to-action-btn-container">
                    <Button
                      href="https://qekp7gberg2.typeform.com/to/ABjAqXGU"
                      target= "_blank"
                      color="blue"
                      className="call-to-action-btn-first"
                    >
                      Parler à un expert
                    </Button>
                    <Button
                      as={NavLink}
                      to="/projets"
                      className="call-to-action-btn-second"
                      >
                        Voir les projets
                    </Button>
                  </div>
                </div>
                <div className="call-to-action-right-box">
                  <div className="call-to-action-advantage-container call-to-action-advantage-container-margin">
                    <Icon 
                      name='check circle' 
                      size='small' 
                      className="call-to-action-advantage-icon"
                    />
                    <p className="call-to-action-advantage-text">Simulez vos taux de financement citoyen et optimisez vos emprunts</p>
                  </div>
                  <div className="call-to-action-advantage-container call-to-action-advantage-container-margin">
                    <Icon 
                      name='check circle' 
                      size='small' 
                      className="call-to-action-advantage-icon"
                    />
                    <p className="call-to-action-advantage-text">Construisez le financement adapté à vos besoins</p>
                  </div>
                  <div className="call-to-action-advantage-container">
                    <Icon 
                      name='check circle' 
                      size='small' 
                      className="call-to-action-advantage-icon"
                    />
                    <p className="call-to-action-advantage-text">Engagez l'ensemble de votre territoire et au-delà</p>
                  </div>
                </div>
              </div>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </div>
  )
}