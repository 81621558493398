import React from 'react'
import { Container } from 'semantic-ui-react';
import { SocialProofResponse } from '../../../../model/dto/response';
import { useNavigate } from 'react-router-dom';
import './social-proof-kpi.css'

type Props = {
    socialProof?: SocialProofResponse
}

export default function SocialProofKpi({socialProof} : Props) {
    const navigate = useNavigate();

  return (
    <Container>
        <div className='container-global'>
            <div className='container-social-proof-block'>
                <div className='social-proof-title'>
                    <h2>Rejoignez une communauté engagée</h2>
                </div>
                
                <div className='social-proof-block'>
                    <div className='social-proof-block-element'>
                        <h3 className='social-proof-block-heading '>
                            +{socialProof?.TotalMembres}
                        </h3>
                    </div>
                    <p className='social-proof-block-description'>
                            membres
                    </p>
                </div>

                <div className='social-proof-block'>
                    <div className='social-proof-block-element'>
                        <h3 className='social-proof-block-heading '>
                            +
                            {
                                socialProof?.TotalInvestissementReussi
                            }
                        </h3>
                        <p className='social-proof-block-label'>
                            M €
                        </p>
                    </div>
                    <p className='social-proof-block-description'>
                        investis pour l'avenir
                    </p>
                </div>

                <div className='social-proof-block'>
                    <div className='social-proof-block-element'>
                        <h3 className='social-proof-block-heading'>0</h3>
                        <p className='social-proof-block-label'>%</p>
                    </div>
                    <p className='social-proof-block-description'>
                        taux de défaut*
                    </p>
                </div>
            </div>
        </div>
        <div
            css={{
                color: "#005877",
                fontSize: "14px",
                textAlign: "right",
                maxWidth: "90%",
                margin: "6px auto",
                cursor: "pointer",
            }}
            onClick={() => {
                navigate("/statistiques");
            }}
        >
            <p>*pour consulter nos statisques c'est par ici !</p>
        </div>
    </Container>
  )
}
