import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./home.css";
import {
    Container,
    Grid,
    Header,
    Image,
    Loader,
    Card,
    GridColumn,
} from "semantic-ui-react";
import { ProjetListItem } from "../../../model/dto/projet-list-item";
import { PourBientotScreen } from "../../generic/c-est-pour-bientot";
import { ProjetLine } from "../../projet/projet-line";
import { publicService } from "../../../services/public-service";
import { ProfilCode, SocialProofResponse } from "../../../model/dto/response";
import FeedbackSignature from "../pages-components/feedback/feedback-signature";
import RejoignezUneCommuEngagee from "../pages-components/call-to-action/rejoignez-une-commu-engagee";
import { StatutProjet } from "../../../model/entities/statut-projet";
import { AuthenticationState } from "../../../model/state/authentication-state";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { HeroActionBandeau } from "../../projet/detail/parts/hero-action-bandeau/hero-action-bandeau";
import SocialProofKpi from "../pages-components/social-proof/social-proof-kpi";
import { SimpleButton } from "../../generic/simple-button";
import Agreement from "../pages-components/agrement/agreement";

type Props = {};

export function HomeV4({}: Props) {
    const [projetList, setProjetList] = useState<ProjetListItem[] | undefined>(
        undefined
    );
    const [socialProof, setSocialProof] = useState<SocialProofResponse>();
    const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)
    const navigate = useNavigate();

    useEffect(() => {
        publicService
            .getHomeProjects()
            .then((projetList) => setProjetList(projetList));
    }, []);

    useEffect(() => {
        publicService
            .getSocialProof()
            .then((socialProof) => setSocialProof(socialProof));
    }, []);

    const isCardClickable = (projet: ProjetListItem) => {
        if(projet.StatutProjetCode === StatutProjet.A_VENIR ){
            if(loginProps?.user?.ProfilCode === ProfilCode.PORTEUR_PROJET && projet.EtablissementId === loginProps?.user?.Etablissement?.Id){
                return true
            }
            else if(loginProps?.oauth?.profilCode === ProfilCode.VILLYZ){
                return true
            }
            return false
        }
        return true
    }

    return (
        <div id="page-home">
            {/*======================== HERO SECTION =======================*/}
                <HeroActionBandeau 
                    classPage="home"
                    paragraph="Valorisez votre épargne dans les projets des collectivités territoriales, des sociétés publiques et des établissements de santé."
                    title="Investissez votre territoire"
                    actionButtonUrl={() => navigate("/projets")}                
                    textButton="Découvrir les projets"
                >
                {/*========================  Social Proof 1  SECTION =====================*/}
                    <SocialProofKpi socialProof={socialProof} />
                </HeroActionBandeau>
        
             {/*======================== PROJECT SECTION =====================*/}
                        <div className="standard-section-spacing">
                            <Container>
                                <div>
                                    <div className="section-header-title">
                                        <h2 style={{fontSize:'24px', color:"black"}}>Découvrez les projets de votre territoire</h2>
                                    </div>
                                    {projetList ? (
                                        projetList.length > 0 ? (
                                            <>
                                                <Card.Group
                                                    centered
                                                    itemsPerRow={3}
                                                    doubling
                                                    stackable
                                                >
                                                    {projetList.map((projetItem) => (
                                                        <ProjetLine
                                                            key={projetItem.Id}
                                                            projet={projetItem}
                                                            isCardClickable={isCardClickable(projetItem)}
                                                        />
                                                    ))}
                                                </Card.Group>
                                            </>
                                        ) : (
                                            <>
                                                <PourBientotScreen />
                                            </>
                                        )
                                    ) : (
                                        <Loader active inline="centered" />
                                    )}
                                </div>
                                <div style={{display:"flex", justifyContent: "center", marginTop:20}}>
                                    <SimpleButton 
                                    label="Découvrir les projets"
                                    className="btn-call-to-action"
                                    secondary
                                    stretchOnDesktop
                                    ralewayAnd16
                                    onClick={() => navigate("/projets")}/>
                                </div>
                            </Container>
                        </div>
            
                        {/*======================== AVANTAGE SECTION =====================*/}
                        <div className="standard-section-spacing">
                            <Container>
                            <div className="standard-section-container">
                                    <Grid stackable={true}>
                                        <Grid.Row>
                                            <Grid.Column
                                                mobile={8}
                                                tablet={4}
                                                computer={6}
                                                className="avantage-section-illustration-column"
                                            >
                                                <div className="avantage-section-illustration-container-left">
                                                    <Image
                                                        src="img/home/planet_earth_nobg.png"
                                                        alt="logo illustrant la planète terre"
                                                    />
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column
                                                mobile={12}
                                                tablet={12}
                                                computer={6}
                                                floated="right"
                                                verticalAlign="middle"
                                            >
                                                <h3 className="avantage-section-title">
                                                    Donnez de l'impact à votre épargne
                                                </h3>
                                                <p className="avantage-section-description">
                                                Tous nos projets répondent à un besoin de territoire, dans la santé, la transition écologique ou encore d'égalité. C'est pourquoi chaque euro investi sur notre plateforme est une brique de plus pour construire les territoires de demain.
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                
                                        {/* inversement de l'ordre en version mobile */}
                                        <Grid.Row className="reversed-mobile-section">
                                            <Grid.Column
                                                mobile={12}
                                                tablet={12}
                                                computer={6}
                                        
                                                verticalAlign="middle"
                                            >
                                                <h3 className="avantage-section-title"
                                                >
                                                    Pilotez vos investissements
                                                </h3>
                                                <p className="avantage-section-description">
                                                    Retrouvez toutes les informations aussi bien financières que d'impact pour prendre la bonne décision d'investissement. Puis investissez sereinement : 100% de votre paiement sera fléché vers le projet de votre choix.
                                                </p>
                                            </Grid.Column>
                                            <Grid.Column
                                                mobile={8}
                                                tablet={4}
                                                computer={6}
                                                floated="right"
                                                className="avantage-section-illustration-column"
                                            >
                                                <div className="avantage-section-illustration-container-right">
                                                    <Image
                                                        src="img/home/rocket_nobg.png"
                                                        alt="logo représentant une fusée lancée"
                                                    />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                
                                        <Grid.Row>
                                            <Grid.Column
                                                mobile={8}
                                                tablet={4}
                                                computer={6}
                                                className="avantage-section-illustration-column"
                                            >
                                                <div className="avantage-section-illustration-container-left">
                                                    <Image
                                                        src="img/home/arbre_plante_nobg.png"
                                                        alt="logo représentant un arbre planté qui commence à pousser"
                                                    />
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column
                                                mobile={12}
                                                tablet={12}
                                                computer={6}
                                                floated="right"
                                                verticalAlign="middle"
                                            >
                                                <h3 className="avantage-section-title">
                                                    Faites converger les intérêts
                                                </h3>
                                                <p className="avantage-section-description">
                                                Investir dans des projets d'intérêt général, c'est participer collectivement à construire des services publics efficaces, fiables et accessibles. Pour cela, nous permettons d'agir dès 1€, selon ses moyens et ses objectifs d'épargne. 
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            </Container>
                        </div>
            
              {/*======================== MEDIA SECTION =====================*/}
              <div className="custom-section-spacing">
                <Container>
                    <div className="media-section">
                        <Header
                            as="h2"
                            className="media-section-header"
                        >
                            Ils parlent de nous
                        </Header>
                        <Grid doubling stackable centered>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <div style={{padding: "5px"}}>
                                        <div style={{display:"flex", justifyContent:"center"}}>
                                            <a
                                                href="https://www.radiofrance.fr/franceinter/podcasts/le-zoom-de-la-redaction/le-zoom-de-la-redaction-du-mardi-24-mai-2022-1166400"
                                                target="_blank"
                                                aria-label="lien vers france inter"
                                            >
                                                <Image
                                                    className="left"
                                                    src="/img/colloc/Colloc_asset_13.png"
                                                    alt="logo de france inter"
                                                    style={{
                                                        height: "56px",
                                                        width: "auto",
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <p className="media-section-description">
                                            "Villyz propose différents projets
                                            d'intérêt général à des petites
                                            collectivités et sécurise les
                                            placements. Ou comment mieux
                                            impliquer dans la vie citoyenne
                                            locale les habitants."
                                        </p>
                                        
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={{padding: "5px"}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "auto",
                                                justifyContent:"center"
                                            }}
                                        >
                                            <a
                                                href="https://www.finmag.fr/blog/villyz-interview/"
                                                target="_blank"
                                                aria-label="lien vers la revue des collectivités locales"
                                            >
                                                <Image
                                                    className="left"
                                                    src="/img/home/rcl.png"
                                                    alt="logo de la revue rcl"
                                                    style={{
                                                        height: "56px"
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <p className="media-section-description">
                                            "Question sécurité, la plateforme, au même titre que les établissements bancaires, est régulée par l'autorité de contrôle prudentiel et de résolution."
                                        </p>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div style={{padding: "5px"}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                height: "56px",
                                                width: "auto",
                                                justifyContent:"center"
                                            }}
                                        >
                                            <a
                                                href="https://www.leparisien.fr/haute-vienne-87/deserts-medicaux-pour-financer-le-pole-sante-des-citoyens-deviennent-banquiers-de-la-commune-11-05-2022-KNBK27QG6JHFLAYWLHROMZ3YLE.php"
                                                target="_blank"
                                                aria-label="lien vers Le Parisien"
                                            >
                                                <Image
                                                    className="left"
                                                    src="/img/colloc/parisien.png"
                                                    alt="logo du journal le Parisien"
                                                    style={{
                                                        width: "135px",
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <p className="media-section-description">
                                            "Une démarche différente des autres
                                            campagnes de financement
                                            participatif : il s'agit là non pas
                                            de dons mais bien d'un prêt."
                                        </p>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Container>
            </div>

             {/*========================  Video Section =====================*/}
             <section className="standard-section-spacing">
                <Container>
                    <div className="section-header-title">
                        <h2 style={{color:"black"}}>Réconciliez votre épargne avec l'intérêt général</h2>
                    </div>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className="video-section">
                            <iframe
                                style={{ borderRadius: "4px", height:"100%",width:"100%" }}
                                width="100%"
                                height="100%"
                                    src="https://www.youtube.com/embed/gtGm5JOK_ug?start=4"
                                title="Devenez acteur de votre territoire avec Villyz"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>                      
                        </div>
                    </div>
                </Container>
            </section>

            
            {/*========================  Avis Section =====================*/}
            <section className="standard-section-spacing">
                <Container>
                    <div>
                        <div className="section-header-title">
                            <h2 style={{color:"black"}}>Vous êtes nos meilleurs ambassadeurs</h2>
                        </div>
                    </div>
                    <Grid centered doubling stackable>
                        <Grid.Row columns={3}>
                            <GridColumn>
                                <div className="flex-centered-container">
                                    <Card  className="avis-card-block">
                                        <Card.Content>
                                            <Card.Description>
                                                <p className="avis-text-paragraph avis-text-paragraph-first-element">
                                                    «&nbsp;J'ai découvert le
                                                    concept de l'investissement
                                                    citoyen par la presse. Le
                                                    site est bien clair et le
                                                    paiement facile et sécurisé.{" "}
                                                    <br /> J'ai eu rapidement
                                                    quelqu'un au téléphone pour
                                                    répondre à toutes mes
                                                    questions.&nbsp;»
                                                </p>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content className="avis-name-content">
                                            <FeedbackSignature
                                                name="Ronan"
                                                genre="homme"
                                                src="/img/avatars/Témoignage1.png"
                                            />
                                        </Card.Content>
                                    </Card>
                                </div>
                            </GridColumn>
                            <GridColumn>
                                <div className="flex-centered-container">
                                    <Card className="avis-card-block">
                                        <Card.Content>
                                            <Card.Description>
                                                <p className="avis-text-paragraph">
                                                    «&nbsp;Nos investissements
                                                    ont un impact sur notre
                                                    avenir, aussi le concept
                                                    d’investissement citoyen est
                                                    un excellent moyen de
                                                    s’impliquer. La plateforme
                                                    Villyz est très réactive,
                                                    répond rapidement aux
                                                    questions qu’on peut se
                                                    poser.&nbsp;»
                                                </p>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content className="avis-name-content">
                                            <FeedbackSignature
                                                name="Laurence"
                                                genre="femme"
                                                src="/img/avatars/Témoignage2.png"
                                            />
                                        </Card.Content>
                                    </Card>
                                </div>
                            </GridColumn>
                            <GridColumn>
                                <div className="flex-centered-container">
                                    <Card className="avis-card-block">
                                        <Card.Content>
                                            <Card.Description>
                                                <p className="avis-text-paragraph">
                                                    «&nbsp;C'est notre volonté
                                                    d'aller dans le sens d'un
                                                    meilleur environnement et de
                                                    la protection de notre
                                                    planète. Je suis
                                                    reconnaissant pour l'accueil
                                                    et la disponibilité dont
                                                    Villyz a fait preuve lors de
                                                    la finalisation de notre
                                                    investissement. &nbsp;»
                                                </p>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content className="avis-name-content">
                                            <FeedbackSignature
                                                name="Michel"
                                                genre="homme"
                                                src="/img/avatars/Témoignage3.png"
                                            />
                                        </Card.Content>
                                    </Card>
                                </div>
                            </GridColumn>
                        </Grid.Row>
                        <div style={{display:"flex", justifyContent: "center", marginTop:20}}>
                                <SimpleButton 
                                    label="Découvrir les projets"
                                    className="btn-call-to-action"
                                    secondary
                                    stretchOnDesktop
                                    ralewayAnd16
                                    onClick={() => navigate("/projets")}

                                />
                        </div>
                    </Grid>
                </Container>
            </section>


            {/*========================  Social Proof 2  SECTION =====================*/}
            <div className="custom-section-spacing">
                <Agreement/>
            </div>

            {/*========================  Partners Section =====================*/}
            <section className="custom-section-spacing">
                <Container>
                    <div className="standard-section-container">
                        <Grid stackable >
                            <Grid.Row columns={2} >
                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                        <div style={{marginBottom: "4vh"}}>
                                            <h2 style={{color:"black"}}>Nos partenaires au service des territoires</h2>
                                        </div>
                                        <p
                                            className="partners-section-text-container-paragraph"
                                        >
                                            Être la première plateforme prestataire de services de financement participatif dédiée au secteur public et aux projets de territoires, c'est aussi être référencée par des acteurs bancaires reconnus partout en France.
                                        </p>
                                </Grid.Column>
                                <Grid.Column computer={7} tablet={8} mobile={16} floated="right">
                                    <Grid centered>
                                        <Grid.Row centered columns={2}>
                                            <Grid.Column computer={7} tablet={7}>
                                                <div
                                                className="partners-section-logo-container"
                                                >
                                                    <Image
                                                        src="/img/colloc/banque-territoires.png"
                                                        alt="logo de la banque des territoires"
                                                    />
                                                </div>
                                            </Grid.Column>
                                            <Grid.Column computer={7} tablet={8}>
                                                <div className="partners-section-logo-container">
                                                    <Image
                                                        src="/img/colloc/credit-agricole.png"
                                                        alt="logo du crédit agricole des savoie"
                                                    />
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row centered>
                                            <GridColumn computer={13} tablet={16}>
                                                <div className="partners-section-logo-container">
                                                    <Image
                                                        src="/img/colloc/caisse-epargne.png"
                                                        alt="logo de la caisse d'épargne de Midi-Pyrénées"
                                                    />
                                                </div>
                                            </GridColumn>              
                                        </Grid.Row>
                                    </Grid>               
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>                                
                </Container>
            </section>

            {/*========================  Call to Action Section =====================*/}
            <div style={{ marginTop: "70px", marginBottom: "80px" }}>
                <RejoignezUneCommuEngagee />
            </div>
        </div>
    );
}
