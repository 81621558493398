import { addDays, differenceInDays, format, parseISO } from "date-fns";
import React, { useMemo, useState } from "react";
import { Card, CardContent, Header, Icon, Modal, Popup} from "semantic-ui-react";
import { ProfilCode, ProjetDetailResponse } from "../../../../../model/dto/response";
import { StatutProjet } from "../../../../../model/entities/statut-projet";
import Projet from "../../../../../model/projet";
import { projetService } from "../../../../../services/projet-service";
import { LightEditableBlock } from "../../../../generic/editable-block/light-editable-block";
import { CagnotteForm } from "../../../forms/cagnotte-form";
import { FinancmentParticipatifForm } from "../../../forms/financement-participatif-form";
import { ProjectInfos, References } from "../../project-detail";
import { AuthenticationState } from "../../../../../model/state/authentication-state"
import { ApplicationState } from "../../../../../store"
import { useSelector } from "react-redux";
import { SuivreProjetBody } from "../../../../../model/dto/body/suivre-projet-body";
import FinancementParticipatif from "../financement-participatif/financement-participatif";
import { ProjetStatut } from "../../../projet-statut";
import './summary-block.css'
import { SubFlowStep } from "../../../../../model/entities/sub-flow-step";
import ObjectifsForm from "../../../forms/objectifs-form";
import ProjetObjectifs from "../../../../../model/entities/projet-objectifs";

type Props = {
  project: Projet;
  projetInfos: ProjectInfos;
  profile: ProfilCode;
  isProjetFollowed?: boolean;
  isUtilisateurAlerted?: boolean;
  nonEditListCode: string[];
  references: References;
  score?: number;
  categorieEtablissementCode?: string,
  categorieEtablissementLibelle?: string,
  nombreInvestisseur: number,
  reponseNumeroList?: number[];
  objectifs?: ProjetObjectifs[];
  reload: () => void;
  onPublishSuccess: (response: Response) => void
};

export default function SummaryBlock({
  project,
  projetInfos,
  profile,
  nonEditListCode,
  isProjetFollowed,
  isUtilisateurAlerted,
  references,
  score,
  categorieEtablissementCode,
  categorieEtablissementLibelle,
  nombreInvestisseur,
  reponseNumeroList,
  objectifs,
  reload,
  onPublishSuccess,
}: Props) {

  const [isProjetSuivi, setIsProjetSuivi] = useState(isProjetFollowed)
  const [isUserAlerted, setIsUserAlerted] = useState(isUtilisateurAlerted)
  const [open, setOpen] = useState(false)
  const [popupMessage, setPopupMessage] = useState('Cliquez ici pour suivre  ou ne plus suivre le projet')
  const [subFlowStep, setSubFlowStep] = useState(SubFlowStep.CARACTERISTIQUES_FINANCEMENT_EMPRUNT)

  const loginProps: AuthenticationState = useSelector<ApplicationState, AuthenticationState>(state => state.authentication)

  const daysUntilEnd = useMemo(() => {
    if (
      !project?.DateDebutFinancementCitoyen ||
      !project?.DureeFinancementCitoyen
    ) {
      return "-";
    }

    const startDate = parseISO(project.DateDebutFinancementCitoyen);
    
    const endDate = addDays(startDate, project.DureeFinancementCitoyen+1);
    return new Intl.NumberFormat("fr-FR", {
      style: "decimal",
    }).format(differenceInDays(new Date(), endDate));
  }, [project]);

  const endDate = useMemo(() => {
    if(project.DateCloture) {
      return parseISO(project.DateCloture)
    }

    if (
      !project?.DateDebutFinancementCitoyen ||
      !project?.DureeFinancementCitoyen
    ) {
      return undefined;
    }

    const startDate = parseISO(project.DateDebutFinancementCitoyen);
    return addDays(startDate, project.DureeFinancementCitoyen);
  }, [project]);

  const handleIconClick = () => {

    if(loginProps.isAuthenticated) {
      const body: SuivreProjetBody = {
        IsAbo: !isProjetSuivi
      }
      projetService.suivreProjet(body, project.Id!, loginProps?.oauth)

      if(!isProjetSuivi) {
        setPopupMessage('Projet Suivi!')
      }
      else {
        setPopupMessage('Vous ne suivez plus ce projet')
      }
    
      setIsProjetSuivi(!isProjetSuivi)
    }
    else {
      setOpen(true)
    }
  }

  return (
    <Card fluid >
      <CardContent className="summary-header-block" >
            <LightEditableBlock
              title="Période de collecte"
              editable={(profile === ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutProjetCode!)) || profile===ProfilCode.VILLYZ)}
                editor={(close) => (
                <CagnotteForm
                  projet={project}
                  onSubmitSuccess={() => {
                    close();
                    reload();
                  }}
                />
              )}
              iconCss={{
                position: "absolute",
                right:"10px",
                top:"10px"
              }}
            > 
              {(project.StatutProjetCode === StatutProjet.COMPLET || 
                project.StatutProjetCode === StatutProjet.INCOMPLET || 
                project.StatutProjetCode === StatutProjet.EN_TRAITEMENT || 
                project.StatutProjetCode === StatutProjet.INFORMATION_MANQUANTE || 
                project.StatutProjetCode === StatutProjet.A_VENIR) && (
                <div
                className="summary-header-date-block"
                  style={{height:"100%", display: "flex", flexDirection:"column", justifyContent:"space-around", alignItems: "center" }}
                 >
                <span 
                className="summary-header-span-day"
                >
                    <Icon name="clock" size="large" /> J{daysUntilEnd}
                </span>
                {endDate && (
                    <span 
                    // style={{ fontSize: ".9em", display: "flex", justifyContent:"center", alignItems: "center"}}
                    className="summary-header-span-end-date"
                    >
                    Termine le {format(endDate, "dd/MM/yy")}
                    </span>
                )}
                </div>
              )}
              <div className="summary-header-text-area"
              // style={{display:"flex !important", alignItems:"center !important", justifyContent:"center !important", marginTop:"5px !important"}}
              >
                <ProjetStatut 
                  daysUntilEnd={daysUntilEnd} 
                  statut={project.StatutProjetCode!} 
                  categorieEtablissementCode={categorieEtablissementCode}
                  categorieEtablissementLibelle={categorieEtablissementLibelle}
                />
              </div>
          </LightEditableBlock>
        </CardContent>
        <CardContent 
        className="summary-central-block"
        //  css={{position: "relative", height:"75%", padding:"0px 10px !important"}}
         >  
          <LightEditableBlock
            title="Financement participatif"
            editable={(profile === ProfilCode.PORTEUR_PROJET && !(nonEditListCode.includes(project.StatutProjetCode!)) || profile===ProfilCode.VILLYZ)}
            editor={(close) => (
              <>
                {subFlowStep === SubFlowStep.CARACTERISTIQUES_FINANCEMENT_EMPRUNT && 
                  <FinancmentParticipatifForm
                    onSubmitSuccess={(response: ProjetDetailResponse) => {
                      setSubFlowStep(response.NextSubStepKey!)
                    }}
                    projet={project}
                    projetInfos={projetInfos}
                  />
                }
                {subFlowStep === SubFlowStep.OBJECTIFS_PROJET_EMPRUNT && 
                  <ObjectifsForm
                    projet={project}
                    onSubmitSuccess={() => {
                        close();
                        reload();
                    }}
                    isDon={false}
                    isCreating={false}
                    objectifs={objectifs}
                  />
                }
              </>
            )}
            iconCss={{
              position: "absolute",
              right:"12px",
              top:"10px"
            }}
          >
            <FinancementParticipatif
              project= {project} 
              projetInfo={projetInfos}
              profile={profile} 
              references={references}
              isUserAlerted = {isUserAlerted} 
              setIsUserAlerted = {setIsUserAlerted} 
              nombreInvestisseur={nombreInvestisseur} 
              categorieEtablissementCode={categorieEtablissementCode}
              onPublishSuccess={onPublishSuccess} 
              endDate={endDate!} 
              score={score}
              reponseNumeroList={reponseNumeroList}
              reload={reload}
              objectifs={objectifs} 
            />
          </LightEditableBlock>
      </CardContent>
      {profile === ProfilCode.INVESTISSEUR && (
          <CardContent 
              style={{
                height:"10%", 
                display:"flex", 
                flexDirection:"row", 
                justifyContent:"center", 
                alignItems:"center",
                marginTop:"10px"
              }} 
          >
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                closeOnDocumentClick
                trigger={
                    <Popup 
                        content={popupMessage}
                        position='bottom left'
                        size='mini'
                        on='hover'
                        pinned
                        mouseEnterDelay={200}
                        mouseLeaveDelay={200}
                        hideOnScroll
                        trigger={
                          <div onClick={handleIconClick} css={{display:'inline-block', cursor: 'pointer'}}>
                            <span 
                            style={{color:"grey", 
                                        fontSize: "1em", 
                                        fontWeight:"bold", 
                                        marginRight:"5px"
                                      }}
                            >
                                SUIVRE CE PROJET
                            </span>
                            <span 
                            style={{ display: 'inline-block',
                                         marginLeft: '5px'
                                      }}
                            >
                              {!isProjetSuivi && (<Icon color="blue" size="large" name="heart outline"/>)}
                              {isProjetSuivi && (<Icon color="blue" size="large" name="heart" />)}
                            </span>
                          </div>
                        }
                    />
            }>
              <Header content='Suivre le projet' />
              <Modal.Content>
                <p>
                    Merci de vous <a href="/login"> connecter </a> ou vous <a href="/login">inscrire</a> pour suivre l'actualité du projet
                </p>
              </Modal.Content>
            </Modal>
          </CardContent> 
        )}
    </Card>
  );
}
