import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { Form } from "semantic-ui-react"
import {DepartementListResponse, PeriodiciteListResponse, TableauAmortissementResponse, TypologieRemboursementListResponse, WorkflowProcessResultResponse } from "../../../model/dto/response"
import { PeriodiciteEcheance } from "../../../model/entities/periodicite-echeance"
import { TypologieRemboursement } from "../../../model/entities/typologie-remboursement"
import Projet from "../../../model/projet"
import { projetService } from "../../../services/projet-service"
import { referenceService } from "../../../services/reference-service"
import { ApplicationState } from "../../../store"
import { AuthenticationState } from "../../../store/authentication/types"
import { CheckBoxElement, CheckBoxListField } from "../../generic/checkbox-list-field"
import { CheckBoxSimpleField } from "../../generic/checkbox-simple-field/checkbox-simple-field"
import {DropdownListFieldNew } from "../../generic/dropdown-list-field"
import { InputField } from "../../generic/input-field"
import { SubmitButton } from "../../generic/submit-button"
import { TableauAmortissementRequestBody } from "../../../model/dto/body/tableau-amortissement-request-body"
import { FormErrorHeader } from "../../generic/form-error-header"
import { SimpleButton } from "../../generic/simple-button"
import { ProjetFinancementParticipatifBody } from "../../../model/dto/body/projet-financement-participatif-body"
import { TypeEmprunt } from "../../../model/entities/type-emprunt"
import { MultipleDropdownSelection, DropdownElement } from "../../generic/multiple-dropdown-selection"
import { ProjectInfos } from "../detail/project-detail"
import { FlowStep } from "../../../model/entities/flow-step"

type Props = {
    loginProps?: AuthenticationState,
    projet: Projet,
    projetInfos?: ProjectInfos,
    isCreating?: boolean,
    workflowTypeCode?: string,
    onSubmitSuccess: (response: Response) => void,
    onSkipStep?: (response: WorkflowProcessResultResponse) => void;
}

type FieldData = {
    name?: string,
    value?: any,
    isValid?: boolean
}

type FormData = {
    montantRecherche: FieldData,
    tauxInteretCitoyen: FieldData,
    maturiteCreditCitoyen: FieldData,
    typologieRemboursement: FieldData,
    periodiciteRemboursement: FieldData,
    certificationLimite: FieldData,
    typeEmprunt: FieldData,
    periodeSansRemboursement: FieldData,
    montantMaximalInvestissement: FieldData,
    montantMaximalFinancement: FieldData,
    isRestrictedZoneGeo: FieldData
    departementIdList: FieldData
}


function FinancmentParticipatifForm({loginProps, projet, projetInfos, isCreating, workflowTypeCode, onSubmitSuccess, onSkipStep}: Props) {
    const [form, setForm] = useState<FormData>({
        montantRecherche: {name: 'montantRecherche', value: projet ? projet.MontantFinancementCitoyenDemande : '', isValid: true},
        tauxInteretCitoyen: {name: 'tauxInteretCitoyen', value: projet ? projet.TauxInteretAnnuel : '', isValid: true},
        maturiteCreditCitoyen: {name: 'maturiteCreditCitoyen', value: projet ? projet.MaturiteCreditCitoyen : '', isValid: true},
        typologieRemboursement: {name: 'typologieRemboursement', value: projet ? projet.TypologieRemboursementCode : '', isValid: true},
        periodiciteRemboursement: {name: 'periodiciteRemboursement', value: projet ? projet.PeriodiciteEcheanceCitoyenCode : '', isValid: true},
        certificationLimite: {name: 'certificationLimite', value: projet ? projet.CertificationLimiteFinancement : '', isValid: true},
        typeEmprunt: {name: 'typeEmprunt', value: projet ? projet.TypeEmpruntCode : TypeEmprunt.PRET, isValid: true},
        periodeSansRemboursement: {name: 'periodeSansRemboursement', value: projet ? projet.PeriodeSansRemboursement : '', isValid: true},
        montantMaximalInvestissement: {name: 'montantMaximalInvestissement', value: projet ? (projet.MontantMaximalInvestissement ? projet.MontantMaximalInvestissement / 100 : '') : '', isValid: true},
        montantMaximalFinancement: {name: 'montantMaximalFinancement', value: projet ? (projet.MontantMaximalFinancement ? projet.MontantMaximalFinancement / 100 : '') : '', isValid: true},
        isRestrictedZoneGeo: {name: 'isRestrictedZoneGeo', value: projet ? projet.IsRestrictedZoneGeo : '', isValid: true},
        departementIdList: {name: 'departementIdList', value: projetInfos?.restrictedDepartements ? projetInfos.restrictedDepartements.map(dep => {return dep.DepartementId?.toString()}) : [], isValid: true}
    })

    const [typologieRemboursementList, setTypologieRemboursementList] = useState<TypologieRemboursement[]>([])
    const [periodiciteEcheanceList, setPeriodiciteEcheanceList] = useState<PeriodiciteEcheance[]>([])
    const [TypeEmpruntListResponse, setTypeEmpruntListResponse] = useState<TypeEmprunt[]>([])
    const [departementList, setDepartementList] = useState<DropdownElement[]>([]);

    const [isFormError, setIsFormError] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName?: string): void => {
        const fieldValue: string = e.target.value;
        const newField: FieldData = { [fieldName!]: { value: fieldValue, name: fieldName } };
      
        setForm({ ...form,  ...newField});
        setIsFormError(false)
    }

    const validateMandatory = (value: any): boolean => {
        return value && value !== ""
    }
    
    const validateMontantSouhaite = (value: any): boolean => {
        const montant = value as number
        const regex = /[0-9]{1,7}/g;
        return validateMandatory(value) && montant >= 10000 && montant <= 5000000 && regex.test(value)
    }

    const validateInteret = (value: any): boolean => {
        const regex = /^[0-9]{1}((,|.)[0-9]{1})?$/g;
        return validateMandatory(value) && regex.test(value)
    }

    const validateMaturite = (value: any): boolean => {
        const montant = value as number
        if(form.typeEmprunt.value === TypeEmprunt.TITRES){
            return validateMandatory(value) && montant >= 84
        }
        return validateMandatory(value) && montant >= 24 && montant <= 84
    }

    const validatePeriodeSansRemboursement = (value: any): boolean => {
        const periode = value as number
        if(form.maturiteCreditCitoyen.value !== null){
            return validateMandatory(periode) && periode <= +form.maturiteCreditCitoyen.value
        }
        
        return validateMandatory(periode)
    }

    const validateMontantMaximalFinancement = (value: any): boolean => {
        return validateMandatory(value) && parseInt(value) <= 5000000 && parseInt(value) >= parseInt(form.montantRecherche.value)
    }


    const isFormValid = (): boolean => {
        return validateMontantSouhaite(form.montantRecherche.value)
    }

    const onFormNotValid = (): void => {
        setIsFormError(true)
    }

    const onSubmitError = (): void => {
        
    }

    const onPreSubmit = (): void => {
        setIsFormSubmitted(true)
    }

    const onGenerateSuccess = (response: TableauAmortissementResponse) => {
        // TODO : télécharger le fichier qui sera dans la réponse
        if(response.IsTraitementOk) {
            const byteCharacters = atob(response.File!);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const now = new Date()
            const day = now.getDate() < 10 ? "0" + now.getDate().toString() : now.getDate().toString()
            const month = now.getMonth() + 1 < 10 ? "0" + (now.getMonth() + 1).toString() : (now.getMonth() + 1).toString()
            const hour = now.getHours() < 10 ? "0" + now.getHours().toString() : now.getHours().toString()
            const minute = now.getMinutes() < 10 ? "0" + now.getMinutes().toString() : now.getMinutes().toString()
            const second = now.getSeconds() < 10 ? "0" + now.getSeconds().toString() : now.getSeconds().toString()
            const fileName = "tableau_amortissement_" + now.getFullYear().toString() + month + day + hour + minute + second + ".xlsx"
            const blob = new Blob(
                [byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
            const aEle = document.createElement('a');     // Create a label
            const href = window.URL.createObjectURL(blob);       // Create downloaded link
            aEle.href = href;
            aEle.download = fileName;  // File name after download
            document.body.appendChild(aEle);
            aEle.click();     // Click to download
            document.body.removeChild(aEle); // Download complete remove element
            window.URL.revokeObjectURL(href) 
        }
        
    }

    useEffect(() => {
        referenceService.getAllTypologieRemboursement(loginProps?.oauth)
        .then(response => {
            return response.json() as Promise<TypologieRemboursementListResponse>
        })
        .then(response => {
            if(response.IsTraitementOk) {
                setTypologieRemboursementList(response.TypologieRemboursementList!)
            }
        })

        referenceService.getPeriodiciteList(loginProps?.oauth)
        .then(response => {
            return response.json() as Promise<PeriodiciteListResponse>
        })
        .then(response => {
            if(response.IsTraitementOk) {
                setPeriodiciteEcheanceList(response.PeriodiciteList!)
            }
        })

        referenceService.getAllDepartementList(loginProps?.oauth)
        .then(response => {
            return response.json() as Promise<DepartementListResponse>
        })
        .then(response => {
            let departementListRes: DropdownElement[] = response.DepartementList && response.DepartementList.map(com => {
                return {
                    value: com.Id.toString(),
                    text: com.Designation + " (" + com.Code + ") "
                    
                } as DropdownElement
            }) || ([])
            setDepartementList(departementListRes)
        })

        referenceService.getTypeEmpruntList(projet.EtablissementId)
        .then(response => {
            if(response.IsTraitementOk) {
                setTypeEmpruntListResponse(response.TypeEmpruntList!)
            }
        })
    }, [])

    const maturiteLabel = useMemo(()=>{
        if(form.typeEmprunt.value === TypeEmprunt.TITRES){
            return "Maturité du crédit (à partir de 84 mois)" 
        } 
        return "Maturité du crédit (entre 24 et 84 mois)"
    }, [form.typeEmprunt.value])

    const body: ProjetFinancementParticipatifBody = {
        MontantFinancementCitoyen: form.montantRecherche.value,
        TauxInteret: form.tauxInteretCitoyen.value,
        MaturiteMois: form.maturiteCreditCitoyen.value,
        TypologieRemboursementCode: form.typologieRemboursement.value,
        PeriodiciteRemboursementCode: form.periodiciteRemboursement.value,
        IsLimitRespected: form.maturiteCreditCitoyen.value,
        TypeEmprunt: form.typeEmprunt.value,
        PeriodeSansRemboursement: form.periodeSansRemboursement.value,
        MontantMaximalInvestissement: form.montantMaximalInvestissement.value * 100,
        MontantMaximalFinancement: form.montantMaximalFinancement.value * 100,
        IsRestrictedZoneGeo: form.isRestrictedZoneGeo.value,
        DepartementIdList: form.departementIdList.value,
        WorkflowTypeCode: workflowTypeCode
    }

    const bodyAmortissement: TableauAmortissementRequestBody = {
        MaturiteMois: form.maturiteCreditCitoyen.value,
        MontantEmprunte: form.montantRecherche.value,
        TauxAnnuel: form.tauxInteretCitoyen.value,
        PeriodiciteEcheanceCode: form.periodiciteRemboursement.value,
        TypologieRemboursementCode: form.typologieRemboursement.value,
        TypeEmprunt: form.typeEmprunt.value,
        PeriodeSansRemboursement: form.periodeSansRemboursement.value
    }

    const nextFinacementParticipatifStepKey = () => {
        const body = {
            WorkflowTypeCode : workflowTypeCode ?? '',
            CurrentFlowStep : FlowStep.FINANCEMENT_PARTICIPATIF,
            RessourceId:  projet?.Id!,
            IsSkiped: true
        }
        return referenceService.getNextStep(body).then(response => {
            onSkipStep && onSkipStep(response)})
    }

    return(
        <Form>
            <FormErrorHeader 
                message="Veuillez corriger les champs en erreur"
                displayed={isFormError}
            />
            <Form.Field width={16}>
                <div>Une question sur le processus de financement participatif ? Consultez notre <a href="/faq-collectivite" target="_blank">F.A.Q</a></div>
            </Form.Field>
            <Form.Field width={16}>
                <CheckBoxListField 
                    data={form.typeEmprunt}
                    submitted={isFormSubmitted}
                    label="Type de contrat d'emprunt"
                    gap="50px"
                    elements={TypeEmpruntListResponse && TypeEmpruntListResponse.map(emprunt => {
                        return {
                            key: emprunt.Code,
                            label: emprunt.Libelle
                        } as CheckBoxElement
                    }) || []}
                    onChange={(key: string, isChecked?: boolean) => {
                        if(isChecked) {
                            if(key != TypeEmprunt.TITRES && form.typeEmprunt.value !== null){
                                setForm({
                                    ...form, 
                                    typeEmprunt: {value: key, name: 'typeEmprunt', isValid: true}, 
                                    periodeSansRemboursement: {value: null, name: 'periodeSansRemboursement', isValid: true}
                                })
                            }
                            else{
                                setForm({...form, typeEmprunt: {value: key, name: 'typeEmprunt', isValid: true}})
                            }
                        }
                        else {
                            setForm({...form, typeEmprunt: {value: undefined, name: 'typeEmprunt', isValid: false}})
                        }
                    }}
                    validateField={validateMandatory}
                    //error="Merci de renseigner le type de contrat d'emprunt"
                />
            </Form.Field>
            <Form.Group>
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantRecherche}
                        submitted={isFormSubmitted}
                        label="Montant souhaité de financement citoyen"
                        error="Merci de renseigner le montant de financement participatif recherché entre 10 000 et 5 million d'euros"
                        onChange={(e) => handleInputChange(e, form.montantRecherche.name)}
                        validateField={validateMontantSouhaite}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={8}>
                    <InputField 
                        data={form.tauxInteretCitoyen}
                        submitted={isFormSubmitted}
                        label="Taux d'intérêt annuel (entre 1% et le taux d'usure)"
                        //error="Merci de renseigner le taux d'intérêt du financement participatif dans les limites indiquées avec un seul chiffre après la virgule"
                        onChange={(e) => handleInputChange(e, form.tauxInteretCitoyen.name)}
                        validateField={validateInteret}
                    />
                </Form.Field>
                <Form.Field width={8}>
                    <InputField 
                        data={form.maturiteCreditCitoyen}
                        submitted={isFormSubmitted}
                        label={maturiteLabel}
                        //error="Merci de renseigner la maturité du crédit citoyen dans les limites indiquées"
                        onChange={(e) => handleInputChange(e, form.maturiteCreditCitoyen.name)}
                        validateField={validateMaturite}
                    />
                </Form.Field>
            </Form.Group>
            {
                form.typeEmprunt.value === TypeEmprunt.TITRES ? 
                    <Form.Field width={6}>
                        <InputField 
                            data={form.periodeSansRemboursement}
                            submitted={isFormSubmitted}
                            label="Période sans amortissement du capital (en mois)"
                            //error="Merci de renseigner une période sans amortissement inférieur à la maturité du crédit citoyen"
                            onChange={(e) => handleInputChange(e, form.periodeSansRemboursement.name)}
                            validateField={validatePeriodeSansRemboursement}
                        />
                    </Form.Field>
                :
                    <Form.Field width={16}>
                        <CheckBoxListField 
                            data={form.typologieRemboursement}
                            submitted={isFormSubmitted}
                            label="Typologie de remboursement"
                            elements={typologieRemboursementList && typologieRemboursementList.map(typologie => {
                                return {
                                    key: typologie.Code,
                                    label: typologie.Libelle
                                } as CheckBoxElement
                            }) || []}
                            spaceBetween
                            onChange={(key: string, isChecked?: boolean) => {
                                if(isChecked) {
                                    setForm({...form, typologieRemboursement: {value: key, name: 'typologieRemboursement', isValid: true}})
                                }
                                else {
                                    setForm({...form, typologieRemboursement: {value: undefined, name: 'typologieRemboursement', isValid: false}})
                                }
                            }}
                            validateField={validateMandatory}
                            //error="Merci de renseigner la typologie de remboursement"
                        />
                    </Form.Field>
            }
            <Form.Group>
                <Form.Field width={8}>
                    <DropdownListFieldNew 
                        label="Périodicité de remboursement"
                        submitted={isFormSubmitted}
                        field={form.periodiciteRemboursement}
                        placeholder="Choisir une périodicité"
                        //error="Merci de renseigner la périodicité de remboursement"
                        datasource={periodiciteEcheanceList && periodiciteEcheanceList.map(periodicite => {
                            return {
                                text: periodicite.Libelle,
                                value: periodicite.Code
                            } as DropdownElement
                        })}
                        onChange={(value: any) => {
                            setForm({...form, periodiciteRemboursement: {name: 'periodiciteRemboursement', value: value, isValid: true}})
                        }}
                        validateField={validateMandatory}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantMaximalFinancement}
                        submitted={isFormSubmitted}
                        label="Montant maximal de financement"
                        //error={"Merci de renseigner un montant maximal de financement entre " + form.montantRecherche.value + " et 5000000"}
                        onChange={(e) => handleInputChange(e, form.montantMaximalFinancement.name)}
                        validateField={validateMontantMaximalFinancement}
                    />
                </Form.Field>
                <Form.Field width={8}>
                    <InputField 
                        data={form.montantMaximalInvestissement}
                        submitted={isFormSubmitted}
                        label="Montant maximal d’investissement"
                        onChange={(e) => handleInputChange(e, form.montantMaximalInvestissement.name)}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field width={8}>
                <CheckBoxSimpleField 
                    data={form.isRestrictedZoneGeo}
                    submitted={isFormSubmitted}
                    label="Restreindre les investissements à une zone géographique"
                    validateField={validateMandatory}
                    onChange={(newKey: any, isValid?: boolean) => {
                        setForm({...form, isRestrictedZoneGeo: {...form.isRestrictedZoneGeo, value: newKey, isValid: isValid}})
                    }}
                />
                {form.isRestrictedZoneGeo.value &&
                    <MultipleDropdownSelection
                        placeholder="Choisir les départements auxquels sera limité l'investissement"
                        options={departementList.map(dep => dep)}   
                        submitted={isFormSubmitted}  
                        data={form.departementIdList} 
                        validateField={validateMandatory} 
                        onChange={(value: any) => {
                            setForm({...form, departementIdList: {...form.departementIdList, value: value, isValid: true}})
                        }}        
                    />
                }
            </Form.Field>
            <Form.Field width={16}>
                <CheckBoxSimpleField 
                    data={form.certificationLimite}
                    submitted={isFormSubmitted}
                    label="Je certifie que je respecte la limite de 5 million d'euros de financement participatif toute plateforme confondue pour mon projet"
                    //error="Merci de certifier que vous respectez bien la limite légale de financement participatif"
                    validateField={validateMandatory}
                    onChange={(value: any, isValid: boolean) => {
                        setForm({...form, certificationLimite: {name: "certificationLimite", value: value, isValid: isValid}})
                    }}
                />
            </Form.Field>
            <div css={{
                display: 'flex',
                justifyContent: 'center'
            }}>

                    <SubmitButton 
                    loaderArea = "modal"
                    data={bodyAmortissement}
                    label="Générer le tableau d'amortissement"
                    name="amortissementButton"
                    action={() => projetService.generateTableauAmortissement(bodyAmortissement, projet?.Id ?? null, loginProps?.oauth)}
                    onActionSuccess={onGenerateSuccess}
                />
            </div>
            <div css={{
                    marginTop: '15px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }} 
                className="button-bar">
                <SubmitButton 
                    data={body}
                    label="Valider"
                    action={() => projetService.saveFinancementParticipatifProjet(body, projet?.Id, loginProps?.oauth)}
                    onActionSuccess={onSubmitSuccess}
                    onActionFailure={onSubmitError}
                    onFormNotValid={onFormNotValid}
                    validateForm={isFormValid}
                    onPreSubmit={onPreSubmit}
                    debounceTimming={isCreating ? 1000 : undefined}
                />
                {isCreating && (
                    <SimpleButton 
                        label="Passer cette étape"
                        onClick={() => nextFinacementParticipatifStepKey()}
                    />
                )}
            </div>
        </Form>
    )
}

const mapStateToProps = (state: ApplicationState) => ({
    loginProps: state.authentication
});

const ConnectedFinancmentParticipatifForm = connect(mapStateToProps, null)(FinancmentParticipatifForm);
export { ConnectedFinancmentParticipatifForm as FinancmentParticipatifForm };

 
